<template>
  <div id="app">
    <HeaderView />
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view class="wrapper" />
    <FooterView />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import FooterView from './components/FooterView.vue'
import HeaderView from './components/HeadView.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    HeaderView,
    FooterView
  },
  watch: {
    '$route.path' () {
      this.setTop()
    },
    '$route.query' () {
      this.setTop()
    }
  },
  methods: {
    setTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style>
  #app {
    font-family: Roboto, sans-serif, "Microsoft Sans Serif","Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    font-weight: 300 !important;
    font-size: 16px;
    color: #666;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    overflow: hidden;
    min-width: 1366px;
    /* font-family: Gilroy,'Helvetica Neue',Helvetica,Arial,sans-serif; */
  }
  /* .wrapper {background-color: #ecf0f5;} */
  .el-menu--horizontal {
    width: 100%;
    background-color: rgba(255, 255, 255, .8);
    left: 0 !important;
    padding: 0 2rem;
    box-sizing: border-box;
    min-width: 1366px;
  }
  .el-menu--popup-bottom-start {
    background-color: transparent;
    box-shadow: none;
    padding: 2rem 0 2rem 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 2px solid #999;
  }
  .el-menu--horizontal .el-menu .el-menu-item {
    background-color: transparent !important;
    display: inline-block;
    padding: 0 2rem;
    margin: 0 1rem;
    height: auto;
  }
  .el-menu--horizontal .el-menu .el-tabs .el-menu-item {width: 100%;}
  .el-tabs__active-bar {background-color: #e67700;}
  .el-menu--horizontal .el-menu .el-menu-item a {color: #666; opacity: .7;}
  .el-menu--horizontal .el-menu .el-menu-item a:hover {color: #e67700; opacity: 1;}
  .el-menu--horizontal .el-menu .el-menu-item a .el-image {border-radius: 50%; width: 8rem; height: 8rem;}
  .el-menu--horizontal .el-menu .el-menu-item a p {text-align: center; margin-top: 1rem;}
  .el-tabs__item:hover, .el-tabs__item.is-active, .u-link--Active {color: #e67700;}
  /* .el-tabs__active-bar {background-color: #e67700;} */
  
</style>
