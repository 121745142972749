
import './styles/base.css'
import './api/format'
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/common.css'
import router from './router/index'
import App from './App.vue'
import Shared from 'vue-social-share'
import share from 'vue-shares'
import store from './store'
Vue.use(share)
Vue.use(Shared)

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
