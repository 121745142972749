<template>
  <section :class="headerShow ? 'header-active' : 'header'" id="top">
    <!-- <el-row :gutter="20" style="padding:1rem 0; width: 100%;">
      <el-col :span="4"> -->
        <div class="grid-content header-logo">
          <section class="logo">
            <router-link to="/">
              <el-image :src="headerShow ? logoImg1 : logoImg2" style="height: 100%;"/>
            </router-link>
          </section>
          <!-- <span style="padding: 0 1rem;">证券代码: 868955</span> -->
        </div>
      <!-- </el-col>
      <el-col :span="20"> -->
        <div class="grid-content header-nav">
          <section class="nav">
            <el-menu
            :default-active="activeIndex"
            class="el-menu-nav"
            mode="horizontal"
            background-color="transparent"
            text-color = '#fff'
            active-text-color="#e67700"
            @select="handleSelect"
            @open="handleOpen"
            @close="handleClose"
            >
              <el-menu-item index="1"><router-link to="/">首页</router-link></el-menu-item>
              <!-- <el-menu-item index="2"><router-link to="/aboutus">关于我们</router-link></el-menu-item> -->
              <el-submenu index="2" @mouseenter.native="handleOpen(2)" @mouseleave.native="handleClose">
                <template slot="title"><a href="/profile?id=7">关于我们</a></template>
                <el-menu-item index="2-1"><router-link active-class="u-link--Active" :to="{path: '/profile', query: {id: 7}}" ><el-image v-if="imgShow == 2" :src="aboutUsImage1" fit="cover"></el-image><p>公司简介</p></router-link></el-menu-item>
                <el-menu-item index="2-2"><router-link active-class="u-link--Active" :to="{path: '/culture', query: {id: 8}}"><el-image v-if="imgShow == 2" :src="aboutUsImage2" fit="cover"></el-image><p>公司文化</p></router-link></el-menu-item>
                <el-menu-item index="2-3"><router-link active-class="u-link--Active" :to="{path: '/honor', query: {id: 3}}"><el-image v-if="imgShow == 2" :src="aboutUsImage3" fit="cover"></el-image><p>公司荣誉</p></router-link></el-menu-item>
                <el-menu-item index="2-4"><router-link active-class="u-link--Active" :to="{path: '/memoir', query: {id: 4}}"><el-image v-if="imgShow == 2" :src="aboutUsImage4" fit="cover"></el-image><p>成长轨迹</p></router-link></el-menu-item>
                <!-- <el-menu-item index="2-5"><router-link active-class="u-link--Active" :to="{path: '/certification', query: {id: 5}}"><el-image v-if="imgShow == 2" :src="aboutUsImage5" fit="cover"></el-image><p>公司资质</p></router-link></el-menu-item> -->
              </el-submenu>
              <el-submenu index="3" @mouseenter.native="handleOpen" @mouseleave.native="handleClose">
                <template slot="title"><a href="/newProduct">产品中心</a></template>
                <el-tabs v-model="activeName" style="width: 100%">
                  <el-tab-pane label="平台产品系列" name="first" :lazy="true">
                    <el-row>
                      <el-col :span="8">
                        <el-row style="display: flex; justify-content: start; align-items: start;">
                          <el-col :span="12" style="justify-content: start; align-items: start;">
                            <el-menu-item index="3-1" @mouseenter.native="handleMouseenter('productImage1')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/platform', query: {id: 1}}" ><p>变电站智慧辅控管理平台</p></router-link></el-menu-item>
                            <el-menu-item index="3-2" @mouseenter.native="handleMouseenter('productImage2')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/platform', query: {id: 2}}"><p>变电站远程智能巡视系统</p></router-link></el-menu-item>
                            <el-menu-item index="3-3" @mouseenter.native="handleMouseenter('productImage3')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/platform', query: {id: 3}}" ><p>智能电子安防监控平台</p></router-link></el-menu-item>
                            <el-menu-item index="3-4" @mouseenter.native="handleMouseenter('productImage4')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/platform', query: {id: 4}}"><p>变电站表计数字化远程监视平台</p></router-link></el-menu-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="16" style="min-height: 1px; text-align: center;">
                        <div :class="productImage == 'productImage1' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage1" class="nav-img" fit="cover"></el-image></div>
                        <div :class="productImage == 'productImage2' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage2" class="nav-img" fit="cover"></el-image></div>
                        <div :class="productImage == 'productImage3' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage3" class="nav-img" fit="cover"></el-image></div>
                        <div :class="productImage == 'productImage4' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage4" class="nav-img" fit="cover"></el-image></div>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                  <el-tab-pane label="设备运行监护系列" name="second" :lazy="true">
                    <el-row>
                      <el-col :span="8">
                        <el-row style="align-items: flex-start;">
                          <!-- <el-col :span="12" style="display: block; justify-content: start; align-items: start;">
                            <el-menu-item index="3-3" @mouseenter.native="handleMouseenter('productImage3')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/status', query: {id: 1}}"><p>特高压</p></router-link></el-menu-item>
                            <el-menu-item index="3-4" @mouseenter.native="handleMouseenter('productImage4')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/status', query: {id: 2}}"><p>微环境</p></router-link></el-menu-item>
                            <el-menu-item index="3-5" @mouseenter.native="handleMouseenter('productImage5')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/status', query: {id: 3}}"><p>电缆状态</p></router-link></el-menu-item>
                          </el-col> -->
                          <el-col :span="12" style="display: block; justify-content: start; align-items: start;">
                            <el-menu-item index="3-5" @mouseenter.native="handleMouseenter('productImage5')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/guardianship', query: {id: 1}}"><p>变电设备红外重症监护装置</p></router-link></el-menu-item>
                            <el-menu-item index="3-6" @mouseenter.native="handleMouseenter('productImage6')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/guardianship', query: {id: 2}}"><p>电气柜防潮防凝露专项解决方案</p></router-link></el-menu-item>
                            <el-menu-item index="3-7" @mouseenter.native="handleMouseenter('productImage7')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/guardianship', query: {id: 3}}"><p>自组网温湿度及积水监控</p></router-link></el-menu-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="16" style="min-height: 1px; text-align: center;">
                        <div :class="productImage == 'productImage5' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage5" class="nav-img" fit="cover"></el-image></div>
                        <div :class="productImage == 'productImage6' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage6" class="nav-img" fit="cover"></el-image></div>
                        <div :class="productImage == 'productImage7' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage7" class="nav-img" fit="cover"></el-image></div>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                  <!-- <el-tab-pane label="现代智慧供应链" name="third" :lazy="true">
                    <el-row>
                      <el-col :span="8">
                        <el-row style="align-items: flex-start;">
                          <el-col :span="12" style="display: block; justify-content: start; align-items: start;">
                            <el-menu-item index="3-6" @mouseenter.native="handleMouseenter('productImage6')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/ibm', query: {id: 1}}"><p>助力飞</p></router-link></el-menu-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="16" style="min-height: 1px; text-align: center;">
                        <div :class="productImage == 'productImage6' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage6" class="nav-img" fit="cover"></el-image></div>
                      </el-col>
                    </el-row>
                  </el-tab-pane> -->
                  <el-tab-pane label="核心技术" name="four" :lazy="true">
                    <el-row>
                      <el-col :span="8">
                        <el-row style="align-items: flex-start;">
                          <el-col :span="12" style="display: block; justify-content: start; align-items: start;">
                            <el-menu-item index="3-8" @mouseenter.native="handleMouseenter('productImage8')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/core', query: {id: 1}}"><p>核心技术层级</p></router-link></el-menu-item>
                            <el-menu-item index="3-9" @mouseenter.native="handleMouseenter('productImage9')" @mouseleave.native="handleMouseleave"><router-link active-class="u-link--Active" :to="{path: '/core', query: {id: 2}}"><p>助力飞核心技术</p></router-link></el-menu-item>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :span="16" style="min-height: 1px; text-align: center;">
                        <div :class="productImage == 'productImage8' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage8" class="nav-img" fit="cover"></el-image></div>
                        <div :class="productImage == 'productImage9' ? 'nav-image active-image' : 'nav-image'"><el-image :src="productImage9" class="nav-img" fit="cover"></el-image></div>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                </el-tabs>
              </el-submenu>
              <el-submenu index="4" @mouseenter.native="handleOpen(4)" @mouseleave.native="handleClose">
                <!-- <router-link to="/zhulifei">助力飞</router-link> -->
                <template slot="title"><a href="/zhulifei">助力飞</a></template>
                <el-menu-item index="4-1"><router-link active-class="u-link--Active" :to="{path: '/zhulifeiDetails', query: {id: 1}}"><el-image v-if="imgShow == 4" :src="zhulifeiImage1" fit="cover"></el-image><p>助力飞的结构特点</p></router-link></el-menu-item>
                <el-menu-item index="4-2"><router-link active-class="u-link--Active" :to="{path: '/whyReglory', query: {id: 2}}"><el-image v-if="imgShow == 4" :src="zhulifeiImage2" fit="cover"></el-image><p>为什么是我们</p></router-link></el-menu-item>
                <el-menu-item index="4-3"><router-link active-class="u-link--Active" :to="{path: '/coreHighlights', query: {id: 3}}"><el-image v-if="imgShow == 4" :src="zhulifeiImage3" fit="cover"></el-image><p>核心亮点</p></router-link></el-menu-item>
                <el-menu-item index="4-4"><router-link active-class="u-link--Active" :to="{path: '/buildSystem', query: {id: 4}}"><el-image v-if="imgShow == 4" :src="zhulifeiImage4" fit="cover"></el-image><p>构建多赢体系</p></router-link></el-menu-item>
              </el-submenu>
              <el-submenu index="5" @mouseenter.native="handleOpen(5)" @mouseleave.native="handleClose">
                <template slot="title"><a href="/internetServices">互联网服务</a></template>
                <el-menu-item index="5-1"><router-link active-class="u-link--Active" :to="{path: '/internet', query: {id: 1}}" ><el-image v-if="imgShow == 5" :src="internetImage1" fit="cover"></el-image><p>我们的目标和愿景</p></router-link></el-menu-item>
                <el-menu-item index="5-2"><router-link active-class="u-link--Active" :to="{path: '/internet', query: {id: 2}}"><el-image v-if="imgShow == 5" :src="internetImage2" fit="cover"></el-image><p>我们提供的服务内容</p></router-link></el-menu-item>
                <!-- <el-menu-item index="5-3"><router-link active-class="u-link--Active" :to="{path: '/internet', query: {id: 3}}"><el-image v-if="imgShow == 5" :src="productImage3" fit="cover"></el-image><p>助力飞的商业模式</p></router-link></el-menu-item> -->
                <el-menu-item index="5-4"><router-link active-class="u-link--Active" :to="{path: '/information', query: {id: 4}}"><el-image v-if="imgShow == 5" :src="productImage4" fit="cover"></el-image><p>未来属于一体化</p></router-link></el-menu-item>
              </el-submenu>
              <el-submenu index="6" @mouseenter.native="handleOpen(6)" @mouseleave.native="handleClose">
                <!-- <router-link to="/investorRelations">投资者关系</router-link> -->
                <template slot="title"><a href="/investor">投资者关系</a></template>
                <el-menu-item index="6-1"><router-link active-class="u-link--Active" :to="{path: '/investorDetails', query: {id: 1}}"><el-image v-if="imgShow == 6" :src="investorFull" fit="cover"></el-image><p>全部公告</p></router-link></el-menu-item>
                <el-menu-item index="6-2"><router-link active-class="u-link--Active" :to="{path: '/investorDetails', query: {id: 2}}"><el-image v-if="imgShow == 6" :src="investorProvisional" fit="cover"></el-image><p>临时公告</p></router-link></el-menu-item>
                <el-menu-item index="6-3"><router-link active-class="u-link--Active" :to="{path: '/investorDetails', query: {id: 3}}"><el-image v-if="imgShow == 6" :src="investorPeriodic" fit="cover"></el-image><p>定期公告</p></router-link></el-menu-item>
                <el-menu-item index="6-4"><router-link active-class="u-link--Active" :to="{path: '/investorDetails', query: {id: 4}}"><el-image v-if="imgShow == 6" :src="investorBrokerage" fit="cover"></el-image><p>券商公告</p></router-link></el-menu-item>
              </el-submenu>
              <el-submenu index="7" @mouseenter.native="handleOpen(7)" @mouseleave.native="handleClose">
                <!-- <router-link :to="{path: '/sustainedDevelopment', query: {id: 1}}">可持续发展</router-link> -->
                <template slot="title"><a href="/develop?id=1">可持续发展</a></template>
                <el-menu-item index="7-1"><router-link active-class="u-link--Active" :to="{path: '/develop', query: {id: 1}}"><el-image v-if="imgShow == 7" :src="developImage1" fit="cover"></el-image><p>通向可持续发展之路</p></router-link></el-menu-item>
                <el-menu-item index="7-2"><router-link active-class="u-link--Active" :to="{path: '/develop', query: {id: 2}}"><el-image v-if="imgShow == 7" :src="developImage2" fit="cover"></el-image><p>注重可持续发展</p></router-link></el-menu-item>
                <el-menu-item index="7-3"><router-link active-class="u-link--Active" :to="{path: '/develop', query: {id: 3}}"><el-image v-if="imgShow == 7" :src="developImage3" fit="cover"></el-image><p>构建可持续的组织</p></router-link></el-menu-item>
                <el-menu-item index="7-4"><router-link active-class="u-link--Active" :to="{path: '/develop', query: {id: 4}}"><el-image v-if="imgShow == 7" :src="developImage4" fit="cover"></el-image><p>生态与繁荣并行</p></router-link></el-menu-item>
              </el-submenu>
              <el-submenu index="8" @mouseenter.native="handleOpen(8)" @mouseleave.native="handleClose">
                <!-- <router-link to="/ourTeam">我们的团队</router-link> -->
                <template slot="title"><a href="/team?id=1">我们的团队</a></template>
                <el-menu-item index="8-1"><router-link active-class="u-link--Active" :to="{path: '/team', query: {id: 1}}"><el-image v-if="imgShow == 8" :src="teamImage1" fit="cover"></el-image><p>团队和信念</p></router-link></el-menu-item>
                <el-menu-item index="8-2"><router-link active-class="u-link--Active" :to="{path: '/team', query: {id: 2}}"><el-image v-if="imgShow == 8" :src="teamImage2" fit="cover"></el-image><p>加入旋荣</p></router-link></el-menu-item>
                <el-menu-item index="8-3"><router-link active-class="u-link--Active" :to="{path: '/team', query: {id: 3}}"><el-image v-if="imgShow == 8" :src="teamImage3" fit="cover"></el-image><p>职业规划和发展</p></router-link></el-menu-item>
                <el-menu-item index="8-4"><router-link active-class="u-link--Active" :to="{path: '/team', query: {id: 4}}"><el-image v-if="imgShow == 8" :src="teamImage4" fit="cover"></el-image><p>定期培训</p></router-link></el-menu-item>
                <el-menu-item index="8-5"><router-link active-class="u-link--Active" :to="{path: '/team', query: {id: 5}}"><el-image v-if="imgShow == 8" :src="teamImage5" fit="cover"></el-image><p>员工关系和健康</p></router-link></el-menu-item>
              </el-submenu>
              <el-submenu index="9" @mouseenter.native="handleOpen(9)" @mouseleave.native="handleClose">
                <template slot="title"><router-link to="/new">新闻资讯</router-link></template>
                <el-menu-item index="9-1"><router-link active-class="u-link--Active" :to="{path: '/newsCategory', query: {id: 1}}"><el-image v-if="imgShow == 9" :src="newsImage1" fit="cover"></el-image><p>旋荣新闻</p></router-link></el-menu-item>
                <el-menu-item index="9-2"><router-link active-class="u-link--Active" :to="{path: '/newsCategory', query: {id: 2}}"><el-image v-if="imgShow == 9" :src="newsImage2" fit="cover"></el-image><p>行业新闻</p></router-link></el-menu-item>
              </el-submenu>
            </el-menu>
          </section>
        </div>
      <!-- </el-col>
    </el-row> -->
  </section>
</template>

<script>
export default {
  name: 'headerView',
  data() {
    return {
      headerShow: false,
      logoImg1: require('../assets/images/logo1.png'),
      logoImg2: require('../assets/images/logo.png'),
      lightImageShow: false,
      productImage: '',
      // productImage1: require('../assets/images/product/变电站综合检测平台_画板 1.png'),
      // productImage2: require('../assets/images/product/红外重症监护平台.png'),
      // productImage3: require('../assets/images/product/微环境状态检测平台.png'),
      // productImage4: require('../assets/images/product/AI视觉精确识别.png'),
      // productImage5: require('../assets/images/product/为变电站提供服务.png'),
      // productImage6: require('../assets/images/product/充电桩主动防御装置.png'),
      // digitalMeter1: require('../assets/images/2023/digitalMeter/导航-01.png'),
      // digitalMeter2: require('../assets/images/2023/digitalMeter/导航-02.png'),
      productImage1: require('../assets/images/2023/product/nav/平台类_1.png'),
      productImage2: require('../assets/images/2023/product/nav/平台类_2.png'),
      productImage3: require('../assets/images/2023/product/nav/平台类_3.png'),
      productImage4: require('../assets/images/2023/product/nav/平台类_4.png'),
      productImage5: require('../assets/images/2023/product/nav/设备运行监护_1.png'),
      productImage6: require('../assets/images/2023/product/nav/设备运行监护_2.png'),
      productImage7: require('../assets/images/2023/product/nav/设备运行监护_3.png'),
      productImage8: require('../assets/images/2023/product/nav/核心技术_1.png'),
      productImage9: require('../assets/images/2023/product/nav/核心技术_2.png'),
      internetImage1: require('../assets/images/2023/internet/导航-01.png'),
      internetImage2: require('../assets/images/2023/internet/导航-02.png'),
      aboutUsImage1: require('../assets/images/aboutus/导航1.png'),
      aboutUsImage2: require('../assets/images/aboutus/导航2.png'),
      aboutUsImage3: require('../assets/images/aboutus/导航3.png'),
      aboutUsImage4: require('../assets/images/aboutus/导航4.png'),
      aboutUsImage5: require('../assets/images/aboutus/导航5.png'),
      investorFull: require('../assets/images/2023/investor/导航_01.png'),
      investorProvisional: require('../assets/images/2023/investor/导航_02.png'),
      investorPeriodic: require('../assets/images/2023/investor/导航_03.png'),
      investorBrokerage: require('../assets/images/2023/investor/导航_04.png'),
      teamImage1: require('../assets/images/2023/team/导航_1.png'),
      teamImage2: require('../assets/images/2023/team/导航_2.png'),
      teamImage3: require('../assets/images/2023/team/导航_3.png'),
      teamImage4: require('../assets/images/2023/team/导航_4.png'),
      teamImage5: require('../assets/images/2023/team/导航_5.png'),
      developImage1: require('../assets/images/2023/develop/导航_01.png'),
      developImage2: require('../assets/images/2023/develop/导航_02.png'),
      developImage3: require('../assets/images/2023/develop/导航_03.png'),
      developImage4: require('../assets/images/2023/develop/导航_04.png'),
      zhulifeiImage1: require('../assets/images/2023/zhulifei/导航_01.png'),
      zhulifeiImage2: require('../assets/images/2023/zhulifei/导航_02.png'),
      zhulifeiImage3: require('../assets/images/2023/zhulifei/导航_03.png'),
      zhulifeiImage4: require('../assets/images/2023/zhulifei/导航_04.png'),
      newsImage1: require('../assets/images/2023/news/导航-01.png'),
      newsImage2: require('../assets/images/2023/news/导航-02.png'),
      activeIndex: '1',
      activeName: 'first',
      imgShow: 0,
      config: {
        url: '',
        source: '',
        title: '',
        description: '',
        image: '',
        sites: ['qzone', 'weibo', 'wechat'],
        disabled: [],
        wechatQrcodeTitle: '微信扫一扫: 分享',
        wechatQrcodeHelper: '<p>微信里点"发现", 扫一下</p><p>二维码便可将本文分享至朋友圈</p>'
      }
    };
  },
  watch: {
    '$route.path' () {
      this.setActiveIndex()
    }
  },
  created () {},
  methods: {
    setActiveIndex () {
      let path = this.$route.path
      switch (path) {
        case '/':
          this.activeIndex = '1'
          break
        case '/profile':
          this.activeIndex = '2'
          break
        case '/newProduct':
          this.activeIndex = '3'
          break
        case '/zhulifei':
          this.activeIndex = '4'
          break
        case '/internetServices': 
          this.activeIndex = '5'
          break
        case '/investor':
          this.activeIndex = '6'
          break
        case '/develop':
          this.activeIndex = '7'
          break
        case '/team':
          this.activeIndex = '8'
          break
        case '/new':
          this.activeIndex = '9'
          break
      }
    },
    handleSelect (index, indexPath) {
      this.activeIndex = index
      console.log(index, indexPath)
    },
    handleMouseenter (org) {
      // this.productImage = this[org]
      console.log(org)
      this.productImage = org
      this.lightImageShow = true
    },
    handleMouseleave () {
      this.productImage = null
      this.lightImageShow = false
    },
    handleOpen (index) {
      this.headerShow = true
      this.imgShow = index
    },
    handleClose () {
      let that = this
      this.$nextTick(() => {
        let el = document.querySelectorAll('.is-opened')
        if (el.length == 0) that.headerShow = false
        else {
          that.headerShow = true
          setTimeout(this.handleClose, 500)
        }
      })
    }
  },
  mounted () {

  },
}
</script>

<style scoped>
.header, .header-active {position: absolute; z-index: 9; width: 100%; min-width: 1366px; padding: 1rem 1rem 0; text-align: left; box-sizing: border-box;}
.header .nav a {color: #fff}
.header-logo {display: inline-block; width: 15%; margin: 0 0 0 1rem; height: 4rem;}
.header .header-logo {margin-top: 1.2rem;}
.logo, .logo a {height: 5rem;}
.header-nav {position: absolute; right: 1rem; top: 1rem; height: 100%;}

.el-submenu /deep/ .el-submenu__title i {color: #fff;}
.el-submenu:hover /deep/ .el-submenu__title, .el-menu-item:hover {background-color: transparent !important;}
.el-submenu:hover /deep/ .el-submenu__title a, .el-submenu:hover /deep/ .el-submenu__title i {color: #e67700; font-weight: 600;}
.el-menu-item:hover a {color: #e67700;}

.header-active {background-color: rgba(255, 255, 255, 1);}
.header-active .nav a {color: #666;}
.header-active .el-submenu /deep/ .el-submenu__title i {color: #666;}
.header-active .logo .el-image {height: 3.5rem !important;}
.nav-image {height: 10rem; width: 10rem; margin: 1rem; display: inline-block; opacity: .5; box-sizing: border-box;}
.active-image {opacity: 1;}
.nav-img {height: 10rem; width: 10rem; margin: 0 auto; border-radius: 50%;}
.active-image .nav-img {transform: scale(1.2, 1.2);}
</style>