<template>
  <section class="footer">
    <section>
      <el-row :gutter="20">
        <el-col :span="3">
          <div style="width: 60%; margin: .4rem auto;">
            <router-link to="/"><el-image :src="logoImg" class="logo"></el-image></router-link>
          </div>
          <a style="margin-top: 1rem; padding: 1rem 0;" target="_blank" href="https://beian.miit.gov.cn/">沪ICP备2022031883号</a>
        </el-col>
        <el-col :span="2">
          <div class="grid-content">
            <dl>
              <dd><router-link to="/profile?id=7" class="link">关于我们</router-link></dd>
              <dd><router-link :to="{path: '/profile', query: {id: '7'}}">旋荣介绍</router-link></dd>
              <dd><router-link :to="{path: '/culture', query: {id: '8'}}">旋荣文化</router-link></dd>
              <dd><router-link :to="{path: '/honor', query: {id: '3'}}">旋荣荣耀</router-link></dd>
              <dd><router-link :to="{path: '/memoir', query: {id: '4'}}">成长轨迹</router-link></dd>
            </dl>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content">
            <dl>
              <dd> <a class="link" href="/newProduct">产品中心</a></dd>
              <dd> <router-link :to="{path: '/platform', query: {id: 1}}">变电站智慧辅控管理平台</router-link></dd>
              <dd> <router-link :to="{path: '/platform', query: {id: 2}}">变电站远程智能巡视系统</router-link></dd>
              <dd> <router-link :to="{path: '/platform', query: {id: 3}}">智能电子安防监控平台</router-link></dd>
              <dd> <router-link :to="{path: '/platform', query: {id: 4}}">变电站表计数字化远程监视平台</router-link></dd>
              <dd> <router-link :to="{path: '/core', query: {id: 1}}">核心技术层级</router-link></dd>
              <dd> <router-link :to="{path: '/core', query: {id: 2}}">助力飞核心技术</router-link></dd>
            </dl>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content">
            <dl>
              <dd> <a class="link" href="/internet?id=1">互联网服务</a> </dd>
              <!-- <dd> <router-link to="/#service" >首问服务制</router-link></dd> -->
              <dd> <router-link :to="{path: '/internet', query: {id: '1'}}" >我们的目标和愿景</router-link></dd>
              <dd> <router-link :to="{path: '/internet', query: {id: '2'}}" >我们提供的服务内容</router-link></dd>
              <dd> <router-link :to="{path: '/internet', query: {id: '3'}}" >助力飞的商业模式</router-link></dd>
              <dd> <router-link :to="{path: '/information', query: {id: '4'}}" >未来属于一体化</router-link></dd>
            </dl>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="grid-content">
            <dl>
              <dd> <router-link to="/investor" class="link">投资者关系</router-link></dd>
              <dd> <router-link :to="{path: '/investorDetails', query: {id: 1}}">全部公告</router-link></dd>
              <dd> <router-link :to="{path: '/investorDetails', query: {id: 2}}">临时公告</router-link></dd>
              <dd> <router-link :to="{path: '/investorDetails', query: {id: 3}}">定期公告</router-link></dd>
              <dd> <router-link :to="{path: '/investorDetails', query: {id: 4}}">券商公告</router-link></dd>
            </dl>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content">
            <dl>
              <dd> <router-link :to="{path: '/develop', query: {id: '1'}}" class="link">可持续发展</router-link></dd>
              <dd> <router-link :to="{path: '/develop', query: {id: '1'}}">通向可持续发展之路</router-link></dd>
              <dd> <router-link :to="{path: '/develop', query: {id: '2'}}">注重可持续发展</router-link></dd>
              <dd> <router-link :to="{path: '/develop', query: {id: '3'}}">构建可持续的组织</router-link></dd>
              <dd> <router-link :to="{path: '/develop', query: {id: '4'}}">生态与繁荣并行</router-link></dd>
            </dl>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content">
            <dl>
              <dd> <router-link to="/team?id=1" class="link">我们的团队</router-link></dd>
              <dd> <router-link :to="{path: '/team', query: {id: 1}}">我们的团队和信念</router-link></dd>
              <dd> <router-link :to="{path: '/team', query: {id: 2}}">加入旋荣</router-link></dd>
              <dd> <router-link :to="{path: '/team', query: {id: 3}}">职业规划和发展</router-link></dd>
              <dd> <router-link :to="{path: '/team', query: {id: 4}}">培训</router-link></dd>
              <dd> <router-link :to="{path: '/team', query: {id: 5}}">员工关系和健康</router-link></dd>
            </dl>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="grid-content">
            <dl>
              <dd> <router-link to="/new" class="link">新闻资讯</router-link></dd>
              <dd> <router-link :to="{path: '/newsCategory', query: {id: 1}}">公司新闻</router-link></dd>
              <dd> <router-link :to="{path: '/newsCategory', query: {id: 2}}">行业新闻</router-link></dd>
            </dl>
          </div>
        </el-col>
        <el-col :span="3">
          <div style="text-align: left; margin-bottom: 1.6rem;"><a href="#top" style="color: #e67700">网站导航<i class="el-icon-s-grid" style="color: #999; padding: .5rem; border: 1px solid #e67700; border-radius: 50%; margin-left: .5rem;"></i></a></div>
          <div class="grid-content">
            <dl>
              <dd> <a class="link">联系我们</a></dd>
              <dd>
                <a><el-image :src="phoneImg"></el-image>400-968-2366</a >
              </dd>
              <dd>
                <a><el-image :src="locationImg"></el-image><div style="display: inline-block; width: 80%;">上海黄浦区延安东路175号1301-1303室</div></a>
              </dd>
            </dl>
          </div>
        </el-col>
      </el-row>
    </section>
    <!-- <section class="filings">
      <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000001">京公网安备11000002000001号</a>
      <a target="_blank" href="https://beian.miit.gov.cn/">沪ICP备2022031883号</a>
    </section> -->
  </section>
</template>

<script>
export default {
  name: "footerView",
  data () {
    return {
      logoImg: require("../assets/images/logo3.png"),
      phoneImg: require("../assets/images/common/phone.png"),
      locationImg: require("../assets/images/common/location.png"),
    }
  }
};
</script>

<style scoped>
.footer{color:#666;font-size:.8rem;padding:3rem 5%;margin:1rem 0 0;box-sizing: border-box;}
.footer dd{padding: .2rem .2rem; width:100%; text-align:left;}
.footer a{color:#999;text-decoration:none; font-size: .8rem;}
.footer a:hover {color: #e67700}
.footer dl a.link{display:inline-block;font-weight:600;padding-bottom:.5rem; color: #666;}
/* .grid-content i.icon{font-size:20px;color:#e00;margin-right:10px;} */
.footer .grid-content .el-image {width: 1rem; vertical-align: top; margin-right: .6rem;}
.footer .logo {width: 100%;}
.el-row{margin-bottom:20px;}
.el-row:last-child{margin-bottom:0;}
.el-col{border-radius:4px; border-left: 1px solid #ccc; min-height: 12rem;}
.el-col:first-child, .el-col:last-child {border-left: none;}
.bg-purple-dark{background:#99a9bf;}
.bg-purple-light{background:#e5e9f2;}
.grid-content{border-radius:4px;min-height:36px;}
.row-bg{padding:10px 0;background-color:#f9fafc;}

.filings {margin-top: 2rem; width: 100%; text-align: center; line-height: 3;}
.filings a {margin: 0 .5rem;}
</style>
