window.Date.prototype.format = function (fmt = 'YYYY-MM-DD HH:mm:ss') {
  const map = {
    'M+': this.getMonth() + 1,
    'D+': this.getDate(),
    'd+': this.getDate(),
    'H+': this.getHours(),
    'm+': this.getMinutes(),
    's+': this.getSeconds(),
    'q+': Math.floor((this.getMonth() + 3) / 3),
    'S': this.getMilliseconds()
  }

  if (/(y+)/i.test(fmt)) {
    let result = RegExp.$1
    fmt = fmt.replace(result, this.getFullYear().toString().substr(4 - result.length))
  }

  for (let k in map) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      let result = RegExp.$1
      fmt = fmt.replace(result, map[k].toString().padStart(result.length, '0'))
    }
  }

  return fmt
}