import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {path:"/",name:"home",component:() => import("../views/home.vue")},
    {path:"/",name:"home",component:() => import("../views/index.vue")},
    {path:"/aboutus",name:"aboutus",component:() => import("../views/aboutus.vue"),},
    {path:"/profile",name:"profile",component:() => import("../views/aboutUs/profile.vue")},
    {path:"/culture",name:"culture",component:() => import("../views/aboutUs/culture.vue")},
    {path:"/memoir",name:"memoir",component:() => import("../views/aboutUs/memoir.vue")},
    {path:"/honor",name:"honor",component:() => import("../views/aboutUs/honor.vue")},
    {path:"/certification",name:"certification",component:() => import("../views/aboutUs/certification.vue")},

    {path:"/digitalMeter",name:"digitalMeter",component:() => import("../views/digitalMeter/digitalMeter.vue")},

    {path:"/new",name:"new",component:() => import("../views/news/new.vue")},
    {path:"/newsCategory",name:"newsCategory",component:() => import("../views/news/newsCategory.vue")},
    {path:"/newDetails",name:"newDetails",component:() => import("../views/news/newDetails.vue")},

    {path:"/investor",name:"investor",component:() => import("../views/investor/investor.vue")},
    {path:"/investorDetails",name:"investorDetails",component:() => import("../views/investor/investorDetails.vue")},

    {path:"/team",name:"team",component:() => import("../views/team/team.vue")},

    {path:"/develop",name:"develop",component:() => import("../views/develop/develop.vue")},

    {path:"/zhulifei",name:"zhulifei",component:() => import("../views/zhulifei/zhulifei.vue")},
    {path:"/zhulifeiDetails",name:"zhulifeiDetails",component:() => import("../views/zhulifei/zhulifeiDetails.vue")},
    {path:"/whyReglory",name:"whyReglory",component:() => import("../views/zhulifei/whyReglory.vue")},
    {path:"/coreHighlights",name:"coreHighlights",component:() => import("../views/zhulifei/coreHighlights.vue")},
    {path:"/buildSystem",name:"buildSystem",component:() => import("../views/zhulifei/buildSystem.vue")},

    {path:"/internetServices",name:"internetServices",component:() => import("../views/internetServices/internetServices.vue")},
    {path:"/internet",name:"internet",component:() => import("../views/internetServices/internet.vue")},
    {path:"/information",name:"information",component:() => import("../views/internetServices/information.vue")},

    {path:"/newProduct",name:"newProduct",component:() => import("../views/newProduct.vue")},
    {path:"/productDetails",name:"productDetails",component:() => import("../views/productDetails.vue")},
    {path:"/inspection",name:"inspection",component:() => import("../views/product/inspection.vue")},
    {path:"/status",name:"status",component:() => import("../views/product/status.vue")},
    {path:"/ibm",name:"ibm",component:() => import("../views/product/ibm.vue")},
    {path:"/cecurity",name:"cecurity",component:() => import("../views/product/cecurity.vue")},
    {path:"/platform",name:"platform",component:() => import("../views/product/platform.vue")},
    {path:"/guardianship",name:"guardianship",component:() => import("../views/product/guardianship.vue")},
    {path:"/core",name:"core",component:() => import("../views/product/core.vue")},
    {path:"/monitor",name:"monitor",component:() => import("../views/product/monitor.vue")},
    {path:"/overhaul",name:"overhaul",component:() => import("../views/product/overhaul.vue")},
    {path:"/dms",name:"dms",component:() => import("../views/product/dms.vue")},

    // {path:"/news",name:"news",component:() => import("../views/news.vue")},
    // {path:"/new",name:"new",component:() => import("../views/new.vue")},
    {path:"/companyNew",name:"companyNew",component:() => import("../views/companyNew.vue")},
    {path:"/product",name:"product",component:() => import("../views/product.vue")},
    {path:"/sustainedDevelopment",name:"sustainedDevelopment",component:() => import("../views/sustainedDevelopment.vue")},
    // {path:"/investorRelations",name:"investorRelations",component:() => import("../views/investorRelations.vue")},
    {path:"/ourTeam",name:"ourTeam",component:() => import("../views/ourTeam.vue")},
    {path:"*",name:"404",component:() => import("../views/404.vue")},
  ]
});
