import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
  },
  strict: debug,
  state: {
    nmps: {
      title: '智能变电站辅助系统综合监控平台',
      content: [
        {title: '平台开发导则', content: [
          {con:'变电站辅助设备监控系统采用开放式系统，系统设备配置和功能满足无人值班技术要求。',},
          {con:'变电站辅助设备监控系统满足全站安全运行要求。系统后台设备按全站最终规模配置，前端设备按最终建设规模配置。',},
          {con:'变电站辅助设备监控系统具备变电站各子系统逻辑模型展示能力，可以正确读取、显示、组织相关模型和实时信息数据。',}
        ]},
        {title: '平台系统架构', content: [
          {con:'变电站辅助设备监控系统由视频监控子系统、技防报警子系统、环境监测子系统、灯光控制子系统、火灾报警子系统、水冷却子系统（地下变电站）组成，实现对站内安防、消防、视频、环境监测等辅助设备的监视与控制。',},
          {con:'技防报警子系统、火灾报警子系统、水冷却子系统设置独立主机与辅控后台系统通信，其他子系统均不设置独立主机，由辅控后台系统集成。',},
          {collapse: [
            {
              tit: '(1) 网络架构',
              content: [
                {con:'变电站辅助设备监控系统（虚线框内）各子系统连接和其他系统的连接见图2-1。',},
                {url:require('../assets/images/product/1.png')},
                {con:'变电站辅助设备监控系统与辅助设备进行通信，采集一次设备、消防、安防、环境监测等信息，经过分析和处理后进行可视化展示，并将数据存入综合应用服务器。Ⅱ区数据通信网关机从综合应用服务器获取Ⅱ区数据和模型等信息，与调度（调控）中心进行信息交互，提供信息查询和远程浏览服务；',},
                {con:'技防报警子系统、火灾报警子系统、水冷却子系统通过独立主机与辅控后台系统通信，视频监控、环境监测前端智能设备、灯光控制系统以通信接口接入辅控后台系统。站内所有辅助设备实现数据汇集到变电站辅助设备监控系统后，通过II区通信网关机走调度数据专网将信息上送到调度主站。',},
              ]
            },
            {tit:'(2) 通信接口定义',
             content: [
              {con:'1. 站内视频监控前端设备通过B接口将视频监控数据上送到变电站辅助设备监控系统后台；',},
              {con:'2. 变电站辅助设备监控系统后台通过A接口将视频监控数据上送到其他视频主站；',},
              {con:'3. 环境监测部分中的传感器如温湿度传感器、风速传感器、SF6探测器等通过RS485方式接入变电站辅助设备监控系统后台；水浸传感器通过硬接点方式接入变电站辅助设备监控系统后台；',},
              {con:'4. 变电站辅助设备监控系统监控后台与技防报警子系统、火灾报警子系统、水冷却子系统采用IEC61850进行通信，实现设备控制、监视和联动；',},
              {con:'5. 具备和照明、暖通、给排水等系统的通信接口；',},
              {con:'6. 变电站辅助设备监控系统后台通过IEC61850与站内一体化监控系统通信；',},
              {con:'7. 变电站辅助设备监控系统可通过IEC101、IEC104与调度主站通信，通过DLT476规约上送文本信息到调度主站。',},
            ]}
          ]},
        ]},
        {title: '变电站辅助设备监控系统后台', content: [
          {collapse:[
            {
              tit: '(1) 辅控系统后台硬件组成',
              content: [
                {con:'变电站辅助设备监控系统后台（以下简称“后台系统”）配置1台独立主机，1台综合电源，1台环境数据采集单元（含SF6带显示监测器），1台灯光智能控制单元，交换机按最终规模配置并预留一定数量的接口。',},
              ]
            },{
              tit: '(2) 辅控系统后台软件功能',
              content: [
                {con:'后台系统包含图形显示、视频监控、环境监测、技防报警、消防报警、水冷却系统等模块组成。',},
                {con:'后台系统除实现智能变电站辅助控制系统设计技术规范、智能变电站辅助系统综合监控平台通用技术规范要求中提及的功能外，还需实现下述功能。',},
                {tit: '2.1.实时监视控制功能'},
                {con:'1. 可实现安全防范、防火、防人为事故，实现被监视目标的实时图像及报警信号上传到主站端，能实现远方控制功能。具备信号远方复归功能；',},
                {con:'2. 可实现操作人员的权限管理，保证控制的唯一性；',},
                {con:'3. 可实现当地或远程布防和撤防，可按照事先制定的策略自动进行布防和撤防，也可通过电子地图进行布防和撤防；',},
                {con:'4. 可实现跟踪监视运行人员功能，当运行人员对某一设备进行操作时，摄像机应自动调整到该区域，并能启动录像装置录像；',},
                {con:'5. 可实现摄像头的启停控制，可实现、空调电源、风机、水泵、照明设备启停；',},
                {con:'6. 可实现火灾报警子模块的火灾报警功能，火灾发生时，可显示发生火警的地点，并发出声光报警信号；',},
                {con:'7. 可实现环境监测，可显示温度、湿度、SF6浓度、水浸报警等。站内辅控系统可提供远方中心站控制接口，可实现远方启停站内潜水泵；',},
                {con:'8. 可实现视频、环境、给排水、暖通、技防、消防、水冷等各辅助子系统逻辑图SVG格式. 展示及实时信号关联展示功能，并将相关信息按要求传送变电站数字孪生系统。',},
                {tit: '2.2.联动功能'},
                {con:'1. 可实现用户自定义的设备联动，包括照明、暖通、消防、火灾、环境监测等相关设备联动；',},
                {con:'2. 夜间或照明不良启动摄像头摄像，必要时可联动辅助灯光、开启照明灯；',},
                {con:'3. 发生火灾时，可联动报警设备所在区域的摄像机跟踪拍摄火灾情况，自动解锁房间门禁、自动切断风机电源、空调电源；',},
                {con:'4. 发生非法入侵时，可联动报警设备所在区域的摄像机，并启动报警功能；',},
                {con:'5. 配电装置室SF6浓度超标时，可自动启动相应的风机，并启动报警功能，必要时可联动相应区域内的摄像机；',},
                {con:'6. 发生水浸时，可自动启动相应的水泵排水；',},
                {con:'7. 可实现对室内环境温度、湿度的实时采集。可自动启动或关闭通风空调系统；',},
                {con:'8. 视频监控子系统具有与火灾自动报警子模块联动的功能。发生火灾时，火灾发生点处附近的摄像机可跟踪拍摄火灾情况，监视器画面可自动切换，以显示火灾情况。',},
                {tit: '2.3.报警功能'},
                {con:'1. 报警类别可区分防盗报警、火灾报警、环境异常报警。报警可根据需要进行分级，报警信号、报警内容可在任何画面自动显示。当发生报警时，相应录像装置可自动进行存盘录像，并同时传送报警信息和相关图像，可自动在电子地图上提示报警位置及类型；',},
                {con:'2. 可处理多事件多点报警的能力，报警信息不应丢失和误报；',},
                {con:'3. 可在报警视窗内提供报警信号的详细信息；',},
                {con:'4. 报警时可提供语音报警和电话、传呼报警等多种方式；',},
                {con:'5. 可通过弹出菜单等方式设置报警联动的摄像机，可以联动摄像机的不同预置位；',},
                {con:'6. 所有报警信息均可查询，必要时可打印输出。',},
                {tit: '2.4.视频显示功能'},
                {con:'1. 可实现实时图像监视，视频图像大小可随意可调；',},
                {con:'2. 可实现主辅视频及辅助视频端口的本地输出；',},
                {con:'3. 可实现1/4/9/16画面预览，预览通道顺序可调；',},
                {con:'4. 可实现预览分组切换、手动切换或自动轮巡预览，自动轮巡周期可设置；',},
                {con:'5. 可实现预览的电子放大。可支持屏蔽指定的预览通道；',},
                {con:'6. 可实现预置点、巡航路径及轨迹。云台控制时，可实现鼠标点击放大、鼠标拖动跟踪功能。',},
                {tit: '2.5.图像储存回放功能'},
                {con:'1. 录像触发模式包括手动、定时、报警、移动侦测、动测或报警、动测和报警等；',},
                {con:'2. 每天可设定多个录像时间段，不同时间段的录像触发模式可独立设置；',},
                {con:'3. 可移动侦测录像、报警录像、动测和报警录像、动测或报警录像的预录及延时，定时和手动录像的预录；',},
                {con:'4. 可按事件查询录像文件。可实现录像文件的锁定和解锁；',},
                {con:'5. 可在回放时对任意区域进行局部电子放大。可实现回放时的暂停、快放、慢放、前跳、后跳，支持鼠标拖动定位。可实现多路同步回放，可通过资源配置加以调整。',},
                {tit: '2.6.遥控操作的智能验证'},
                {con:'当一体化监控系统对某个设备发出遥控指令后，系统通过智能视频分析技术，可以对主变压器、断路器、隔离开关、接地刀闸等设备的各项指标进行智能分析，获取设备的状态参数，智能判断设备状态与一体化监控指令是否一致，并可以实时报警。',},
                {tit: '2.7.事件记录及查询'},
                {con:'可以对设备的监视结果进行记录，记录的内容包括变电站设备的运行状态、故障描述信息、故障发现时间、故障处理情况等信息，并可对记录的历史信息进行查询。',},
                {tit: '2.8.电子地图功能'},
                {con:'1. 支持在地图上对(设备摄像机、门禁、灯控、电子围栏等)状态进行监视、控制操作和管理；',},
                {con:'2. 支持对地图进行放大、缩小和漫游功能；',},
                {con:'3. 支持报警事件联动，当报警发生时，支持在地图上直接对变电站进行管理；',},
                {con:'4. 在电子地图上自动推出报警地点实时画面；',},
                {con:'5. 显示摄像机、报警器、温湿度等视频监控设备的位置及环境监测量的数值；',},
                {con:'6. 电子地图按变电站、电压等树形分层结构进行直观浏览；',},
                {con:'7. 各级电子地图中显示摄像机等设备的分布和报警器设备布防、撤防、报警的状态；',},
                {con:'8. 可以对电子地图自行编辑、配置，设置地图，设置摄像机、报警器的位置；',},
                {con:'9. 可以在电子地图浏览视频图像；',},
                {con:'10. 地图信息保存在数据库中。',},
              ]
            },
            // {
            //   tit: '2.1.实时监视控制功能',
            //   content: [
            //     {con:'1. 可实现安全防范、防火、防人为事故，实现被监视目标的实时图像及报警信号上传到主站端，能实现远方控制功能。具备信号远方复归功能；',},
            //     {con:'2. 可实现操作人员的权限管理，保证控制的唯一性；',},
            //     {con:'3. 可实现当地或远程布防和撤防，可按照事先制定的策略自动进行布防和撤防，也可通过电子地图进行布防和撤防；',},
            //     {con:'4. 可实现跟踪监视运行人员功能，当运行人员对某一设备进行操作时，摄像机应自动调整到该区域，并能启动录像装置录像；',},
            //     {con:'5. 可实现摄像头的启停控制，可实现、空调电源、风机、水泵、照明设备启停；',},
            //     {con:'6. 可实现火灾报警子模块的火灾报警功能，火灾发生时，可显示发生火警的地点，并发出声光报警信号；',},
            //     {con:'7. 可实现环境监测，可显示温度、湿度、SF6浓度、水浸报警等。站内辅控系统可提供远方中心站控制接口，可实现远方启停站内潜水泵；',},
            //     {con:'8. 可实现视频、环境、给排水、暖通、技防、消防、水冷等各辅助子系统逻辑图SVG格式. 展示及实时信号关联展示功能，并将相关信息按要求传送变电站数字孪生系统。',},
            //   ]
            // },
            // {
            //   tit: '2.2.联动功能',
            //   content: [
            //     {con:'1. 可实现用户自定义的设备联动，包括照明、暖通、消防、火灾、环境监测等相关设备联动；',},
            //     {con:'2. 夜间或照明不良启动摄像头摄像，必要时可联动辅助灯光、开启照明灯；',},
            //     {con:'3. 发生火灾时，可联动报警设备所在区域的摄像机跟踪拍摄火灾情况，自动解锁房间门禁、自动切断风机电源、空调电源；',},
            //     {con:'4. 发生非法入侵时，可联动报警设备所在区域的摄像机，并启动报警功能；',},
            //     {con:'5. 配电装置室SF6浓度超标时，可自动启动相应的风机，并启动报警功能，必要时可联动相应区域内的摄像机；',},
            //     {con:'6. 发生水浸时，可自动启动相应的水泵排水；',},
            //     {con:'7. 可实现对室内环境温度、湿度的实时采集。可自动启动或关闭通风空调系统；',},
            //     {con:'8. 视频监控子系统具有与火灾自动报警子模块联动的功能。发生火灾时，火灾发生点处附近的摄像机可跟踪拍摄火灾情况，监视器画面可自动切换，以显示火灾情况。',},
            //   ]
            // },
            // {
            //   tit: '2.3.报警功能',
            //   content: [
            //     {con:'1. 报警类别可区分防盗报警、火灾报警、环境异常报警。报警可根据需要进行分级，报警信号、报警内容可在任何画面自动显示。当发生报警时，相应录像装置可自动进行存盘录像，并同时传送报警信息和相关图像，可自动在电子地图上提示报警位置及类型；',},
            //     {con:'2. 可处理多事件多点报警的能力，报警信息不应丢失和误报；',},
            //     {con:'3. 可在报警视窗内提供报警信号的详细信息；',},
            //     {con:'4. 报警时可提供语音报警和电话、传呼报警等多种方式；',},
            //     {con:'5. 可通过弹出菜单等方式设置报警联动的摄像机，可以联动摄像机的不同预置位；',},
            //     {con:'6. 所有报警信息均可查询，必要时可打印输出。',},
            //   ]
            // },
            // {
            //   tit: '2.4.视频显示功能',
            //   content: [
            //     {con:'1. 可实现实时图像监视，视频图像大小可随意可调；',},
            //     {con:'2. 可实现主辅视频及辅助视频端口的本地输出；',},
            //     {con:'3. 可实现1/4/9/16画面预览，预览通道顺序可调；',},
            //     {con:'4. 可实现预览分组切换、手动切换或自动轮巡预览，自动轮巡周期可设置；',},
            //     {con:'5. 可实现预览的电子放大。可支持屏蔽指定的预览通道；',},
            //     {con:'6. 可实现预置点、巡航路径及轨迹。云台控制时，可实现鼠标点击放大、鼠标拖动跟踪功能。',},
            //   ]
            // },
            // {
            //   tit: '2.5.图像储存回放功能',
            //   content: [
            //     {con:'1. 录像触发模式包括手动、定时、报警、移动侦测、动测或报警、动测和报警等；',},
            //     {con:'2. 每天可设定多个录像时间段，不同时间段的录像触发模式可独立设置；',},
            //     {con:'3. 可移动侦测录像、报警录像、动测和报警录像、动测或报警录像的预录及延时，定时和手动录像的预录；',},
            //     {con:'4. 可按事件查询录像文件。可实现录像文件的锁定和解锁；',},
            //     {con:'5. 可在回放时对任意区域进行局部电子放大。可实现回放时的暂停、快放、慢放、前跳、后跳，支持鼠标拖动定位。可实现多路同步回放，可通过资源配置加以调整。',},
            //   ]
            // },
            // {
            //   tit: '2.6.遥控操作的智能验证',
            //   content: [
            //     {con:'当一体化监控系统对某个设备发出遥控指令后，系统通过智能视频分析技术，可以对主变压器、断路器、隔离开关、接地刀闸等设备的各项指标进行智能分析，获取设备的状态参数，智能判断设备状态与一体化监控指令是否一致，并可以实时报警。',},
            //   ]
            // },
            // {
            //   tit: '2.7.事件记录及查询',
            //   content: [
            //     {con:'可以对设备的监视结果进行记录，记录的内容包括变电站设备的运行状态、故障描述信息、故障发现时间、故障处理情况等信息，并可对记录的历史信息进行查询。',},
            //   ]
            // },
            // {
            //   tit: '2.8.电子地图功能',
            //   content: [
            //     {con:'1. 支持在地图上对(设备摄像机、门禁、灯控、电子围栏等)状态进行监视、控制操作和管理；',},
            //     {con:'2. 支持对地图进行放大、缩小和漫游功能；',},
            //     {con:'3. 支持报警事件联动，当报警发生时，支持在地图上直接对变电站进行管理；',},
            //     {con:'4. 在电子地图上自动推出报警地点实时画面；',},
            //     {con:'5. 显示摄像机、报警器、温湿度等视频监控设备的位置及环境监测量的数值；',},
            //     {con:'6. 电子地图按变电站、电压等树形分层结构进行直观浏览；',},
            //     {con:'7. 各级电子地图中显示摄像机等设备的分布和报警器设备布防、撤防、报警的状态；',},
            //     {con:'8. 可以对电子地图自行编辑、配置，设置地图，设置摄像机、报警器的位置；',},
            //     {con:'9. 可以在电子地图浏览视频图像；',},
            //     {con:'10. 地图信息保存在数据库中。',},
            //   ]
            // },
          ]},
        ]},
        {title: '环境监测子系统', content: [
          {collapse:[
            {
              tit: '（1）系统功能及构成',
              content: [
                {con:'环境监测系统通过各类传感器的部署来监视全变电站的运行环境状况，使变电站设备处于良好的运行环境中。',},
                {con:'站端硬件技术及系统功能满足智能变电站辅助控制系统设计技术规范、智能变电站辅助系统综合监控平台通用技术规范中相关要求。',},
              ]
            },
            {
              tit: '（2）通信接口及规约',
              content: [
                {con:'温湿度传感器、SF6泄露传感器、水浸传感器、液位传感器等前端设备采用RS485与辅控系统后台通信，按需配置SF6监测值显示器。',},
              ]
            },
            {
              tit: '（3）前端硬件布置',
              content: [
                {con:'1. 二次设备室、电容器室、站用变室、开关室等重要设备间，面积小于20平方米，每个房间布置1台温湿度监测器；面积大于20平方米，各布置2台温湿度检测器；',},
                {con:'2. 根据电缆层面积大小，配置若干水浸传感器；',},
                {con:'3. 每台空调配置1个空调控制器（根据空调型号选配）；',},
                {con:'4. 每路风机配置1个风机控制器。',},
              ]
            },
            {
              tit: '（4）电源供给',
              content: [
                {con:'1. 前端设备电源在屏柜内集中通过配电回路配送，机柜中安装配电接线端子，每路电源都有标签详细说明，便于维护、检修；',},
                {con:'2. 前端探测器包括温湿度、水浸、风速、SF6探测器、空调控制器的供电由柜内综合电源提供；综合电源与屏柜内配电器联接。',},
              ]
            },
            {
              tit: '（5）光/电缆选择',
              content: [
                {con:'温度传感器、湿度传感器、SF6泄露传感器、水浸传感器到环境监测子系统采用阻燃屏蔽电缆ZR-RVVP02-4*1.0实现信号的传输和供电。考虑到信号传输的实时性要求，建议邻近布置的多个传感器合并为一路实现信号传输。',},
              ]
            },
          ]},
        ]},
        {title: '灯光控制子系统', content: [
          {collapse:[
            {
              tit: '(1)系统功能及构成',
              content: [
                {con:'在采用室内网络快球且照度条件不满足摄像机照度要求时需补充灯光照明，跟房间照明联动，根据现场实际需求由辅控后台通过灯光智能控制单元发送控制信号。',},
                {con:'固定式摄像机采用单独配置补光灯方式满足摄像机对照度的要求。',},
                {con:'灯光控制子系统中站端硬件技术及系统功能满足智能变电站辅助控制系统设计技术规范、智能变电站辅助系统综合监控平台通用技术规范中相关要求。',},
              ]
            },
            {
              tit: '(2)通信接口及规约',
              content: [
                {con:'灯光智能控制单元采用硬结点方式实现对室内照明的联动控制。',},
              ]
            },
            {
              tit: '(3)前端硬件布置',
              content: [
                {con:'灯光控制系统无前端设备，只在遥视照明设计中在安装有室内网络快球的房间配置照明控制箱，接收辅控后台发送的硬接点信号。',},
              ]
            },
            {
              tit: '(4)电源供给',
              content: [
                {con:'站端系统设备应使用变电站内AC220V/50Hz交流电源；',},
              ]
            },
            {
              tit: '(5)光/电缆选择',
              content: [
                {con:'照明控制箱与辅控系统间连接采用阻燃屏蔽电缆ZR-RVVP4*1.0连接。',},
              ]
            },
          ]},
          // {tit:'(1)系统功能及构成',},
          // {con:'在采用室内网络快球且照度条件不满足摄像机照度要求时需补充灯光照明，跟房间照明联动，根据现场实际需求由辅控后台通过灯光智能控制单元发送控制信号。',},
          // {con:'固定式摄像机采用单独配置补光灯方式满足摄像机对照度的要求。',},
          // {con:'灯光控制子系统中站端硬件技术及系统功能满足智能变电站辅助控制系统设计技术规范、智能变电站辅助系统综合监控平台通用技术规范中相关要求。',},
          // {tit:'(2)通信接口及规约',},
          // {con:'灯光智能控制单元采用硬结点方式实现对室内照明的联动控制。',},
          // {tit:'(3)前端硬件布置',},
          // {con:'灯光控制系统无前端设备，只在遥视照明设计中在安装有室内网络快球的房间配置照明控制箱，接收辅控后台发送的硬接点信号。',},
          // {tit:'(4)电源供给',},
          // {con:'站端系统设备应使用变电站内AC220V/50Hz交流电源；',},
          // {tit:'(5)光/电缆选择',},
          // {con:'照明控制箱与辅控系统间连接采用阻燃屏蔽电缆ZR-RVVP4*1.0连接。',},
        ]},
        {title: '其他系统接入定义', content: [
          {collapse:[
            {
              tit: '(1)火灾消防报警系统',
              content: [
                {con:'火灾消防报警系统优先采用IEC61850规约将信号上送到变电站辅助设备监控系统中。',},
              ]
            },
            {
              tit: '(2)水冷却系统',
              content: [
                {con:'若变电站为地下变电站时变电站辅助设备监控系统需负责水冷却系统的接入。',},
                {con:'水冷却系统优先采用IEC61850规约的方式将信号上送到变电站辅助设备监控系统中。',},
              ]
            },
          ]},
          // {tit:'(1)火灾消防报警系统',},
          // {con:'火灾消防报警系统优先采用IEC61850规约将信号上送到变电站辅助设备监控系统中。',},
          // {tit:'(2)水冷却系统',},
          // {con:'若变电站为地下变电站时变电站辅助设备监控系统需负责水冷却系统的接入。',},
          // {con:'水冷却系统优先采用IEC61850规约的方式将信号上送到变电站辅助设备监控系统中。',},
        ]},
        {title: '带宽要求', content: [
          {con:'辅助设备监控系统与调度（调控）中心通过安全II区上行通信，通信带宽大于4Mbps，不具备条件时，可采用2Mbps。单路视频上行网络带宽（与调度主站通信带宽）具体可参照如下执行：',},
          {con:'1. 130万像素的摄像机（720p），画面上行网络带宽不大于2Mbps；',},
          {con:'2. 200万像素的摄像机（1080p），画面上行网络带宽不大于4Mbps；',},
          {con:'3. 多画面情况下，上行网络带宽不大于4Mbps；',},
          {con:'4. 其他情况（如带宽资源丰富、1000万像素以上的摄像机等），上行网络带宽可大于4Mbps。',},
        ]},
        {title: '安装要求', content: [
          {con:'1.站端视频处理单元、综合电源、环境处理单元、网络交换机、网桥等均应组屏安装；',},
          {con:'2.前端设备（摄像机、红外对射报警器、温湿度传感器等）就近安装在配电装置设备附近；',},
          {con:'3.辅助设备监控系统所使用的通用电缆可分为；',},
          {con:'4.（铠装）阻燃屏蔽电缆（导线截面积不小于0.5mm2）；',},
          {con:'5.（铠装）阻燃电源电缆（导线截面积不小于1.0mm2）；',},
          {con:'6.屏蔽网络线、光缆等；',},
          {con:'7.非铠装电缆敷设时，电缆沟、弱电桥架、竖井内、室内墙内暗埋套穿PVC管；',},
          {con:'8.所有电缆埋地敷设时套穿热镀锌管；',},
          {con:'9.技防报警子系统、环境监测子系统、火灾报警子系统及视频监控前端设备、环境监测前端设备与一体化智能变电站辅助设备监控系统后台处于同一建筑物内且距离小于100m时宜采用六类屏蔽双绞线联接，其余采用单模光纤互联；',},
          {con:'10.电缆选择和敷设应满足GB50116、GB50217、GB50395相关要求。',},
        ]},
        {title: '试验要求', content: [
          {con:'试验标准参照国标及IEC相应标准进行。若辅助装置是第一次在国内同类变电站中采用，则应按国内标准进行试验。',},
          {collapse:[
            {
              tit: '(1) 型式试验',
              content: [
                {tit:'1.1.绝缘性能',},
                {con:'1. 绝缘电阻；',},
                {con:'2. 介质强度；',},
                {con:'3. 冲击电压。',},
                {tit:'1.2.耐湿热性能',},
                {con:'根据试验条件和使用环境，在以下两种方法中选择其中一种。',},
                {con:'1. 恒定湿热；',},
                {con:'2. 交变湿热。',},
                {tit:'1.3.抗电气干扰性能',},
                {con:'1. 承受辐射电磁场抗干扰的能力；',},
                {con:'2. 承受快速瞬变干扰的能力；',},
                {con:'3. 承受脉冲群干扰的能力；',},
                {con:'4. 承受静电放干扰的能力。',},
                {tit:'1.4.直流电源影响',},
                {tit:'1.5.静态模拟、动态模拟',},
                {tit:'1.6.连通通电',},
                {con:'1. 振动（正弦）；',},
                {con:'2. 冲击；',},
                {con:'3. 碰撞。',},
                {tit:'1.7.结构和外观',},
                {con:'装置机箱和摄像机等应为金属外壳，装置机箱前面有透明面罩。装置机箱和摄像机等采用防静电及防强电磁辐射干扰的措施。装置机箱和摄像机等的不带电金属部分在电气上连成一体，并可靠接地。设备满足发热元件的通风散热要求。',},
                {con:'设备有安全标志。',},
              ]
            },
            {
              tit: '(2) 出厂试验',
              content: [
                {con:'出厂试验验收试验的结果应与由型式试验所得到的结果相似，满足GB标准、IEC标准及本技术规范的技术要求。出厂试验也应由与型式试验相同的方法进行。',},
              ]
            },
            {
              tit: '(3) 现场试验',
              content: [
                {con:'至少应进行下列试验和检查：',},
                {con:'1. 直观检查；',},
                {con:'2. 通入电源检查；',},
                {con:'3. 回路检查；',},
                {con:'4. 所有接线正确性的检查及试验；',},
                {con:'5. 绝缘试验；',},
                {con:'6. 性能试验。由买方确定必要的重复试验；',},
                {con:'7. 整组试验。',},
                {con:'其他必要的试验等。',},
              ]
            },
          ]},
          // {tit:'(1) 型式试验',},
          // {tit:'1.1.绝缘性能',},
          // {con:'1. 绝缘电阻；',},
          // {con:'2. 介质强度；',},
          // {con:'3. 冲击电压。',},
          // {tit:'1.2.耐湿热性能',},
          // {con:'根据试验条件和使用环境，在以下两种方法中选择其中一种。',},
          // {con:'1. 恒定湿热；',},
          // {con:'2. 交变湿热。',},
          // {tit:'1.3.抗电气干扰性能',},
          // {con:'1. 承受辐射电磁场抗干扰的能力；',},
          // {con:'2. 承受快速瞬变干扰的能力；',},
          // {con:'3. 承受脉冲群干扰的能力；',},
          // {con:'4. 承受静电放干扰的能力。',},
          // {tit:'1.4.直流电源影响',},
          // {tit:'1.5.静态模拟、动态模拟',},
          // {tit:'1.6.连通通电',},
          // {con:'1. 振动（正弦）；',},
          // {con:'2. 冲击；',},
          // {con:'3. 碰撞。',},
          // {tit:'1.7.结构和外观',},
          // {con:'装置机箱和摄像机等应为金属外壳，装置机箱前面有透明面罩。装置机箱和摄像机等采用防静电及防强电磁辐射干扰的措施。装置机箱和摄像机等的不带电金属部分在电气上连成一体，并可靠接地。设备满足发热元件的通风散热要求。',},
          // {con:'设备有安全标志。',},
          // {tit:'(2) 出厂试验',},
          // {con:'出厂试验验收试验的结果应与由型式试验所得到的结果相似，满足GB标准、IEC标准及本技术规范的技术要求。出厂试验也应由与型式试验相同的方法进行。',},
          // {tit:'(3) 现场试验',},
          // {con:'至少应进行下列试验和检查：',},
          // {con:'1. 直观检查；',},
          // {con:'2. 通入电源检查；',},
          // {con:'3. 回路检查；',},
          // {con:'4. 所有接线正确性的检查及试验；',},
          // {con:'5. 绝缘试验；',},
          // {con:'6. 性能试验。由买方确定必要的重复试验；',},
          // {con:'7. 整组试验。',},
          // {con:'其他必要的试验等。',},
        ]},
        {title: '附录', content: [
          {collapse:[
            {
              tit: '附录A  110kV地下（地上联建）变电站辅助设备监控系统设备材料表',
              content: [
                {url:require('../assets/images/product/2.png')},
              ]
            },
            {
              tit: '附录B 110kV独立地上变电站辅助设备监控系统设备材料表',
              content: [
                {url:require('../assets/images/product/3.png')},
              ]
            },
            {
              tit: '附录C 35kV地下变电站辅助设备监控系统设备材料表',
              content: [
                {url:require('../assets/images/product/4.png')},
              ]
            },
            {
              tit: '附录D 35kV地上变电站辅助设备监控系统设备材料表',
              content: [
                {url:require('../assets/images/product/5.png')},
              ]
            },
            {
              tit: '附录E 35kV开关站辅助设备监控系统设备材料表',
              content: [
                {url:require('../assets/images/product/6.png')},
              ]
            },
          ]},
        ]},
      ]
    },
    infrared: {
      title: '特高压重症（红外）监护平台',
      content: [
        {title: '平台开发导则', content: [
          {con:'特高压重症监护平台采用开放式系统，系统设备配置和功能满足无人值班技术要求。',},
          {con:'特高压重症监护平台满足全站安全运行要求。系统后台设备按全站最终规模配置，前端设备按最终建设规模配置。',},
          {con:'特高压重症监护平台具备逻辑模型展示能力，可以正确读取、显示、组织相关模型和实时信息数据。',},
        ]},
        {title: '平台系统架构', content: [
          {con:'特高压重症监护平台系统由红外监控装置、网络传输装置、电源装置、后台监护系统组成，实现对站内需要监护的设备的监视。',},
          {url:require('../assets/images/2023/product/01.png')},
        ]},
        {title: '平台功能描述（后台系统）', content: [
          {collapse:[
            {
              tit: '全天候监控',
              content: [
                {con:'利用红外热像监控系统通过远端控制，无需看管而实现全天候运行。',},
              ]
            },
            {
              tit: '自动报警',
              content: [
                {con:'当监测到温度异常时生成报警信息，通知相关人员查看现场情况，及时做出相应处理。',},
              ]
            },
            {
              tit: '实时显示',
              content: [
                {con:'实时显示全辐射红外热图，值班人员可查看任意位置的温度。',},
              ]
            },
            {
              tit: '报警记录',
              content: [
                {con:'当变电设备出现温度异常，监控后台会及时发现温度异常区域，触发报警，声光报警模块会发出报警声且软件后台会记录报警地点和区域，将异常图片存入指定文件夹下，方便后期查找调用。',},
              ]
            },
            {
              tit: '报警查询',
              content: [
                {con:'可查询报警情况且对报警区域可再进行分析处理。',},
              ]
            },
            {
              tit: '自定义报警阈值和等级',
              content: [
                {con:'系统可内定义4个不同报警阀值和等级，支持I/O 输出以便与消防、广播等系统联动，协助工作人员对隐患发生的紧急程度、发展势态和可能造成的危害程度作出准确评估，提升处理的有效性',},
              ]
            },
            {
              tit: '短信报警',
              content: [
                {con:'当温度出现异常时，可通过短信收发模块通知相关人员注意现场情况，第一时刻了解现场情况并且做出相应处理。',},
              ]
            },
            {
              tit: '专业检测报告',
              content: [
                {con:'可对异常情况进行录制、拍照、分析，并出具专业检测报告。',},
              ]
            },
          ]},
          // {con:'全天候监控：利用红外热像监控系统通过远端控制，无需看管而实现全天候运行。',},
          // {con:'自动报警：当监测到温度异常时生成报警信息，通知相关人员查看现场情况，及时作出相应处理。',},
          // {con:'实时显示：实时显示全辐射红外热图，值班人员可查看任意位置的温度。',},
          // {con:'报警记录：当变电设备出现温度异常，监控后台会及时发现温度异常区域，触发报警，声光报警模块会发出报警声且软件后台会记录报警地点和区域，将异常图片存入指定文件夹下，方便后期查找调用。',},
          // {con:'报警查询：可查询报警情况且对报警区域可再进行分析处理。',},
          // {con:'自定义报警阈值和等级：系统可内定义4个不同报警阀值和等级，支持I/O 输出以便与消防、广播等系统联动，协助工作人员对隐患发生的紧急程度、发展势态和可能造成的危害程度作出准确评估，提升处理的有效性',},
          // {con:'短信报警：当温度出现异常时，可通过短信收发模块通知相关人员注意现场情况，第一时刻了解现场情况并且做出相应处理。',},
          // {con:'可对异常情况进行录制、拍照、分析，并出具专业检测报告。',},
          {url:require('../assets/images/2023/product/02.png')},
        ]},
        {title: '平台功能描述（前台功能）', content: [
          {con:'红外热像仪可连续、自动、非接触采集多点温度，可用于关键设备工作状态监测，其探测芯片为焦平面阵列FPA，非制冷微热量，图像分辨率可达384×288、640×480，测温范围从-20~350℃，热灵敏度NETD≤50mk@30℃，测温点数量达110592个或更多，可自动识别并追踪最高温度位置，并根据用户报警设置对超过预警温度的设备进行报警，加上压铸铝整体护罩后，整体防护等级IP66，护罩防护等级IP67。',},
        ],},
      ],
    },
    microenvironment: {
      title: '微环境状态监测平台',
      content: [
        {title: '平台开发导则', content: [
          {con: '微环境状态监测平台采用开放式系统，系统设备配置和功能满足无人值班技术要求。', url: null},
          {con: '微环境状态监测平台满足全站安全运行要求。系统后台设备按全站最终规模配置，前端设备按最终建设规模配置。', url: null},
          {con: '微环境状态监测平台具备逻辑模型展示能力，可以正确读取、显示、组织相关模型和实时信息数据。', url: null},
        ],},
        {title: '平台系统架构', content: [
          {con: '微环境状态监测平台系统由传感器装置、集中采集装置、Lora/NB-IoT无线传输装置、后台监护系统组成，实现对站内设备需要监测的微环境的监视。', url: null},
          {url:require('../assets/images/2023/product/03.png')},
        ],},
        {title: '平台功能描述（后台系统）', content: [
          {collapse:[
            {
              tit: '全天候监视',
              content: [
                {con: '利用温湿度、水浸传感器无线采集系统，无需看管而实现全天候运行监视户外设备微环境。', url: null},
              ]
            },
            {
              tit: '自动报警',
              content: [
                {con: '当监测到微环境异常时生成报警信息，通知相关人员查看现场情况，及时作出相应处理。', url: null},
              ]
            },
            {
              tit: '实时显示',
              content: [
                {con: '实时显示微环境各项采集信息，值班人员可查看任意位置的温度、湿度。', url: null},
              ]
            },
            {
              tit: '报警记录',
              content: [
                {con: '当微环境采集信息异常，监控后台会及时发现微环境异常区域，触发报警，且软件后台会记录报警位置和时间，并保存在历史记录中，方便后期查找调用。', url: null},
              ]
            },
            {
              tit: '报警查询',
              content: [
                {con: '可查询报警情况且对报警信息可进行分析处理。', url: null},
              ]
            },
            {
              tit: '自定义报警阈值和等级',
              content: [
                {con: '系统可内定义不同报警阀值和等级，便于在不同环境下有针对性的设定报警阈值。', url: null},
              ]
            },
          ]},
          {url:require('../assets/images/2023/product/04.png')},
        ],},
        {title: '平台功能描述（前台功能）', content: [
          {con: '温湿度采集装置、水浸传感器使用无线lora方式上传信号，可完美解决现场电源不易解决的难题，施工便捷、易于安装。', url: null},
          {con: '安装于设备机构箱内，可精确测量机构箱内的温度和湿度或水浸情况', url: null},
          {con: '传输方式：无线Lora', url: null},
          {con: '传输频率：433MHz', url: null},
          {con: '传输距离：≤500m', url: null},
          {con: '供电方式：3.6V 锂亚电池，4000mAh', url: null},
          {con: '待机电流：≤2uA', url: null},
          {con: '工作寿命: 〉5年（1次/小时，如采集周期延长可增加工作寿命）', url: null},
          {con: '发送方式：（1）采集温度/湿度波动≥设定值时发送（2）设定时间定时强制发送当前采集值；两项功能可同时启用。', url: null},
          {con: '测量范围（温湿度）：', url: null},
          {con: '温度： -40 ... +125°C	 (精度 1°C)', url: null},
          {con: '湿度：0%RH ～ 99.9%RH		 (精度3 % )', url: null},
          {con: '外壳：IP67 防水塑壳', url: null},
          {con: '尺寸：90*50*42mm', url: null},
        ],},
      ],
    },
    ai: {
      title: 'AI视觉“精确识别”',
      content: [
        {content: [
          {con: '视觉技术作为AI技术中应用最成熟的一个方向，已经催生出较为成熟的应用和巨大的商业价值。'},
          {con: 'AI视觉精确识别目前应用最为成熟的领域毋庸置疑是人脸识别，同时还包括电商网站，搜索引擎上的根据图片来检索出商品信息，并提供推荐的商品链接。目前在电力系统的生产运行过程中，用到的视觉技术主要是用于设备测温，AI视觉识别技术主要用于故障的识别和危险行为告警。'},
          {con: '经过我司多年的积累和与中科院等知名高校的联合研发，已经形成了一套可商用的，基于视觉AI精确识别的核心技术。'},
          {con: '其衍生产品能够实现针对工业品（含MRO产品）的实时识别，重要物资的实时盘点，各样式表计数据实时读取等功能，可应用于零星物资领用柜、小型仓库出入库管理、重点物资的库存实时监控，圆形，数字形，柱状表，开关型等表盘的数值和状态实时读取。可以实现有一双人眼24小时帮助用户观察某一特定的场景的变化，即完美实现非介入式的设备状态信息收集和实时诊断功能，具有广阔的市场前景。'},
        ]},
        {title: '表计数值AI精确自动读取装置', content: [
          {con: '各电压等级变电站或各类型操作室、控制室中，有大量的设备的表计需要读数和记录。'},
          {con: '其工作量重要且繁琐，目前较为常见的方法就是通过导轨机器人进行拍照，而后回到后台后进行数据处理后，完成读数的记录，替代人工到现场巡视。而且伴随自动化程度提高，会有更多无人值守的站出现，那么表计数据的读取越发需要自动化的手段进行替代。'},
          {con: '为什么不让设备具有数据远传的功能？这样就不用再去读表了，这里面有诸多原因，其一，大量的基础设施是几年前投运的，本身不具备远传功能，且不具备加装远程读表设备的条件；其二，有些重要的设备，即便是加装了远程读表的功能，还是需要现场去读表以及观测。'},
          {con: '针对上述情况，我们自主研发出可多种模式下运行的进行表计数据读取和识别的AI摄像头，其优势在于AI算法强大，自学习能力强，设备安装简单，成本低。产品的主要功能点有：'},
          {collapse:[
            {
              tit: '（1）建立表计模型：',
              content: [
                {con: '默认的标记模型有圆标，扇形表，数字表盘，开关压板，指示灯，包括了变电站内绝大部分的需观测和记录的表计数据；如果有新的表盘需要识别，只需要在系统中上传该表盘的清晰图像和视频片段，同时说明一下读数的规则即可；'},
              ]
            },
            {
              tit: '（2）无源模式：',
              content: [
                {con: '通过摄像机自带电源，进行前端拍摄，表计数据识别，识别后的数据回传，对应照片回传，数据存档备查，如果按照每天一次读表的要求，一次充电可续航3年以上；支持远程唤醒临时拍摄图片，同时支持拍摄和回传视频数据（会增加用电损耗）；'},
              ]
            },
            {
              tit: '（3）有源模式：',
              content: [
                {con: '可通过POE供电的方式进行供电，可以长时间的保持相机在线，完成相关数据的采集和告警；同时也可以成为一个监控或重症监护系统的摄像头予以使用，完成相关配套的数据收集和分析工作；'},
              ]
            },
            {
              tit: '（4）数据传输模式：',
              content: [
                {con: '包含了Wifi，4G/5G，RJ45为主，支持扩展LORA，ZIGBEE的低功耗模式；'},
              ]
            },
            {
              tit: '（5）数据分析：',
              content: [
                {con: '表计读数趋势分析，超阈值自动告警，设备运行状态分析等可以为设备或环境提供基于连续性数据的分析和突发性情况的告警。'},
              ]
            },
          ]},
          {con: '', url: require('../assets/images/product/12.png')},
        ]},
        {title: '零星电商物资集中收发一体机', content: [
          {con: '生产类或非标类的零星物资往往没有赋国际码（69码）或商品码，无码可扫难以直接开展数字化管理。而通过AI视觉精确识别的技术，能够依托物资的外形、外包装等视觉维度实现对所需管理物资的扫码识别，并通过与柜门的联动，身份的验证，结合一体机配套的线上审批流程和出入库管理流程，可以实现零星物资在无人值守下的领用管理，由于产品使用视觉技术管理出入库，即：出入库时只要随手放入相应的位置或直接取出即可，相比传统物资的数字化出入库管理，其操作的便利性具有巨大优势。该产品适用于大型集团性企业高效的管理零星物资的领用。产品主要功能点有：'},
          {collapse:[
            {
              tit: '（1）便捷建模：',
              content: [
                {con: '提供视频和图片的方式自主建模功能，建模完毕之后，该物资即可通过视觉识别的方式纳入管理'},
              ]
            },
            {
              tit: '（2）平台联动：',
              content: [
                {con: '柜体开关门与APP，PC端管理平台联动，实现物料申请，物料审批，库存进销存，领用报表等功能；'},
              ]
            },
            {
              tit: '（3）物资入库：',
              content: [
                {con: '通过APP/PC端发起入库申请，审批通过后生成入库条码，经柜体识别条码后开门，随后将所需入库的物资直接放置到柜内后关门即可，系统将自扫描柜内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；'},
              ]
            },
            {
              tit: '（4）物资出库：',
              content: [
                {con: '通过APP/PC端发起出库申请，审批通过后生成出库条码，经柜体识别后开门，随后取出所需领用的物资后关门即可，系统将自扫描柜内物资库存变动的情况并与入库申请单进行复核，若不一致，将予以警示；提货时将同时启动监控视频予以全程记录存档；值得一提的是，整个出入库的过程都极为简便，可操作性强。'},
              ]
            },
          ]},
        ]},
        {title: '生产班组库房物资管理数字化改造', content: [
          {con: '将零星物资集中收发一体机的机制放大，可以扩展成小型专业仓的出入库管理。除了将仓库的门禁系统替换原柜门开关机制，并建立灯光联动系统，其他管理机制类似。以此可以打造一个24小时响应的无人值守的专业仓。产品的主要功能点有：'},
          {collapse:[
            {
              tit: '（1）便捷建模：',
              content: [
                {con: '提供视频和图片的方式自主建模功能，建模完毕之后，该物资即可通过视觉识别的方式纳入管理；'},
              ]
            },
            {
              tit: '（2）平台联动：',
              content: [
                {con: '专业仓门禁与APP，PC端管理平台联动，实现物料申请，物料审批，库存进销存，领用报表等功能；'},
              ]
            },
            {
              tit: '（3）库存识别机制：',
              content: [
                {con: '专业仓内分设扫码识别工作台区和自动识别区。工作台识别区主要用于识别个体较小的物资，批量处理；自动识别区为仓库大部分的可自由放置物资的开放区域，包括仓库内的地面，货架等区域，在这部分区域内的物资可被视觉系统自动识别，一旦库存发生变动，系统将自动更新库存，如有异常将自动告警；'},
              ]
            },
            {
              tit: '（4）物资入库：',
              content: [
                {con: '通过APP/PC端发起入库申请，审批通过后生成入库条码，经门禁系统识别条码后开门，小型物资通过工作台逐一扫码或手工辅助输入数量后放置在仓库内非自动识别区，大型物资可直接放入自动识别区，而后关门即可，系统将自扫描仓库内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；'},
              ]
            },
            {
              tit: '（5）物资出库：',
              content: [
                {con: '通过APP/PC端发起入库申请，审批通过后生成出库条码，经门禁系统识别条码后开门，小型物资通过工作台逐一扫码或手工辅助输入数量后进行提货，大型物资可直接提货，而后关门即可，系统将自扫描仓库内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；提货时将同时启动监控视频予以全程记录存档；'},
              ]
            },
            {
              tit: '（6）物资出库：',
              content: [
                {con: '通过APP/PC端发起出库申请，审批通过后生成出库条码，经柜体识别后开门，随后取出所需领用的物资后关门即可，系统将自扫描柜内物资库存变动的情况并于入库申请单进行复核，若不一致，将予以警示；值得一提的是，整个出入库的过程都极为简便，可操作性强。'},
              ]
            },
          ]},
          {con: '', url: require('../assets/images/product/13.png')},
        ]},
        {title: '生产物资库存自动盘点解决方案', content: [
          {con: '根据视觉技术在物资精确识别方面的成功经验，可以帮助大型集团型企业对自建大型仓库的库存物资进行自动盘点，或者对其供应商协议库存备货量进行自动盘点。该解决方案的主要功能点有:'},
          {collapse:[
            {
              tit: '（1）便捷建模：',
              content: [
                {con: '提供视频和图片的方式自主建模功能，建模完毕之后，该物资即可通过视觉识别的方式纳入管理；'},
              ]
            },
            {
              tit: '（2）仓库存储区域、货架区域的摄像头设置：',
              content: [
                {con: '平均每个摄像头至少可以看管4m*4m的面积，包括地面面积和货架纵向界面的面积，如果摄像头放置合理，单个摄像头看管面积还可以成倍扩大；'},
              ]
            },
            {
              tit: '（3）频率警报：',
              content: [
                {con: '在管控区域内可以任意设置盘点的频率，发布库存异动的报表或警告。能够与客户的进销存等业务系统中的盘点模块对接，可实时通报库存异常的情况，便于用户快速的发现问题，解决问题；'},
              ]
            },
            {
              tit: '（4）库存盘查：',
              content: [
                {con: '如果需要对协议供货供应商的协议库存进行备查，通过视觉的解决方案是效率最高，实操性最强的方法之一。其主要工作机制与库存盘点类似，先需要构建所需管理物资的模型，然后再存储区域安装摄像头，要求供应商把物资按规定放置在存储区域即可，系统将开展自动盘点的工作，一旦库存量低于合同约定的阀值，则自动通知用户，且予以多方告警。充足的库存才能保证用户各项工作的正常和顺利的开展。'},
              ]
            },
          ]},
        ]},
      ],
    },
    substation: {
      title: '为变电站提供服务',
      content: [
        {title: '智能变电站辅助设备监控系统:', content: [
          {con: '变电站辅助设备监控系统部署在变电站，包括辅助设备监控系统主机、工作站等主要设备，集成变电站 安防、消防、动环、锁控及状态监测信息，实现辅助设备数据采集、运行监视、操作控制、对时、权限、配置、数据存储、报表以及智能联动管理，为变电站综合监控提供辅助信息支撑。'},
          {con: '变电站辅助设备监控系统由视频监控子系统、技术防范报警子系统、环境监测子系统、灯光控制子系统、火灾报警子系统、水冷却子系统（地下变电站）组成，实现对站内安防、消防、视频、环境监测等辅助设备的监视与控制。技术防范报警子系统、火灾报警子系统、水冷却子系统设置独立主机与辅控后台系统通信，其他子系统均不设置独立主机，由辅控后台系统集成。'},
          {con: '变电站辅助设备监控系统与辅助设备进行通信，采集一次设备、消防、安防、环境监测等信息，经过分析和处理后进行可视化展示，并将数据存入综合应用服务器。Ⅱ区数据通信网关机从综合应用服务器获取Ⅱ区数据和模型等信息，与调度（调控）中心进行信息交互，提供信息查询和远程浏览服务。'},
          {con: '技术防范报警子系统、火灾报警子系统、水冷却子系统通过独立主机与辅控后台系统通信，视频监控、环境监测前端智能设备、灯光控制系统以通信接口接入辅控后台系统。站内所有辅助设备实现数据汇集到变电站辅助设备监控系统后，通过II区通信网关机走调度数据专网将信息上送到调度主站。'},
          {con: '以下为完整辅控系统的拓扑架构图：'},
          {url:require('../assets/images/2023/product/05.png')},
          {con: '值得一提的是，我们可以根据不同的变电站等级和用户需求，对系统进行裁剪和定制开发，以满足不同等级的变电站（包括特高压站，220站，110KV站，35KV站，P站，K站等）辅控的要求。'},
        ]},
        {title: '变电站电子安防和防恐提升平台:', content: [
          {con: '在变电站的实际应用中，各个监控系统均各成体系、师出多门，存在设备型号复杂、损坏后不便维护、在系统相似或交叉领域重复投资等诸多问题,在业务协调、联动还存在困难。'},
          {con: '为了进一步提高变电站智能化管理水平，适应变电站逐步向“无人值守”发展的要求，需要建立一套统一的管理维护平台——“变电站综合安防系统”来实现对各辅助系统的整合和管理，这也逐步成为国内变电站建设和改造的重要内容和发展趋势。正因如此，我司在国网上海市电力公司的要求下，开始了变电站电子安防平台的开发和建设，同时该平台的建设满足国网变电站治安反恐防范工程的各项要求。'},
          {con: '变电站电子安防平台是我司采用自动化技术、计算机技术、网络通信技术、视频压缩技术、电子脉冲技术、RFID技术、微波探测技术、声控探测技术以及智能控制等多种技术，通过对变电站动力环境、边界、图像、门禁识别、火灾报警、安防报警实现对变电站的重点区域防控、防盗、入侵、消防、照明、采暖通风等方面的在线监视和智能预警。系统通过监测、预警和控制三种手段，为变电站的安全生产提供可靠的保障，从而解决了变电站安全运营的“在控”、“可控”等问题。所使用的通讯规约和标准均按照国网公司的最新标准予以实现。值得一提的是，我司熟悉变电站辅控平台的技术要求，可将两者功能进行灵活的组合，更好的满足用户的需求。平台具体的主要功能包括：'},
          {collapse:[
            {
              tit: '实施标准',
              content: [
                {con: '（1） 电子安防平台按照《变电站（换流站）治安反恐防范工程完善化改造方案（征求意见稿）》的具体要求予以实施；'},
              ]
            },
            {
              tit: '实施要求',
              content: [
                {con: '（2） 电子安防平台以“智能控制”为核心，对全站主要电气设备、关键设备安装地点以及周围环境进行全天候的状态监视和智能控制，以满足电力系统安全生产的要求，同时，该系统可满足变电站安全警卫的要求；'},
              ]
            },
            {
              tit: '数据采集',
              content: [
                {con: '（3） 电子安防平台以网络通信（IEC61850协议）为核心，完成站端音视频、环境数据、火灾报警信息、SF6、门禁以及防盗报警等数据的采集和监控，并将以上信息远传到监控中心或调度中心；'},
              ]
            },
            {
              tit: '系统结构',
              content: [
                {con: '（4） 电子安防平台采用分层、分区的分布式结构，按综合安防系统省级主站系统、综合安防系统地区级主站系统和综合安防系统站端系统三级构建。每级系统必须至少包含主控中心、分控中心和接口系统，以及WEB浏览系统等；'},
              ]
            },
            {
              tit: '监控模式',
              content: [
                {con: '（5） 平台把环境、视频、火灾消防、采暖通风、照明、SF6、防盗报警、门禁等所有监控量在监控系统主界面上进行一体化显示和控制，不得分系统孤立显示和控制。系统监控应提供电子地图监控和图表监控两种监控模式，电子地图可以实现省、市以及变电站的分级分层显示；'},
              ]
            },
            {
              tit: '脱机运行',
              content: [
                {con: '（6） 站端设备能够脱机运行，在网络断线、服务器故障等情况下站端设备依然能够实现正常运行、联动报警、设备控制以及记录存储等；'},
              ]
            },
            {
              tit: '系统、设备联动',
              content: [
                {con: '（7） 平台可以设置平台内的动力环境监控子系统、视频监控子系统、消防子系统、空调及设备控制子系统、SF6监测报警子系统、防盗报警子系统、门禁子系统等各子系统之间进行联动；还可以通过硬件和软件的方式和站内自动化系统进行联动，应能实现用户自定义的设备联动，包括现场设备操作联动视频、综合自动化系统告警联动门禁视频等。并可以根据变电站现场需求，完成自动的闭环控制和告警，如自动启动/关闭空调、自动启动/关闭风机、自动启动/关闭排水系统等；'},
              ]
            },
            {
              tit: '日志报表',
              content: [
                {con: '（8） 系统所有操作以及报警确认，都保留详细的日志并生成相应报表。'},
              ]
            },
          ]},
          {url:require('../assets/images/2023/product/06.png')},
        ]},
      ],
    },
    charge: {
      title: '充电桩主动防御装置',
      content: [
        {content: [
          {con: '截至2022年3月底，全国机动车保有量达4.02亿辆，汽车保有量达3.07亿辆，全国新能源汽车保有量达891.5万辆，占汽车总量的2.90%。其中纯电动汽车保有量724.5万辆，占新能源汽车总量的81.27%。'},
          {con: '未来5-8年内，保守估计将有3亿辆电动车上市，而且纯电车的比例逐步将达到100%，充电桩的需求预计在2亿只，按照每只7.5KW的功率，全部安装需要新增15亿KW的容量，而且还会多出来2亿根表前线。而截至2021年底，全国发电装机容量23.77亿千瓦（数据摘自《人民日报》）。可想而知，这将对电网造成巨大且不可逆的冲击。如果不适度的进行电能调配，主动干预，或者只是盲目的上容量，既不安全，又不经济。'},
          {con: '我们的设备能够有效提升电网的向充电桩输电的效率，在同等负载的情况下，直接输出功率能够提高10%；同时按照现有技术导则计算，同等负载条件下至少可以多安装2倍充电桩。能够实现电力公司，物业，电动车业主的多赢局面，同时能够有帮助电动车的更快速的推广，助力早日达成“双碳”目标。'},
          {con: '通过使用我们的设备，能够大大的延缓电网高峰时期的负载，对电网也有巨大的好处，我们的这种控制系统，是按每支枪进行销售和服务的，而且主动预警更是可以转化为长期的有偿服务，可以为项目带来非常好的盈利前景。'},
          {con: '', url: require('../assets/images/product/16.png')},
        ]},
      ],
    },
    profile: {
      title: 'ABOUT US',
      tit: '旋荣科技股份有限公司',
      url: require('../assets/images/aboutus/正文配图1.png'),
      content: [
        {con: '上海旋荣科技股份有限公司成立于2013年（下简称旋荣科技），是传统和新型电力系统的智能巡检、设备运维和生产物资的供应商，同时也是一体化信息系统和供应链赋能服务的提供商。并努力成为所在领域内的领先企业。公司经营稳健，成长快速，于2016年7月成功在新三板挂牌上市（股票代码：838956），并于2020年4月晋升为创新层企业。'},
        {con: '公司拥有专业的电气自动化，信息化和物联网技术的研发团队，在状态全面感知、数据可靠传输、机器深度学习、视觉成像分析和组件及接口开发方面掌握了核心技术。公司以此为依托，聚焦电力行业，自主研发智能巡检，电子安防平台，设备状态检测及监护平台，变电站数字化表计平台等系列产品。产品应用覆盖包括特高压，超高压，中压，低压在内的全域和全电压等级设备，以及变电站全站的辅控系统，同时公司持续进行产品迭代和新产品的研发，以满足电力系统的最新要求和新型电力系统所带来的变革、挑战和机遇。'},
        {con: '公司同为工器具、仪器仪表、办公物资等MRO类产品和信创类服务器、PC产品的集约化供应商，并从事部分上述产品的研发、OEM贴牌生产、销售及相关技术服务。公司通过批量采购（经销）以及OEM贴牌生产的方式集约化地为用户提供55大类、8万余种SKU的工器具、仪器仪表、办公物资等MRO系列产品，并积累了同类产品大量的原厂资源，形成了包含工器具、仪器仪表、个性化办公物资等可对外赋能的供应链体系。目前业务主要服务于电力、能源、金融行业相关的央国企。'},
        {con: '公司团队具有丰富的央企电商化项目履约经验和深厚的互联网领域的技术积累，自主研发了《助力飞现代智慧供应链》系列产品，助力飞是具有独创性的供应链双向赋能的一体化信息管理平台，主要服务于大型企业自建电商平台业务和中小企业的数字化转型业务。助力飞系列产品包括具有完全自主知识产权的S2B2C供应链新零售系统，B2B2C多用户商城系统、B2C商城系统、直播商城系统、O2O门店系统、B2B商城系统、APP商城系统、微商城 小程序系统、收银系统、骑手配送系统、跨境电商等电子商务软件系统，DDS系统，OA系统，CRM系统，项目管理系统，人员工时管理系统，人员绩效管理系统，进销存系统，WMS系统，TMS系统，厂家直供平台，商品管理平台等子系统，旨在打造企业版“拼多多”和基于多用户实时富余库存的电商平台。并取得60多项著作权及相关证书。'},
        {url: require('../assets/images/2023/aboutus/上市图.png')},
      ],
      rightTitle: '办公地址',
      rightContent: [
        {con: '上海总部'},
        {url: require('../assets/images/2023/icon/position.png')},
        {con: '上海市黄浦区延安东路175号旺角广场13层邮编：200001'},
        {con: '北京分部'},
        {url: require('../assets/images/2023/icon/position.png')},
        {con: '山东分公司'},
        {url: require('../assets/images/2023/icon/position.png')},
        {con: '深圳分公司'},
        {url: require('../assets/images/2023/icon/position.png')},
        {con: '南京分部'},
        {url: require('../assets/images/2023/icon/position.png')},
      ]
    },
    culture: {
      title: 'CULTURE & STRUCTURE',
      tit: '旋荣文化及组织架构',
      content: [
        {url: require('../assets/images/aboutus/灰色LOGO.png'), class: 'logo'},
        {con: '旋荣科技秉承“您的需要，我们承诺”（Promise For Need）的经营理念，为客户提供全方面的服务，始终把客户的需要放在第一位，想客户之所想，急客户之所急。公司不仅在传统物资供应、软件研发和技术服务上具备技术优势，同时为客户提供全方位的增值服务，从而实现客户的精细化管理和精益收入。'},
        {url: require('../assets/images/aboutus/组织架构.png')}
      ]
    },
    honor: {
      title: 'HONOR',
      tit: '旋荣荣誉',
      content: [
        {url: require('../assets/images/aboutus/旋荣荣誉.png')}
      ]
    },
    certification: {
      title: 'CERTIFICATION',
      tit: '资质&专利',
    },
    vision: {
      title: 'GOALS & VISIONS',
      tit: '我们的目标和愿景',
      url: require('../assets/images/aboutus/正文配图.png'),
      content: [
        {tit: '我们的目标', class: 'title'},
        {con: '构建基于多用户共享库存的电子商务平台；'},
        {con: '有5000个原厂级别供应商加入厂家直供平台；'},
        {con: '助力飞一体化系统的销售价格控制在千元的级别；'},
        {con: '助力飞拥有10万以上的中小企业用户；'},
        {tit: '我们的愿景', class: 'title'},
        {con: '做性价比最高的商用一体化管理系统，让中小企业能用得起，用得好；'},
        {con: '在基本不用变更企业组织架构和流程且不增加企业额外负担的情况下，助力中小企业实现业务的数字化转型；'},
        {con: '让国内所有的中小企业的经营效率提高5%。'},
      ],
    },
    service: {
      title: 'SERVICE CONTENT',
      tit: '我们提供的服务内容',
      url: require('../assets/images/aboutus/提供服务.png'),
      content: [
        {con: '我们为企业客户提供一体化的互联网服务，其中包括企业一体化信息系统服务，企业供应链双向赋能服务和企业业务数字化转型服务。我们的信息化服务是基于完全自主研发的包含多用户电子商务平台（S2B2B2C）的ERP产品，名为助力飞现代智慧供应链（下简称助力飞），助力飞标准产品共有五大板块，分别是：电子商城板块，运营协同板块，供应链协同板块，生产制造板块，项目管理板块和决策支持板块。同时助力飞支持深度二次开发，以满足用户的个性化需求。'},
        {con: '助力飞是以供应链管理和项目管理为内核扩展而成的ERP/MIS产品，特别适用于以下三类企业：（一）从事包括电商渠道在内的具有批发/零售业务的企业；（二）为大型企业提供全方面综合服务的企业；（三）以项目开发为主业的企业；'},
        {tit: '助力飞产品所能够解决企业发展中的主要问题有：', class: 'title'},
        {tit: '（一）面向大型集团性企业：'},
        {con: 'a)主要需求/问题：需要构建内部企业采购商城或员工商城，管理内外部的供应链流程，需要与之配套的一套架构稳固，性能稳定，流程可定制化，能够与后台ERP或其他电商平台快速对接的电子商务平台；'},
        {con: 'b)解决方案：助力飞标准产品的五大板块中，即包含了电子商城版本，该电子商城的样式和功能点与当前主流商城的功能保持相似，技术上采用微服务架构，具有良好的与其他系统对接的能力，而且可以作为一个单独的模块进行独立部署，或合并助力飞其他模块后再单独部署，且支持深度二次开发，能够高效满足企业的电商类型的各种类型的业务需求；'},
        {tit: '（二）面向中小型企业：'},
        {con: 'a)主要需求/问题：其一：原有信息化系统已经不能满足业务的发展需要；其二：企业在快速发展期，或者说从20个人左右的团队向更大团队发展的企业，在人，财，物，决策体系等管理方面遇到的瓶颈问题。主要问题是：助力飞能够解决小微企业的发展的瓶颈问题。'},
        {con: 'b)解决方案：小微企业在初创时，在购销或技术诸端，往往只要有一个方面做得很强，就能生存，但是伴随企业的发展，人员和规模的扩大，成本支出的增加，我们建立的中小企业发展的模型研究表示，当团队达到20人时，如果还仅仅依靠初创时的那个火车头，其他环节没有跟上，就非常容易造成公司管理的脱节，导致内耗大增，大概率将使公司的发展效率降低，更有甚者将导致公司团队分崩离析。究其原因，团队和公司的管理能力的培养是有成本的，常言道管过就是管过，没管过就是没管过，常言还道：队伍大了，不好带了，常言又道：企业像行进中的列车，列车的速度取决于火车头的速度和后面车厢和链条的质量，如果火车头速度太快，链条和车厢质量不好，反而容易脱轨。因此这种因发展而导致衰退的情况在第一次当老板的初创企业中发生的概率较大。助力飞总结了很多企业运营的经验，擅长管理跨多部门的复杂流程的管理，从人的分工，财的准确，物的位置以及决策过程痕迹着手，将决策机制引入该系统中，使得信息系统不再仅仅是记录结果的工具，而是让信息系统成为企业发展的列车中最坚强的锁链，能让整辆列车牵一发而动全身，在打掉流程中所有的惰性后让问题科学和准确的暴露出来，减少扯皮，科学反映员工的工作饱和度和员工绩效，真正站在发展的角度为公司排忧解难，花小钱办大事。这就是助力飞一体化系统的威力所在。'},
      ],
    },
    business: {
      title: 'BUSINESS MODEL',
      tit: '助力飞商业模式',
      url: require('../assets/images/aboutus/正文配图.png'),
      content: [
        {con: '助力飞现代智慧供应链，是一款基于SaaS架构的一体化信息系统，通过独创的商业模式，旨在实现企业版“拼多多”和基于用户共享库存的分布式电子商城。'},
        {con: '助力飞的商业模式是为中小企业提供低成本的一体化信息系统，在大幅提升企业管理效率的同时，标准化中小企业的库存管理和订单履约流程。经标准化后的商品将作为共享库存自动在商城中上架，且优先提供给其他助力飞用户，实现共享库存的电子商城。同时，助力飞构建厂家/总代直供为基础的供应链赋能平台，其特点是产品供给方具有唯一性，形成拼单集量采购效应，降低需方采购成本，而且在需方仅有采购初步意向时，即提供厂家级别的服务，帮助需方更好的达成业务。不仅如此，助力飞5G版本包含了自建商城的板块，能够为需要建立采购商城的企业提供自建商城的产品和服务，助力飞将已标准化的共享库存打包嵌入自建采购商城用户，丰富商城的商品库，与需方实现双赢。另外，助力飞还积极探索与其他主流平台商进行对接，分享助力飞的共享库存，帮助助力飞用户获取新的销售机会和业务流量。总而言之，助力飞对于中小微企业用户来说，不仅能够提高自身信息化管理水平，还能够为用户带来新的销售订单。'},
        {tit: '我们需要实现目标的路径是：', class: 'title'},
        {con: '（一）需要有尽可能多的助力飞的使用用户；'},
        {con: '（二）需要有越来越多的原厂/总代级别供应商资源入驻助力飞平台；'},
        {tit: '我们执行的具体方案有：', class: 'title'},
        {con: '（一）面向中小企业，提供助力飞SaaS版本，降低企业使用软件的成本；'},
        {con: '（二）与各级政府开立的经济开发区联系，配合园区改善营商环境的举措，在园区企业中进行助力飞的推广，给予推广对象一定免费的使用周期；'},
        {con: '（三）对接原厂和原厂总代的供应渠道，只有原厂的渠道才不会让商品的供应渠道打架，同时可以帮助原厂渠道建立有信任度的标准价格体系，从而也能反向提升平台的价值；'},
        {con: '我们需要的合作伙伴或个人是：'},
        {con: '助力飞的目标是构建一个多赢的体系，无论您是企业还是个人，都欢迎您加入到我们的体系中，我们需要的团队需要包含以下几点其中之一即可：'},
        {con: '（一）具有原厂或原厂代理商资源的企业或个人，助力飞有分享机制，会给您提供长效的盈利机制；'},
        {con: '（二）具有平台软件销售经验和渠道的企业或个人，助力飞系统本身具有相当的市场价值，且可以把助力飞非软件部分的收入分享给软件销售的团队和个人，从而给您提供长效的盈利机制；'},
        {con: '（三）具有互联网行业的经验，认同助力飞价值体系，并愿意为之努力达成目标的个人或企业，欢迎您加入我们；'},
        {tit: '一体化服务的相对优势：', class: 'title'},
        {con: '纵观企业信息化服务的历史，千禧年之前的企业信息化服务颇具争议，其整体定位更像是一个奢侈品，究其原因主要是信息化产品积累较少，开发技术和资源较少，技术壁垒高，致使各方面的成本都非常高，从而导致了一般只有大型且盈利能力强的企业才有能力去购买信息化服务或者自建研发队伍进行系统研发，而这种服务都是定制化程度较高的产品，目标就是实现公司治理的信息化管理，提高公司运营的效率、杜绝管理漏洞、规避隐患。一般来说，定制化的信息化系统都是一体化信息系统，实现全流程的管理，除了成本高以外没啥缺点。'},
      ],
    },
    information: {
      title: 'UNIVERSAL INFORMATION',
      tit: '未来将重新属于一体化的信息服务',
      content: [
        {con: '计算机行业虽然诞生不足百年，但是已经实实在在改变了整个人类的生活。对于企业的发展来说，计算机技术不仅带来了技术上的革命，而且在经营、运营方面也完全实现了革命性的升级。'},
        {con: '早在20世纪60年代，就有学者提出了“企业信息化”这一概念，是指企业以业务流程的优化和重构为基础，在一定的深度和广度上利用计算机技术、网络技术和数据库技术，控制和集成化管理企业生产经营活动中的各种信息，实现企业内外部信息的共享和有效利用，以提高企业的经济效益和市场竞争力，这将涉及对企业管理理念的创新，管理流程的优化，管理团队的重组和管理手段的创新。其实质是将企业的生产过程、物料移动、事务处理、现金流动、客户交互等业务过程数字化，通过各种信息系统网络加工生成新的信息资源，提供给各层次的人们洞悉、观察各类动态业务中的一切信息，以作出有利于生产要素组合优化的决策，使企业资源合理配置，以使企业能适应瞬息万变的市场经济竞争环境，求得最大的经济效益。'},
        {con: '通俗一点来类比，企业的生存和发展如果像一辆列车，一节一节的车厢像是企业中的各个重要环节或部门，那么信息化系统就像车厢内部的座位和车厢之间的链条，每个位置能够确认每个人的身份，坐在哪里，需要做什么事情；而车厢间的链条更像则把公司的部门紧密的串联在一起，跟着火车头快速前进。那么，什么样的管理信息系统是好的系统，那要慢慢说来，首先我们需要从管理信息系统的发展的历史说起：'},
        {con: '以下为按照10年为一个区间的发展史（一家之言，仅供参考）'},

      ],
      table: [
        {stage: '筑基期', trait: '    集中批处理。计算机的普及率很低,设备功能简单且运行效率很低。在软件上没有操作系统,应用软件是个空白。数据无法共享,对数据采用文件式的管理,没有现在意义上的数据库系统。\n    开发语言以机器语言和汇编语言为主。\n    出现第一个高级语言Fortran。\n    出现了ALGOL语言，对后续高级语言具有巨大的影响。或者说为高级语言的发展奠基也不为过。', cycle: '20世纪40年代中期至50年代末期', case: '    1954年美国通用电气公司安装的第一台商业用数据处理计算机,开创了信息系统应用于企业管理的先河。此阶段管理信息系统以商业企业中的单项事务子系统为主,主要利用电子计算机代替局部数据量大、操作方法简单的业务处理,如工资核算、物料管理等。其目的主要是单纯用计算机代替人的重复性劳动,减轻工作强度,提高工作效率,这也是管理信息系统的萌芽时期。'},
        {stage: '金丹期', trait: '    硬件方面有了很大的发展,出现了大容量的磁盘。数据以文件方式储存在磁盘上，实现了初步的数据共享。 在软件方面也出现了操作系统。管理信息系统因此出现了最基本的业务处理逻辑，但是受限于性能，一般只能处理当前的事务性的事件。\n    Basic语言作为Fortran的简化版出现，降低开发难度。', cycle: '20世纪60年代', case: '    软件应用出现雏形，但是开发成本极高，主要以服务于军工复合体为主。软件更像是硬件的伴生品，并不能独立出来应用。'},
        {stage: '元婴期', trait: '    计算机在性能上的提高和价格上的进一步降低为计算机的广泛使用铺平了道路。分布式系统技术的出现使操作系统更加完善,数据库、各类应用软件也逐渐兴起。同时,在这个阶段也开始运用系统的理论和方法进行管理信息系统的开发。管理信息系统从以处理事务为主开始转向以管理控制为主。\n    Basic，C语言逐步出现并占据主流。\n第一个结构化语言Pascal出现，同时经典的C语言出现，其语法规则成为后世很多主流语言的基础。\n值得一提的是Smalltalk语言出现，他是第一个真正具有IDE开发环境的语言出现，具有开创性的意义。', cycle: '20世纪70年', case: '    IBM最早实现了软件和硬件的剥离，使得以软件服务为主体的单独公司得以存在。MRP产品开始出现，计算机辅助设计（CAD）软件开始出现。'},
        {stage: '化神期', trait: '    计算机硬件的性价比进一步提高，划时代的具有互联，高速，智能化方向发展的计算机网络问世，管理信息系统的应用和开发飞速发展。\n    这一阶段诞生了C++语言，便于开发更加复杂的高级应用。MATLAB随后诞生，至今仍是工业设计软件的中流砥柱。数据库则出现了SQL语言。最早服务于网络系统开发的Per语言也随后面世。这一阶段真可谓百花争放。', cycle: '20世纪80年代', case: '    企业开始实现以产品数据管理(PDM)、计算机集成制造技术(CIM)、企业资源计划(ERP)等为代表的企业范围内的应用集成,从研发设计信息化、生产过程信息化及企业经营管理信息化三个维度有力推进了企业信息化发展。 企业资源计划扩展了制造资源计划系统的应用范围,不仅包含了其基本功能,还包含了如客户关系管理、售后服务、项目管理、集成化的过程管理等功能,是针对物资资源管理(物流)、人力资源管理(人流)、财务资源管理(资金流)、信息资源管理(信息流)的集成一体化的企业管理软件。'},
        {stage: '渡劫期', trait: '    伴随着网络应用的需求进一步扩大，技术上的升级进一步加快。这一段时间诞生了Python语言，Visual Basic语言，PHP语言，Sun公司改造C++成为Java语言，JavaScript语言。除了VB以外，剩下的几种语言都仍是目前网络应用开发的主流语言。', cycle: '20世纪90年代', case: '    以SAP为代表的ERP系统100%的覆盖了最优秀的企业。此时的ERP通常都是以MRP为内核发展起来的，各种ERP系统如雨后春笋一般拔地而起，其通用特点之一就是非常的昂贵，但是由于当时ERP的产品和业务咨询顾问都不是很成熟，因此ERP上线后的效果也褒贬不一。这一阶段的产品应该说都是一体化，定制化的产品，正是这一阶段的多样性，才给下一阶段软件的产品定型打下坚实的基础。\n    EDI业务模型出现，为后续的网络化、供应链延伸管理的信息系统打下坚实的基础。'},
        {stage: '软件应用开始大规模普及', trait: '    微软C#面试，随后VB.NET等Visual Studio系列产品随即同步问世。以C/S架构的产品的到蓬勃的发展', cycle: '21世纪00年代', case: '    经过数十年的积累，这一阶段真的是软件产品，研发风格，研发人员软件项目百花齐放的年代。\n    从管理信息系统过来说，出现比较科学的产品+业务顾问+二次开发模式。这种方式的好处是大大降低了成本。其主流的思想还是一体化的管理系统，可以服务于最优秀的大型企业之外的优秀的中小企业。\n    同时，此时的研发人员成本并不高，中小微企业都有余力为自己定制信息化系统，因此在全社会培养了大量的研发方面的全才，这一阶段也是全行业脑洞大开的时刻，各种奇思怪想的产品层出不穷，真可谓百家争鸣，为后续的互联网的繁荣奠定了最坚实的人力资源基础和业务原型基础。'},
        {stage: '软件行业日趋成熟，行业发展的优势和劣势同样显著', trait: '    基于Web的应用组件成为主流，因此基于JAVA和IOS为基础衍生出的各类应用和架构，包括日渐成熟的SaaS化架构和微服务架构等。', cycle: '21世纪10年代及以后', case: '    互联网的大厂崛起，形成参天大树，而且将分工做的非常细致，如：做前台的就坚决不碰后台，反之亦然这种情况在数年前简直匪夷所思的，但是现在已然成为整个行业的标准，其结果而言是极大的抬高了全社会的研发成本，导致小型的软件公司很难生存，而且中小企业自己组建开发团队开发业务系统的场景已几无可能。\n    此时to C的服务迅速崛起，创造了巨大的市场价值，但是其实to C的服务相对简单，因此这一阶段的软件产品都朝着专业化发展，只解决一个主要问题，使得整个行业像多了很多个山峰，每个山峰都是某个细分领域的龙头，但是如果你要从A到B的话，用户可能就得爬n个山峰，最早互联网的互联互通仿佛成为一个笑话了，而是成为一个又一个护城河。这种风格也影响到企业级别的信息化服务，大家都去做专了，只管好自己这一部分，而没有人去考虑全局性，对于中小企业来说，要么就只能用n个系统，要么就只能半手工的完成业务流程。\n    另外一体化系统的日渐式微还有一个重要原因是，之前的一体化信息系统主要服务于大企业，经过20年的发展，大企业的一体化系统上线率已接近饱和，后续有能力购买一体化系统服务的成长起来的企业相比于原来的大企业的基数来说，还是略显单薄。\n    那么一体化信息系统又该何去何从呢?'},
        {stage: '', trait: '    破局人何在？', cycle: '', case: ''},
      ],
      bottomContent: [
        {tit: '中小企业的管理信息系统模型'},
        {con: '对于广大的中小企业来说，需要什么样的信息系统，我们认为应该秉承一个最基本的原则：管理信息系统是设计者管理水平的体现，企业应当根据自身的管理水平选择匹配的管理信息系统，以达到最佳的管理效果。'},
        {con: '对于广大的中小企业来说，什么时候需要管理信息系统，我们认为应该秉承一个最基本的原则：当企业业务扩张且有良好的盈利时，或人员及部门快速扩张时，应当开始选择管理信息系统。'},
        {con: '企业管理究竟是切成各个模块各管各的，还是应该视作一个整体进行管理？'},
        {con: '这其实要站在不同的角度来看待这个问题，如果从部门经理，或某个主管来说，那么只扫门前雪，是非常合理的事情，一套针对部门的专业管理软件，应该就可以。'},
        {con: '如果站在公司全局来看，如果采购只管采购，销售只管销售，仓库只管仓库，生产只管生产，财务只管财务，换言之，采购人员只讲究内部合规和价格优势，但是并不理会生产材料要求或销售交货的时效性或者现有库存多少以及仓库是否会爆仓；销售人员的客户资料，销售机会，订单情况只在个人或者小团队中；仓库发货只按照自己的正常流程来，客户急不急，有没有突发情况关我屁事；而更有甚者，财务要搞点小事情，足以让整个公司瘫痪。笔者认为，每个公司或多或少都会存在内耗的情况，但是绝大部分公司的负责人或老板都不希望见到这种情况愈演愈烈。我们认为，公司应该是一个整体，只有整体向前公司才更健康，如果有一部分拖后腿了，应该想办法去帮助和提升；如果有一部分发展速度提速，应当借机提升公司其他部门的生产效率，将公司的运营整体提升到更高的水平。'},
        {tit: '那这个时候应该怎么办？'},
        {con: '解决公司内部管理的问题一般来说有两个流派，其一：以德服人派；其二：流程建制派。'},
        {con: '顾名思义，以德服人派更加强调的是统一思想，激发员工的工作热情，原动力，大局观，辅以一定的流程和制度进行管理。其好处是一个好的员工被激发起来，让他的目标，价值观，管理水平，业务能力都能够高度贴合公司的需求，那么不管这个员工是高层、中层还是底层，都能实现该员工治下的最优管理，扁平化的管理一旦提高正确性以后，会促进公司运营的效率大幅提升，是为“上策”。然而，这种方法的核心取决于“人”，而人作为不确定的因素，却是最难塑造成公司所需要的样子，人搞不定的话，其他事情就如同镜花水月，最终只能自己骗自己。'},
        {con: '流程建制派：强调的是建章立制，通过全公司的视角订立各项制度，包括部门内的和部门之间的，更完备一点的可以订立绩效体系，与制度进行联动。其好处是这种方法可执行性和可复制性比较强。缺点是如果管理的经验不足，容易制定出有漏洞的制度，反而会让不怀好意的人钻了空子，另外及时制度始终是字面上东西，而且真正在执行时的监管也是个大问题。'},
        {con: '到底哪一种管理流派是好的选择？'},
        {con: '我们非常俗套的认为，应该把两种通用流派相结合，治标又治本。但是千金易得一将难求，人的培养真不是一朝一夕的事情，如果公司面临危机或者迎来快速成长期的时候，我们建议应该使用流程建制的方式来快速的控制问题和支撑起公司的发展。那么我们又如何去解决流程建制派所具有的局限性呢？最佳的答案就是使用一体化的管理信息系统。'},
        {tit: '一体化信息系统的特点是什么？'},
        {con: '信息系统的核心就是把公司的制度刚性化，实现事务性决策的高效且正确的执行。短期来看，整个工作流程有记录，避免扯皮的发生，降低内耗。长期来看，收集了大量企业运行时的数据和经历，通过对长期数据的分析，有利于帮助企业主做更加合理的经营决策和管理调整。'},
        {con: '说的更通俗一点，一体化系统能够告诉企业，员工的工作量饱和度如何？员工的工作正确率如何？在哪一个流程上卡的比较长？公司的盈利情况如何？公司的实时库存情况？等等。'},
        {con: '一体化系统就是要打破部门之间各自为政的情况，从全局上反馈公司运营的情况，通过链条把业务流程全覆盖，正确且快速的发现公司发展的短板，将公司有限的资源投入到最需要改善的地方上。'},
        {tit: '选择一体化信息系统的难点是什么？'},
        {con: '全定制化的流程价格昂贵，并不是一般的中小企业能够承受的。比如说车和包，都有普通的和奢侈品级别的，但是不论好坏，他们的基本功能都是一样的。但是作为使用的人来说，需要根据自己的预算来选择合适的产品。因此选择管理信息系统时也应当遵从这个原则。这样能够解决一体化信息系统的第一个大难题，就是贵的问题。'},
        {con: '一体化系统根据产品设计的初衷，一般都默认用以解决某一行业或不同业态的管理问题，如：电商行业，物流行业，餐饮行业，或供应链领域，销售管理领域，生产制造领域等。其实并不存在一套包打天下的系统，因此企业给自己选择适合的一体化信息系统确实是一个关键点。'},
        {tit: '如何降低一体化信息系统的使用成本？'},
        {con: '软件产品主要是由代码组成，开发的过程成本相当的高昂，但是开发完毕以后，其版本的边际成本将趋近于无，虽然实施过程中需要一定的人工成本，但是这部分成本相对可控。因此，企业一定要明确自己要解决什么问题，然后再有针对性的选择一体化信息系统，尽量使用系统的标准产品的功能，不要进行二次开发的工作。这样就能极大的减少产品的使用成本。'},
        {con: '如果企业管理的流程与一体化系统的管理流程不相符，企业一定也要反思一下，自己之前的管理流程和方式是否合理，或者说是否是最佳的方法？一般来说，一个成熟的信息化系统，都是经过反复调研和总结归纳而出的，都是具有一定的代表性和合理性的，虽然说不一定完全贴合企业的实际管理需求，但是建议企业要认真的研究，并不是说要削足适履，而是要根据系统的标准流程做参照物，认真研究。根据产品的不同，再辅以一些线外的手段，使得流程闭环。另外，选择SaaS化的产品，不用进行IT及网络部署，可以按年付费，成本可以显著降低。总而言之，选择最适合自己的SaaS化的一体化产品，且尽量不做二次开发要求，能够选择性价比很高的一体化信息系统。'},
      ]
    },
    company: {
      title: 'Reglory News',
      con: '旋荣新闻',
      content: [
        { id : 1, link: '/newDetails', url : require('../assets/images/news/company1.png'), title : '公司监测系统升级', content : '近期，公司技术人员为上海特高压变电站升级SF6（六氟化硫）气体微水压力在线监测系统SF6气体以其优异的绝缘和灭弧性能，在电力系统中获得了广泛应用…', date : '2022-01-11'},
        { id : 2, link: '/newDetails', url : require('../assets/images/news/company2.png'), title : '第三代助力飞', content : '近期，旋荣科技发布第三代“助力飞”现代智慧供应链SaaS平台，这标志着“助力飞”的功能又登上一个新的台阶。新版“助力飞”是为服务商/电商企业的内部业务管理…', date : '2021-07-09'},
        { id : 3, link: '/newDetails', url : require('../assets/images/news/company3.png'), title : '先进基层党组织', content : '近期，上海科技京城党委召开“两优一先”表彰大会，授予上海旋荣科技股份有限公司党支部“先进基层党支部”荣誉称号，同时，授予公司党支部书记吴晓春先生…', date : '2021-06-10'},
        { id : 4, link: '/newDetails', url : require('../assets/images/news/company4.png'), title : '子公司荣获高新证书', content : '近期，旋荣科技的全资子公司上海旋胜科技有限公司取得《高新技术企业证书》，有效期三年，公司将在税收、人才引进等方面享受各项优惠政策…', date : '2022-02-22'},
        { id : 5, link: '/newDetails', url : require('../assets/images/news/company5.png'), title : '视觉AI精确识别项目全面展开', content : '2022年5月，公司的视觉AI精确识别项目全面展开，利用AI图像增强网络、多尺度检测模型等先进技术，有效提高客户的物资管理效率。经过我司多年的积累和与中科院等知名高校…', date : '2022-05-08'},
        { id : 6, link: '/newDetails', url : require('../assets/images/news/company6.png'), title : '助力飞“项目管理”', content : '2022年6月，“助力飞”现代智慧供应链SaaS平台的“项目管理”模块上线，本模块可以清晰地展现各个项目的进展情况，便于各级管理层对项目进行实时跟踪，并且…', date : '2022-06-08'},
        { id : 7, link: '/newDetails', url : require('../assets/images/news/company7.png'), title : '加入电力行业协会', content : '2022年8月，公司加入上海市电力工程行业协会，本协会由上海市辖区内从事电力工程建设相关业务的企事业单位及其他组织自愿结成的行业联合组织…', date : '2022-08-09'},
      ]
    },
    industry: {
      title: 'Reglory News',
      con: '行业新闻',
      content: [
        { id : 11, link: '/newDetails', url : require('../assets/images/news/1.png'), title : '特高压的机遇', content : '在现代电网整体业务布局下，特高压柔性直流输电技术将有助于巩固电网直流建设、运维主体地位，推动实现直流资源整合，建立协同高效的直流建设、运维体系…', date : '2021-12-27'},
        { id : 12, link: '/newDetails', url : require('../assets/images/news/2.png'), title : '特高压“两直一交”', content : '随着年末1000千伏长沙-南昌（潇湘-赣江）特高压交流工程正式投运，2021年华中电网特高压“两直一交”工程建设任务圆满完成。2021年，华中电网特高压建设全面提速…', date : '2021-12-29'},
        { id : 13, link: '/newDetails', url : require('../assets/images/news/3.png'), title : '特高压交流工程', content : '12月26日，国家电网南昌—长沙1000千伏特高压交流工程竣工投产，将有效缓解电力供需矛盾，更好地服务湖南、江西两省经济腾飞，助力中部地区崛起和高质量发展…', date : '2022-01-06'},
        { id : 14, link: '/newDetails', url : require('../assets/images/news/4.png'), title : '5G+特高压', content : '在11月中旬举行的2021世界制造业大会上，发布了今年安徽省“5G+工业互联网”十大创新应用，“5G+特高压密集通道及配电智能运检”是其中之一…', date : '2021-12-03'},
        { id : 15, link: '/newDetails', url : require('../assets/images/news/5.png'), title : '特高压能源输送', content : '2021年是实施国企改革三年行动的攻坚之年、关键之年。平高集团有限公司控股上市公司——河南平高电气股份有限公司（以下简称“平高电气”）始终坚持…', date : '2021-12-14'},
        { id : 16, link: '/newDetails', url : require('../assets/images/news/6.png'), title : '特高压线路开工', content : '十四五”期间，国网规划建设特高压工程“24交14直”，涉及线路3万余公里，变电换流容量3.4亿千伏安，总投资3800亿元。今年，国网计划开工“10交3直”共13条…', date : '2022-01-12'},
        { id : 17, link: '/newDetails', url : require('../assets/images/news/7.png'), title : '特高压的发展', content : '特高压是指电压等级在交流1000千伏及以上和直流±800千伏及以上的输电技术，具有输送容量大、距离远、效率高和损耗低等技术优势。2004年以来，国家电网公司…', date : '2022-01-09'},
        { id : 18, link: '/newDetails', url : require('../assets/images/news/8.png'), title : '理解能源互联网', content : '能源是人类文明进步的重要物质基础和动力，攸关国计民生和国家安全。在绿色低碳的发展理念下，一次能源能量密度持续上升的路径被打破，能源行业进入转型发展新时期…', date : '2022-01-05'},
        { id : 19, link: '/newDetails', url : require('../assets/images/news/9.png'), title : '超高压的应用', content : '标准大气条件下空气放电电压大概在30kV/cm，即330kV的理论放电距离为11cm，考虑到实际条件（天气状况、地理位置等）实际放电距离可能远小于这个值。由此可见…', date : '2022-01-08'},
        { id : 20, link: '/newDetails', url : require('../assets/images/news/10.png'), title : '电网建设再加速', content : '1月中旬召开国家电网2022年度工作会议透露，2022年，在电网投资方面，国家电网计划投资金额为5012亿元，首次突破5000亿元，创历史新高。在国家发改委近日召开的…', date : '2022-02-09'},
        { id : 21, link: '/newDetails', url : require('../assets/images/news/11.png'), title : '电力系统转型的机遇', content : '2020年9月，国家主席习近平在第七十五届联合国大会一般性辩论上郑重提出碳达峰、碳中和目标。“双碳”目标是党中央作出的重大决策，关系着中华民族永续发展…', date : '2021-05-18'},
        { id : 22, link: '/newDetails', url : require('../assets/images/news/12.png'), title : '以新能源为主体', content : '近日，央财经委员会第九次会议召开，会上指出，“十四五”是碳达峰的关键期、窗口期需石窟关注其发展状况。同时南方电网公司发布服务碳达峰、碳中和工作方案…', date : '2021-04-01'},
        { id : 23, link: '/newDetails', url : require('../assets/images/news/13.png'), title : '新型电力系统', content : '深化电力体制改革，构建以新能源为主体的新型电力系统，是党中央基于保障国家能源安全、实现可持续发展、推动碳达峰、碳中和目标实施作出的重大决策部署，为新时期能源行业…', date : '2021-12-21'},
      ]
    },
    companyNew1: {title: '公司监测系统升级',source: '',author: '',date: '2022年01月11日',content:[
      {con: '近期，公司技术人员为上海特高压变电站升级SF6（六氟化硫）气体微水压力在线监测系统。'},
      {con: 'SF6气体以其优异的绝缘和灭弧性能，在电力系统中获得了广泛应用，成为中特高压、超高压开关中普遍使用的绝缘和灭弧介质。如果在开关运行过程中发生SF6气体泄漏或水分超标时，对运行设备的绝缘性能会造成较大的影响，程度严重的会导致绝缘击穿，造成重大事故，通过建立SF6微水压力在线监测系统，可实时监测SF6气体运行状态，及时发现SF6气体缺陷，并对气体运行趋势作出智能判断，从而有效避免SF6气体问题威胁到电网运行安全，极大增强设备及电网运行安全可靠性。'},
      {url: require('../assets/images/companyNew/1.png')},
    ],},
    companyNew2: {title: '第三代助力飞',source: '',author: '',date: '2021年07月09日',content:[
      {con: '近期，旋荣科技发布第三代“助力飞”现代智慧供应链SaaS平台，这标志着“助力飞”的功能又登上一个新的台阶。'},
      {tit: '（一）功能模块'},
      {con: '新版“助力飞”是为服务商/电商企业的内部业务管理量身定做，能为使用系统的用户带来订单的业务系统。助力飞有比价平台PP，厂家直供平台MDSP、库存实时共享子系统RISS、电商实时价子系统REPS、供应商履约平台VPP、商品管理平台CMP、客户关系管理子系统CRM、供应商关系管理子系统SRM、财务对账子系统、报表系统BW、驾驶舱系统、移动端。新版“助力飞”包含14个子系统，可以根据实际需要进行配置。'},
      {url: require('../assets/images/companyNew/2.png')},
      {tit: '（二）业务流程'},
      {con: '新版“助力飞”可以满足各类复杂业务流程的处理要求，可以对流程进行自定义设置。'},
      {url: require('../assets/images/companyNew/3.png')},
      {tit: '（三）决策支持'},
      {con: '新版“助力飞”把OA、WMS、TMS、CRM、VP等系统充分结合起来，让管理系统不再仅仅是一个事后记账的工具，而是把决策的过程和审批的流程都融合进系统中，直线提升工作效率，降本增效。'},
      {url: require('../assets/images/companyNew/4.png')},
      {con: '旋荣科技在多年物资供应经验的基础上，自主研发了具有完全知识产权的“助力飞”现代智慧供应链SaaS平台，提高制造商、代理商、集货商、零售商在流通领域的内、外部流转效率，降低全社会库存，为用户不断赋能，降本增效。同时，公司也紧跟时代脉搏，积极为各类企业提供综合物资供应服务，在实践中促进助力飞SaaS平台的不断完善和优化升级。'},
    ],},
    companyNew3: {title: '先进基层党组织',source: '',author: '',date: '2021年06月10日',content:[
      {con: '近期，上海科技京城党委召开“两优一先”表彰大会，授予上海旋荣科技股份有限公司党支部“先进基层党支部”荣誉称号，同时，授予公司党支部书记吴晓春先生“优秀党务工作者”荣誉称号，授予公司董事长赵潇玮先生、董事王萍女士“优秀党员”荣誉称号。'},
      {url: require('../assets/images/companyNew/5.png')},
      {con: '公司党支部在上级党委的领导下，积极开展各项党建工作，积极参与各项公益活动，得到了上级党委的高度认可。'},
      {url: require('../assets/images/companyNew/6.png')},
      {con: '今后，公司党支部将坚定不移树牢党旗指引方向，团结带领全体党员干部职工，坚定信心、顽强拼搏、锐意进取，在传统化工行业转型发展上蹚出一条新路，为全方位推进高质量发展、奋力谱写全面建设社会主义现代化国家而努力奋斗。'},
    ],},
    companyNew4: {title: '全资子公司取得《高新技术企业证书》',source: '',author: '',date: '2022年02月22日',content:[
      {con: '近期，旋荣科技的全资子公司上海旋胜科技有限公司取得《高新技术企业证书》，有效期三年，公司将在税收、人才引进等方面享受各项优惠政策。'},
      {url: require('../assets/images/companyNew/7.png')},
      {con: '上海旋胜科技有限公司成立于2018年，主要从事软件开发、信息技术服务、技术咨询等业务。'},
    ],},
    companyNew5: {title: '视觉AI精确识别项目全面展开',source: '',author: '',date: '2022年05月',content:[
      {con: '2022年5月，公司的视觉AI精确识别项目全面展开，利用AI图像增强网络、多尺度检测模型等先进技术，有效提高客户的物资管理效率。经过我司多年的积累和与中科院等知名高校的联合研发，已经形成了一套可商用的，基于视觉AI精确识别的核心技术，区别于目前市面上大部分的视觉识别技术，精确识别这项具有跨越性价值的技术，将改变未来的整个视觉识别行业。'},
      {url: require('../assets/images/companyNew/8.png')},
    ],},
    companyNew6: {title: '助力飞现代智慧供应链SaaS平台的“项目管理”模块上线',source: '',author: '',date: '2022年05月',content:[
      {con: '2022年6月，“助力飞”现代智慧供应链SaaS平台的“项目管理”模块上线，本模块可以清晰地展现各个项目的进展情况，便于各级管理层对项目进行实时跟踪，并且可以根据项目实际情况进行订制，项目管理驾驶舱示例如下：'},
      {url: require('../assets/images/companyNew/9.png')},
    ],},
    companyNew7: {title: '公司加入上海市电力工程行业协会',source: '',author: '',date: '2022年08月',content:[
      {con: '2022年8月，公司加入上海市电力工程行业协会，本协会由上海市辖区内从事电力工程建设相关业务的企事业单位及其他组织自愿结成的行业联合组织，是经上海市民政局批准的非营利性社会团体法人。协会将在项目申报、市场拓展、产品研发等方面给公司大力支持。'},
      {url: require('../assets/images/companyNew/10.png')},
    ],},
    companyNew11: {title: '特高压的机遇',source: '北极星输配电网',author: '韦晓星',date: '2021年12月27日',content:[
      {con: '在现代电网整体业务布局下，特高压柔性直流输电技术将有助于巩固电网直流建设、运维主体地位，推动实现直流资源整合，建立起协同高效的直流建设、运维体系。在建设特高压直流工程的同时，直流建设管理、运行管理优势将向产业价值链延伸，整合上下游资源，在供给与需求、技术与市场整合中培育新动能、新业态。另外，基于主网架优化配置清洁能源资源的基础优势，以及跨省(区)电网技术密集等特点，通过特高压柔直电网构建与利益相关方的多层次、多样化科技创新与开放合作平台，实现业务和服务增值，提升能源生态整体价值。'},
      {url: require('../assets/images/new/1.png')},
      {con: '新能源具有随机性、波动性、间歇性特点，系统调节资源需求大，且新能源大规模并网后系统呈现高度电力电子化特征，也对直流输电技术带来了新的挑战。随着新能源的大规模开发，输电网中电力电子设备越来越多，导致大量谐波注入输电网，主网谐波谐振风险开始凸显。近年来，南方电网已发生多起谐波及谐振事件，严重影响主网架的安全稳定运行。2021年3月16日，南方电网公司印发了《南方电网公司加强国家战略科技力量建设的实施意见》，重点推进高比例新能源和高比例电力电子装备接入电网的稳定运行控制、超远距离跨区域大容量特高压直流输电等研究。同时，成立了交直流串并联复杂大电网规划与运行科研团队，开展新型电力系统电网分析与优化、电网安全稳定运行与控制技术研究，“双高”电力系统稳定性建模、分析与控制研究，新能源接入换流器的振荡规律与控制理论研究，特高压换流站近区谐波超标风险及交流系统网架特性变化预测研究等。'},
      {con: '面对构建新型电力系统带来的挑战，南方电网公司将整合优势资源，基于特高压柔性直流电网建设，加大关键技术的集中攻关、试验示范、推广应用，加快数字化和智能化转型，全力服务好“碳达峰、碳中和”目标，加快形成绿色生产方式，全面彰显主网架减碳枢纽和功能平台作用，提高新能源消纳能力，提升输电通道利用水平。'},
      {url: require('../assets/images/new/2.png')},
      {con: '绿色高效、柔性开放和数字赋能是新型电力系统的显著特征，电网消纳高比例新能源的核心枢纽作用将更加显著。白皮书提出建设“合理分区、柔性互联、安全可控、开放互济”的坚强可靠主网架，研究适应高比例新能源接入的同步电网规模，适时通过柔性直流互联技术构建2到4分区电网，提升系统的安全稳定水平和分区间电力交换能力。特高压柔性直流输电技术将促进大电网柔性互联，促进资源互济共享能力进一步提升，“跨省区主干电网+中小型区域电网+配网及微网”的柔性互联形态和数字化调控技术将使电网更加灵活可控，协同助力数字电网成为承载新型电力系统的最佳形态，促进南方区域主网架加快向安全、可靠、绿色、高效、智能的现代化大电网发展，并在优化能源资源配置、保障新能源充分消纳、推动构建多元能源供给体系等方面发挥更加重要的作用。'},
      {con: '近年来，全球输变电技术快速发展，超高海拔输电技术、柔性直流架空输电故障清除技术、新型直流接地技术逐步进入工程化应用阶段，碳纤维复合芯导线、无人机运维方式等应用于特高压输电工程，特高压直流换流阀投入应用，国内外漂浮式海上风电技术规模化应用加快，大规模复杂混联电网仿真技术取得突破。技术创新作为电网现代化的主要驱动力，大容量柔直、多端直流等先进直流输电技术得到推广应用，未来将持续推动电网物理基础设施升级，同步促进新一代数字信息技术与电网基础设施融合创新。全面感知的数字电网技术推动源网荷储各环节深度融合，展现出现代化电网的显著特征，有力推动传统电网向现代化电网形态衍变。'},

    ],},
    companyNew12: {title: '特高压“两直一交”',source: '北极星输配电网',author: '张慕婕 邵德军',date: '2021年12月29日',content:[
      {con: '随着年末1000千伏长沙-南昌（潇湘-赣江）特高压交流工程正式投运，2021年华中电网特高压“两直一交”工程建设任务圆满完成。2021年，华中电网特高压建设全面提速，±800千伏雅湖、陕武特高压直流和1000千伏南昌-长沙特高压交流工程集中投产，国网华中分部认真贯彻国家电网公司党组的决策部署，超前谋划、统筹协调、精心安排，克服了新冠疫情常态化、主网配合停电多、工程调试任务重、风险管控难度大等困难，保障了特高压“两直一交”工程顺利投产。'},
      {url: require('../assets/images/new/3.png')},
      {con: '±800千伏雅湖、陕武特高压直流是首个落点江西、湖北的特高压直流工程，雅湖直流于6月26日正式投运，工程投运后具备输送460万千瓦能力，有效缓解了度夏期间华中及江西电网供电紧张局面，同时缓解了四川电网丰水期弃水压力。陕武直流于12月21日完成高低端系统调试，具备输送300万千瓦能力，有效缓解了度夏度冬用电高峰时期华中及湖北电网供电紧张局面。1000千伏长沙-南昌特高压交流工程于12月24日正式投运，工程投运后改变了湖南、江西末端电网的格局，鄂湘赣三省形成“三角形”联络新格局，提升了电网稳定水平和受电能力，提高祁韶、雅湖特高压直流跨区送华中能力共190万千瓦，提升湖南、江西电网供电能力超过350万千瓦，对缓解今冬明春华中及湖南、江西电网供电紧张局面具有重要意义，同时也提高了西北新能源和西南水电清洁能源的消纳能力。'},
      {con: '”重大工程实施方案，充分调动各方力量和积极性，统筹停电协调、配套工程调试、直流投产调试、安控建设等工作。顺利完成了特高压雅湖直流、陕武直流、潇江线工程及配套500千伏7个变电站、28条线路、2台调相机等工程调试方案编制、工程投产调试等工作。全面完成了“两直一交”工程配套稳控系统建设及直流带电传动试验，为接纳区外电力、提升电网安全稳定水平奠定了坚实的基础。'},
      {url: require('../assets/images/new/4.png')},
      {con: '特高压“两直一交”工程投产后，提升了华中电网跨区受电能力950万千瓦，有效解决了鄂湘、鄂赣省间断面长期卡口问题，提升了华中电网省间互济能力，借助祁韶、雅湖直流通道，实现了西北风电、光伏和西南水电互济，实现了清洁能源在华中电网的统一消纳与跨区、跨省优化配置，缓解了大负荷期间华中部分省份“硬缺电”的困难局面，将为今冬明春华中区域电力可靠供应提供有力支撑。+中小型区域电网+配网及微网”的柔性互联形态和数字化调控技术将使电网更加灵活可控，协同助力数字电网成为承载新型电力系统的最佳形态，促进南方区域主网架加快向安全、可靠、绿色、高效、智能的现代化大电网发展，并在优化能源资源配置、保障新能源充分消纳、推动构建多元能源供给体系等方面发挥更加重要的作用。'},
    ],},
    companyNew13: {title: '特高压交流工程',source: '国家电网',author: '',date: '2022年1月6日',content:[
      {con: '12月26日，国家电网南昌—长沙1000千伏特高压交流工程竣工投产，将有效缓解电力供需矛盾，更好服务湖南、江西两省经济腾飞，助力中部地区崛起和高质量发展。'},
      {url: require('../assets/images/new/5.png')},
      {con: '江西将坚决扛起能源保供政治责任，加快推进高质量发展，努力使南昌—长沙工程成为促进经济转型创新发展的能源大动脉，成为促进内陆地区互济共赢的能源大动脉，成为促进人与自然和谐共生的能源大动脉。'},
      {con: '南昌—长沙工程是落实习近平总书记能源安全新战略的生动实践，对于湖南全面落实“三高四新”战略定位和使命任务意义重大。将以工程投产为契机，继续在能源转型升级、先进制造业发展、战略性新兴产业培育等方面与国家电网公司深化合作，实现互利共赢，共同推动我国能源电力事业蓬勃发展、高质量发展。'},
      {con: '始终把保障国家能源安全、满足经济社会发展用电需求作为首要任务和第一责任，针对湘赣两省持续加大投入，完善电网结构，增强外受电能力，不断提高供电保障水平。'},
      {con: '南昌—长沙工程从开工到建成仅用了10个月的时间，打造了高质量的精品工程，创造了了不起的建设纪录。'},
      {url: require('../assets/images/new/6.png')},
      {con: '南昌—长沙工程是华中特高压骨干网架的重要组成部分，工程起于江西省南昌市，止于湖南省长沙市，新建两座1000千伏变电站，新增1200万千伏安变电容量，线路长度2×341公里，总投资102亿元，将增加湖南、江西电网供电能力350万千瓦以上，提升系统安全稳定水平，为保障今冬明春湘赣两省电力安全可靠供应、确保人民群众温暖过冬。'},
    ],},
    companyNew14: {title: '5G+特高压',source: '新华社',author: '陈尚营 刘军喜',date: '2021年12月03日',content:[
      {con: '在11月中旬举行的2021世界制造业大会上，发布了今年安徽省“5G+工业互联网”十大创新应用，“5G+特高压密集通道及配电智能运检”是其中之一。'},
      {con: '爬上安徽省池州市贵池区墩上街道许桥村附近的一座小山，密集的输电铁塔、高压线从远处的群山中“穿行”而来，蔚为壮观。'},
      {url: require('../assets/images/new/7.png')},
      {con: '这是池州九华特高压密集通道，是西电东送的大动脉之一。通道断面额定输送负荷最大达3060万千瓦，与安徽省目前最大的用电负荷相当，每年向长三角地区输送清洁能源近714亿千瓦时，接近整个上海市全年用电量的一半。'},
      {con: '距离高大的特高压铁塔不远，有一座相对“袖珍”的5G无线基站。“这是专为密集通道建设的一座5G基站，今年9月底建成。”5G技术具有低延时、大带宽的特点，能为密集通道实施智慧管控提供保障。'},
      {con: '据介绍，九华特高压密集通道在山区，植被茂密、地理环境复杂，山火、异物、冰灾、地质灾害等风险较高，一旦发生了事故，将危及华东电网安全。原有通道巡检业务存在塔装摄像头数据传输慢、分辨率不高，山区环境单兵巡检不便，无人机自动化程度不高等问题。'},
      {con: '为解决这一难题，国网安徽省电力有限公司联合中国移动安徽公司，建立了承载密集通道智能运检业务的电力5G虚拟专网，实现特高压密集通道5G智慧巡检，借助5G网络能力建立5G无人机巡检、高精度定位、智能AI处理等运检应用系统。'},
      {con: '这个项目构建了全新的5G网和电力通信网‘双网融合’模式，提供按需建网和边缘计算能力，支撑密集通道全状态感知和全景监控功能，通信可靠率达到99.99%，网络带宽和延时等性能指标大幅提升，提升设备实时感知及诊断能力，保障能源通道安全。'},
      {url: require('../assets/images/new/8.png')},
      {con: '为减少特高压输电线路对自然环境的影响，综合考虑节约土地资源等多种因素，在路径选择时尽量避开风景区、自然保护区，由此形成多条线路汇集的密集通道现象，像这样的特高压密集通道在安徽省有6处、463公里。'},
    ],},
    companyNew15: {title: '特高压能源输送',source: '北极星输配电网',author: '',date: '2021年12月14日',content:[
      {con: '2021年是实施国企改革三年行动的攻坚之年、关键之年。截至目前，累计参建20条特高压直流工程，大量供货切滤波器断路器、阀侧断路器、旁路天关、隔离接地开关、穿墙套管、金属等设备，源源不断输送光明与幸福。'},
      {url: require('../assets/images/new/9.png')},
      {con: '上海庙－临沂±800千伏特高压直流输电工程是世界第二个±800千伏、输送容量1000万千瓦、受端分层接入500/1000千伏交流电网的特高压直流工程。该工程于2018年9月正式投运。其采用的1100千伏切滤波器组断路器突破了开断容性高频电流重击穿等重大技术难题，填补了特高压国际领域的空白，为华中地区大气污染防治工作和“打赢蓝天保卫战”作出了重要贡献。'},
      {con: '昌吉-古泉±1100千伏特高压直流工程是世界上电压等级最高、输送容量最大、输送距离最远、技术水平最先进的特高压输电工程。该工程于2019年9月正式投运。其首次应用平高独立自主研发的1100kV交流隔离开关，技术性能参数达到国际领先，同时触头具有自清洁功能。此外，该站所采用的±1100千伏气体绝缘直流穿墙套管，突破了环氧材料配方、载流导体结构和超长变径空心复合绝缘子等方面的技术难题，填补了国内空白，是世界首支通过定型试验的产品。该电站刷新了世界电网技术的新高度，开启了特高压输电技术发展的新纪元，对于全球能源互联网的发展具有重大的示范作用。'},
      {con: '青海-河南±800千伏特高压直流输电工程是世界上第一条专为清洁能源外送而建设的特高压通道工程。该工程于2020年12月投运。其采用的800千伏切滤波器组罐式断路器，攻克了开断容性电流重击穿概率极低的难题，并一次性、零击穿通过5轮C2级及FC附加试验共564次开断和600次关合高难度试验，在国内外尚属首家首次，创造了目前世界同类产品最好记录。该电站开辟出新能源资源优势向经济优势转化的新路径，是践行“绿水青山就是金山银山”发展理念、造福于民的有力体现。'},
      {con: '陕北-武汉±800千伏特高压直流输电工程是“西电东送”“北电南供”的能源大通道。该工程于2021年8月投运。其采用的±816kV直流穿墙套管机械寿命达10000次，属国内首台（套）设备，打破了国外厂家在该领域的垄断地位，解决了长期制约我国输变电行业发展的“卡脖子”难题。该站正式将陕北、陕西乃至西北的清洁能源源源不断地送到湖北及华中西区，构筑起陕电入鄂的“空中高速路”，推动陕北煤电基地、风电和光伏发电高质量发展，同时可缓解湖北迎峰度夏期间供电紧张局面，让武汉及周边地区用上环保绿色的清洁能源。'},
      {url: require('../assets/images/new/10.png')},
      {con: '老挝西格玛水电站至色贡二变电站输变电总承包工程是“一带一路”倡议下中老两国重点建设项目。平高电气供货的115千伏断路器、隔离开关等3个变电站设备于2020年3月成功并网运行。'},
      {con: '巴基斯坦默拉直流工程是中巴经济走廊“优先实施”项目清单下唯一的输变电项目。平高电气供货的ZGW6-680、GW16B-550等9种型号共计近300组交直流隔离开关、接地开关产品于2021年6月进入送电阶段。巴基斯坦默拉直流工程是巴基斯坦首个直流输电工程，也是巴基斯坦目前电压等级最高、输电线路最长的项目。'},
    ],},
    companyNew16: {title: '特高压线路开工',source: '中国能源报',author: '苏南',date: '2022年01月12日',content:[
      {con: '“十四五”期间，国网规划建设特高压工程“24交14直”，涉及线路3万余公里，变电换流容量3.4亿千伏安，总投资3800亿元。今年，国网计划开工“10交3直”共13条特高压线路。'},
      {con: '在碳达峰、碳中和背景下，加强网架建设，尤其是特高压建设，有效解决我国高比例可再生能源并网、跨省跨区大范围调配的难题。交直流特高压输电工程作为构建新型电力系统的重要措施，将成为“十四五”电网重点投资方向。'},
      {con: '实现长距离能源外送，作为电力“快递”的特高压直流扮演着重要角色。在西部地区、北部地区，新能源资源富集地区，要科学规划、布局一批以新能源为主的电源基地和电力输送通道，实现新能源电力全局优化配置。'},
      {url: require('../assets/images/new/11.png')},
      {con: '西北地区和西南地区清洁能源资源禀赋突出，将长期作为我国特高压的主要送端。构建新型电力系统需要解决清洁能源的消纳利用问题，特高压既能够支撑大范围资源优化配置，还将发挥跨区调节互济的作用，随着新能源渗透率逐渐提高，采用更加灵活优化的运行方式，挖掘不同区域新能源出力及负荷需求的时序互补性，实现跨区域调峰和备用。'},
      {con: '特高压和跨省跨区输电工程大规模启动后，新能源消纳问题有望得到缓解。以华中电网为例，±800千伏雅中-江西特高压直流、陕武特高压直流和1000千伏南昌-长沙特高压交流工程去年集中投产，尤其是±800千伏雅中-江西特高压直流工程投运后具备输送460万千瓦能力，有效缓解了度夏期间华中及江西电网供电紧张局面，同时缓解了四川电网丰水期弃水压力。1000千伏长沙-南昌特高压交流工程投运后改变了湖南、江西末端电网的格局，鄂湘赣三省形成“三角形”联络新格局。'},
      {con: '随着国内网架日趋完善，变电站之间电气距离逐渐缩短，最有效的连接方法是采用柔性直流技术进行区域电网背靠背互联，提高电网电力交换及事故支援能力，并缓解交直流互相影响、缩小交流故障影响范围。国家今年将大规模启动新一轮特高压建设，并且建设规模空前。'},
      {con: '“十四五”期间，国网规划建设特高压工程“24交14直”，涉及线路3万余公里，变电换流容量3.4亿千伏安，总投资3800亿元。今年，国网计划开工“10交3直”。'},
      {con: '“按照规划，建设甘孜—天府南—成都东、天府南—重庆的特高压交流工程，其中，四川新建甘孜、天府南、成都东特高压的变电站3座，重庆新建铜梁特高压变电站，新增变电容量2400万千伏安。”国网四川电力相关负责人透露，“争取今年上半年完成核准，并开工建设。”'},
      {url: require('../assets/images/new/12.png')},
      {con: '特高压不仅能有效支撑清洁能源大范围优化配置，助力碳达峰、碳中和目标实现，同时还可提高东中部地区电力供应保障能力。此外，特高压投资规模大、产业链长，建设特高压项目能够有效拉动上下游相关产业发展，促进经济增长。'},
      {con: '特高压产业链除了高压电气开关设备、新材料、换流阀、线缆、变压设备、机械等相关产业，还可依托5G推动物联网、芯片等高新技术发展。'},
    ],},
    companyNew17: {title: '特高压的发展',source: '',author: '',date: '2022年01月09日',content:[
      {con: '特高压是指电压等级在交流1000千伏及以上和直流±800千伏及以上的输电技术，具有输送容量大、距离远、效率高和损耗低等技术优势。2004年以来，国家电网公司联合各方力量，在特高压的理论、技术、标准、装备及工程建设、运行等方面取得全面创新突破，掌握了具有自主知识产权的特高压输电技术，并将特高压技术和设备输出国外，实现了“中国创造”和“中国引领”。'},
      {con: '截至目前，国家电网建成投运“十四交十二直”26项特高压工程，核准、在建“两交三直”5项特高压工程。运建31项特高压输电工程线路长度达到4.1万千米，变电（换流）容量超过4.5亿千伏安（千瓦）。特高压交流和直流分别荣获2012年度、2017年度国家科技进步特等奖。截至2020年，特高压累计输送电量超过2.1万亿千瓦时，电网资源配置能力不断提升，在保障电力供应、促进清洁能源发展、改善环境、提升电网安全水平等方面发挥了重要作用。'},
      {url: require('../assets/images/new/13.png')},
      {con: '目前中国已经建成的超高压是西北电网750千伏的交流实验工程。首个国内最高电压等级特高压交流示范工程，是我国自主研发、设计和建设的具有自主知识产权的1000千伏交流输变电工程--晋东南-南阳-荆门特高压交流试验示范工程，全长640公里，纵跨晋豫鄂三省，其中还包含黄河和汉江两个大跨越段。线路起自山西1000kV晋东南变电站，经河南1000kV南阳开关站，止于湖北1000kV荆门变电站。2008年12月30日22时，该工程投入试运行，2009年1月6日22时，顺利通过168小时试运行。'},
      {con: '直流方面，四川向家坝--上海±800千伏特高压直流输电示范工程已顺利投入运行，这是目前规划建设的世界上电压等级最高、输送距离最远、容量最大的直流输电工程;锦屏-苏南±800kV特高压直流线路工程也于2012年5月13日顺利通过竣工验收。'},
      {con: '国家电网公司在2010年8月12日首度公布，到2015年建成华北、华东、华中("三华")特高压电网，形成"三纵三横一环网"。'},
      {con: '同日，国家电网宣布世界上运行电压最高的1000千伏晋东南-南阳-荆门特高压交流试验示范工程已通过国家验收，这标志着特高压已不再是"试验"和"示范"阶段，后续工程的核准和建设进程有望加快。'},
      {con: '2015年7月24日，在江苏省东台市，1000千伏淮南-南京-上海线特高压交流工程开建。1000千伏淮南-南京-上海特高压交流工程是国家大气污染防治行动计划12条重点输电通道之一，变电容量1200万千伏安，线路全长759.4千米，新建输电线路2×780公里，工程投资268亿元。该工程是迄今规模最大、建设难度最大的特高压交流工程，建成后可增强长三角地区电网互联互通、相互支援的能力。'},
      {url: require('../assets/images/new/14.png')},
      {con: '特高压直流输电具备点对点、超远距离、大容量送电能力，主要定位于我国西南大水电基地和西北大煤电基地的超远距离、超大容量外送。'},
      {con: '特高压直流在我国的应用前景广阔。以国家电网为例，金沙江一期溪洛渡和向家坝送出工程将采用3回±800千伏、640万千瓦直流特高压送出，四川锦屏水电站采用1回±800千伏、640万千瓦直流特高压送出，以上工程计划在2011年底~2016年期间陆续建成投运。金沙江二期乌东德、白鹤滩水电站送出工程也将采用3回±800千伏、640万千瓦直流特高压送出。发展特高压直流输电，还为我国后备能源基地西藏水电和新疆煤电开发提供经济的输电方式，为加强与俄罗斯、蒙古、哈萨克斯坦等国的电力合作提供技术保障。'},
    ],},
    companyNew18: {title: '理解能源互联网',source: '',author: '',date: '2022年01月05日',content:[
      {con: '能源是人类文明进步的重要物质基础和动力，攸关国计民生和国家安全。在绿色低碳的发展理念下，一次能源能量密度持续上升的路径被打破，能源行业进入转型发展新时期。建设能源互联网，将先进信息通信技术、控制技术与先进能源技术深度融合应用，能够有力支撑能源电力清洁低碳转型、能源综合高效利用和多元主体灵活便捷接入，是促进能源可持续发展的重要手段。'},
      {con: '能源互联网可理解是综合运用先进的电力电子技术, 信息技术和智能管理技术, 将大量由分布式能量采集装置, 分布式能量储存装置和各种类型负载构成的新型电力网络、石油网络、天然气网络等能源节点互联起来, 以实现能量双向流动的能量对等交换与共享网络。'},
      {url: require('../assets/images/new/15.png')},
      {con: '能源互联网在横向上体现能源转换（电力生产）、存储、传输、消费“四大环节”的顺畅贯通，纵向上体现为能源网架、信息支撑、价值创造“三大体系”的有机衔接。其中，能源网架体系是能源互联网的物质基础，承载能源流。信息支撑体系是能源互联网的神经中枢，承载信息流。价值创造体系是能源互联网的价值实现载体，承载业务流。'},
      {con: '国家电网公司紧密围绕实现“双碳”目标和构建新型电力系统，立足电网主业，规划建设能源互联网：一是坚持绿色发展，通过源网荷储一体化、多能互补等措施，加快新能源从新增装机主体发展为总装机主体，超过煤电成为第一大电源，推动新型电力系统构建和能源系统绿色转型，积极服务国家“双碳”目标。'},
      {con: '二是强化安全保障，通过建设坚强骨干网架、弹性灵活配电网、平台云网融合等，构建能源互联网安全防御体系，提高“双高”、“双峰”背景下电网抗扰动能力和自愈能力，提升信息安全态势感知能力和智能化、动态化网络安全防护水平，实现更高水平的电力安全保障。'},
      {con: '三是注重智慧赋能，全面提升信息采集、传输、处理、应用等能力，推动传统电网基础设施和新型数字化基础设施融合，促进电网调度运行智能化和运营管理智慧化，实现以数字化转型为主线的智慧赋能。'},
      {con: '四是突出价值创造，在持续深化能源配置、社会民生、产业发展等传统价值的基础上，通过打造能源转型服务、能源数字产品、能源平台生态三类新兴价值，推动战略性新兴产业发展，打造共享、共治、共赢的能源互联网生态圈。到2025年基本建成、2035年全面建成具有中国特色国际领先的能源互联网。2025年实现非化石能源占一次能源比重达到20%，电能占终端能源消费比重达到30%，跨省跨区输电能力达到3亿千瓦。'},
      {url: require('../assets/images/new/16.png')},
      {con: '能源互联网可理解是综合运用先进的电力电子技术, 信息技术和智能管理技术, 将大量由分布式能量采集装置, 分布式能量储存装置和各种类型负载构成的新型电力网络节点互联起来, 以实现能量双向流动的能量对等交换与共享网络。'},
      {con: '从政府管理者视角来看，能源互联网是兼容传统电网的，可以充分、广泛和有效利用分布式可再生能源的、满足用户多样化电力需求的一种新型能源体系结构；从运营者视角来看，能源互联网是能够与消费者互动的、存在竞争的一个能源消费市场，只有提高能源服务质量，才能赢得市场竞争；从消费者视角来看，能源互联网不仅具备传统电网所具备的供电功能，还为各类消费者提供了一个公共的能源交换与共享平台。'},
    ],},
    companyNew19: {title: '超高压的应用',source: '',author: '',date: '2022年01月08日',content:[
      {con: '标准大气条件下的空气放电电压大概在30kV/cm，即330kV的理论放电距离为11cm，考虑到实际条件（天气状况、地理位置等）实际放电距离可能远小与这个值。由此可见，330kV输电线路的放电距离很小，其安全距离可想而知并非很大，目前在搭设线路时留出的绝缘距离远远超出上述标准的安全距离。'},
      {con: '输电线路产生的「火花」专业上叫「输电线路的电晕」。电晕放电是由于输电线路表面的电场强度很高 , 引起空气电离而发生的放电现象。空气分子的游离强度一般为 20～30 kV/cm ,当输电线路表面电场强度超过这个数值之后，就会听到线路发出“ 咝咝 ” 的放电声，嗅到臭氧的气味，夜间还可以看到导线周围发出的蓝紫色荧光，这就是电晕放电。'},
      {url: require('../assets/images/new/17.png')},
      {con: '在《GB/T4365-1995电磁兼容术语》中，对「 电磁辐射 」是这样定义的：电磁辐射是能量以电磁波形式由源发射到空间的现象，或能量以电磁波形式在空间传播。  '},
      {con: '由于330kV输电线路传输的电能频率为50赫兹，因而会产生电磁感应现象，其对周边的实际影响主要以电磁感应效应为主，而不是电磁辐射。一个很明显的例子就是，在天气潮湿的情况下，人体接触到高压架空电线下工作着的未能接地的金属物体充电，会产生麻痛的感觉，这就是电磁感应现象，但是其能量非常小，类似冬天脱毛衣时产生的静电效应，不会对人体产生危害； 330kV输电线路传输的电能频率为50赫兹，其产生的是一种极低频率的电磁场，不可能以电磁波形式在空间传递能量；其次，其产生的工频能量非常小，能量小则空间传输能力差，其电磁环境影响基本可以忽略不计； 电磁辐射对人体的作用主要是热效应，危害主要出现在射频电磁场。'},
      {con: '330kV输电线路辐射属于工频电磁场，而不是射频电磁场，其能量非常小，其辐射性质较弱，对人体不会造成影响。 综上所述，330kV输电线路传输的电能频率为50赫兹，对周边的实际影响主要以电磁感应效应为主，产生的是一种极低频率的电磁场，其能量非常小，其辐射性质较弱，对周围电磁环境影响基本可以忽略不计，对人体不会造成影响。'},
      {url: require('../assets/images/new/18.png')},
      {con: '在超高压输电问世前，电网一般是220/330Kv，输电半径一般在200~500公里，再远的话，参数不能保证。现在有了超高压，使1000公里的输电有了可能。'},
    ],},
    companyNew20: {title: '电网建设再加速',source: '中国能源报',author: '韩逸飞',date: '2022年02月09日',content:[
      {con: '1月中旬召开的国家电网2022年度工作会议透露，2022年，在电网投资方面，国家电网计划投资金额为5012亿元，首次突破5000亿元，创历史新高。'},
      {con: '在国家发改委近日召开的1月份新闻发布会上，国家发改委经济运行调节局局长李云卿表示，要发挥大电网资源优化配置作用，推动跨省区输电通道加快核准，并尽早开工、尽早投产，有效提升大范围资源优化配置能力。'},
      {con: '业内分析认为，在国家鼓励引导、企业积极推动的背景下，电网建设特别是特高压和配电网两个领域将迎来加速期。'},
      {con: '根据国家电网和南方电网披露的数据粗略计算，“十四五”期间，我国电网投资总额接近3万亿元，高于“十二五”的2万亿元、“十三五”的2.57万亿元投资额。分析认为，3万亿元仅是电网投资的下限而已。电网投资缘何持续增长，背后的逻辑是什么？'},
      {con: '据业内分析，电力需求的快速增长是推动电网投资增加的直接原因。城镇化和农村新增需求持续推动电力负荷增加，工业、交通、建筑等领域加速电气化带来用电新增量。根据中电联报告预测，“十四五”期间我国全社会用电量年均增速超4.8%，2025年全社会用电量为9.5万亿千瓦时。'},
      {con: '除了用电量增长外，电力系统和电源结构也在发生变化。新能源向主体能源迈进，据预计，未来几年，年均新能源装机有望超过1亿千瓦。与此同时，分布式电源发展、电动汽车接入提速。在这一趋势下，亟需增加电网投资，新建外送通道，提升配网智能化水平，实现数字化赋能，更好支撑电力系统转型。'},
      {url: require('../assets/images/new/19.png')},
      {con: '今年的货币政策是稳字当头，要做有效加法，走在市场曲线前面。因此，可以判断，其发力点不再是传统的房地产行业，而是新基建等符合新发展理念的项目，而特高压、新能源等正是新基建的代表。在相对宽松的货币政策下，金融的活水将更多流向这些领域。'},
      {con: '无论是从客观现实需求，还是从主观转型意愿，抑或从外部支持条件等方面来分析，电网投资增加都在情理之中。那么，在电网投资持续增加的背景下，这些钱将如何花在刀刃上？'},
      {con: '电网建设投资主要方向是补齐两头短板：一是以特高压直流为主的跨区跨省输电，以解决新能源大基地送出问题；二是加强配电网建设，以应对日益增长的分布式电源发展、电动汽车接入以及需求响应要求，提升“源荷互动”的能力。'},
      {con: '数据显示，截至2020年底，中国已建成“14交16直”，在建“2交3直”共35个特高压工程，在运在建特高压线路总长度4.8万公里。'},
      {con: '在以新能源为主体的新型电力系统建设大背景下，特高压通道成为中国“西电东送、北电南供、水火互济、风光互补”的能源运输“主动脉”，也是破解能源电力发展深层次矛盾，实现能源从就地平衡到大范围配置转变的有效途径。在这种情况下，特高压建设必将提速。'},
      {con: '目前，我国清洁能源可开发资源约60亿千瓦，如果全部有效开发，相当于新建18亿千瓦常规火电，但是我国70%以上的清洁能源集中在西部、北部地区，当地难以完全消纳，必须在全国范围内消纳，这就需要发挥大电网的资源配置优势。'},
      {con: '一方面让当前既有通道更高效输送新能源，另一方面要新建跨区跨省通道，完善省间交易机制，促进新能源更大范围优化配置。'},
      {con: '2021年3月发布的《国家电网公司“碳达峰、碳中和”行动方案》提出，“十四五”期间，国家电网已建跨区输电通道逐步实现满送，提升输电能力3527万千瓦。规划建成7回特高压直流，新增输电能力5600万千瓦。'},
      {con: '“十四五”期间国网跨省跨区输电通道重点工程包括已经建成投产的陕北-湖北、雅中-江西特高压直流工程，核准在建的白鹤滩-江苏、白鹤滩-浙江特高压直流和闽粤联网工程，以及新增、正在开展前期工作的川渝特高压交流和金上-湖北、陇东-山东、哈密-重庆特高压直流等“3交8直”重点工程。'},
      {con: '这批新增输电通道建成后，将提升跨省跨区输电能力6000万千瓦以上，促使可再生能源电量占比不低于50%。'},
      {url: require('../assets/images/new/20.png')},
      {con: '配电网涵盖电力生产、传输、存储和消费的全部环节，具备了能源互联网全部要素，是发展新业务、新业态、新模式的物质基础。根据相关研究，“十四五”时期，配电网规划将由传统物理网架规划向能源网架、信息支撑、价值创造三大体系全面延伸，推动以电为中心的智慧能源系统建设。业内认为，电网投资增加将有利于补齐这一块“拼图”。'},
      {con: '新型电力系统的提出，既重申了新能源发展对于实现碳达峰碳中和目标的重要作用，也正视了电网的中枢平台地位。近年来，受电源、用户两端挤压，处于中间环节的电网加速向源、网、荷协同方向发展，从而实现“源荷互动”。这无疑需要提升配电网的综合承载能力、供电保障能力和普遍服务能力。'},
      {con: '当前，随着分布式电源、储能、电动汽车等大量接入配电网，负荷正从单一用电向发用电一体化方向转变，这为电力系统局部用电平衡、运行方式安排等带来新挑战。新能源更大规模发展，也要求负荷侧提供柔性调节能力，以实现“源随荷动”向“源荷互动”的转变。'},
      {con: '配电网领域也被认为是数字化赋能的一个关键领域。新增投资有利于深化采集感知，加强配电网感知终端建设，并向用户侧延伸拓展，建设与配电网智能调控、智慧运检、智慧用电等业务相适应的信息感知基础设施，从而提升电网实时感知、广泛互联、安全可控水平，助力电网向新型电力系统转型。'},
    ],},
    companyNew21: {title: '电力系统全面转型升级的重大历史机遇',source: '中国电网',author: '张友良',date: '2021年05月18日',content:[
      {con: '2020年9月，国家主席习近平在第七十五届联合国大会一般性辩论上郑重提出碳达峰、碳中和目标。“双碳”目标是党中央作出的重大决策，关系着中华民族永续发展和构建人类命运共同体。实现“双碳”目标的过程是一场广泛而深刻的经济社会变革，在能源供给侧要构建多元化清洁能源供应体系，大力发展非化石能源，重点是加大风电、光伏等新能源发电的开发利用，要求电力系统展现更大作为。'},
      {con: '2021年3月15日，习近平总书记在中央财经委员会第九次会议上提出构建以新能源为主体的新型电力系统，是“双碳”目标背景下党中央对电力系统发展作出的最新重大决策。构建以新能源为主体的新型电力系统是习近平生态文明思想的最新原创性成果，是我国能源电力发展的根本遵循。'},
      {con: '一是凸显了电力系统在“双碳”目标实现过程中的关键作用。践行碳达峰、碳中和，能源是主战场，电力是主力军。电力系统是重要的碳排放源，自身承担着较大减排压力，同时需要通过电能替代承接其他行业用能需求，支撑全社会减排。习近平总书记在实现“双碳”目标的基本思路和主要举措中特别提出构建新型电力系统，明确了电力行业服务碳达峰、碳中和的重要责任和使命，体现了电力系统在“双碳”能源转型中的关键作用。'},
      {con: '二是明确了电力系统未来发展的目标与方向。实现“双碳”目标时间紧迫、任务艰巨、影响广泛，需要全行业迅速统一思想，形成推动能源电力转型的强大合力。构建新型电力系统要求的及时提出，以权威声音解答了建设什么样的电力系统、以何种主体能源建设电力系统等关键问题，为能源电力行业提供了根本行动指南，对实现“双碳”目标具有重大而深远的指导意义。'},
      {url: require('../assets/images/new/21.png')},
      {con: '在“双碳”目标背景下，构建新型电力系统与落实国家能源安全新战略之间存在什么内在联系？'},
      {con: '2014年，习近平总书记在中央财经领导小组第六次会议上提出“四个革命、一个合作”国家能源安全新战略，成为指导我国能源转型的行动纲领，引领能源行业发展进入了新时代。基于“十三五”期间能源发展取得的阶段性成果，党中央审时度势提出“双碳”目标，为能源革命制定了具体时间表。构建以新能源为主体的新型电力系统，是“四个革命、一个合作”能源安全新战略的最新实践与发展，是“双碳”目标背景下能源革命内涵的深化，为电力发展指明了方向。'},
      {con: '具体而言，在能源供给革命方面，新型电力系统将以新能源为供应主体，同时深度替代其他行业的化石能源使用，对建立多元能源供应体系、保障供应安全具有重要意义。'},
      {con: '在能源消费革命方面，新型电力系统将通过电能替代实现能源消费高度电气化，有助于提高用能效率、控制能源消费总量，加快形成清洁低碳和节能型社会。'},
      {con: '在能源技术革命方面，新能源发电广泛替代常规电源将深刻改变电力系统技术基础，转型将全面促进电力技术创新、产业创新、商业模式创新，催生产业升级的新增长点。在能源体制革命方面，构建新型电力系统是一项长期的系统性工程，现有电力结构、发展模式、利益格局均面临革命性变化，要求全面深化电力体制改革，进一步发挥市场在能源清洁低碳转型与资源配置中的决定性作用。'},
      {con: '能源国际合作方面，我国已成为全球最大的可再生能源市场和设备制造国，新型电力系统的建设将更加有力推动我国可再生能源技术、装备和服务“走出去”，为全世界绿色低碳发展、打造能源命运共同体贡献中国力量、中国智慧、中国方案。如何理解新型电力系统与电网之间的关系？'},
      {con: '电力系统是由发输变配用各领域、源网荷储各环节、技术体制各层面紧密耦合的有机整体。电网是连接电能生产与消费的基础平台设施，是电力系统的中枢环节。新型电力系统各方面的变化将更加凸显电网的平台作用，对未来电网的物理形态和技术特征提出了新的要求，具体表现为高度的安全性、开放性、适应性。'},
      {con: '安全性方面，新型电力系统中各级电网协调发展，多种电网技术相互融合，广域资源优化配置能力显著提升，电网安全稳定水平可控、能控、在控，能够承载高比例新能源、直流等电力电子设备接入，有效保障国家能源安全、电力可靠供应与电网安全运行。'},
      {con: '开放性方面，新型电力系统的电网具有高度多元、开放、包容特征，兼容各类新电力技术，满足各种新设备便捷接入需求，支撑各类能源交互转化、新型负荷双向互动，是各能源网络有机互联的链接枢纽。'},
      {con: '适应性方面，新型电力系统的电网与源、荷、储各环节紧密衔接、协调互动，通过应用先进技术并扩展控制资源池，形成较强的灵活调节能力和高度智能的运行控制能力，适应海量异构资源广泛接入并密集交互的应用场景。'},
      {url: require('../assets/images/new/22.png')},
      {con: '在现有电力系统的基础上构建新型电力系统，电力调度面临的主要挑战和机遇有哪些？现有电力系统向新型电力系统转型升级的过程中，电力系统的物质基础和技术基础持续变化。一是电力生产结构发生深刻变化。新型电力系统的一次能源供应主体由稳定可控的煤、气、水等常规能源转向风能、太阳能等新能源。新能源供应与气象环境相关，具有随机、波动、间歇特性，为电源出力引入高度不确定性；新能源资源不能运输或存储，只能就地转换，按照资源分布进行集中或分散式开发，电源总体接入位置愈偏远、愈加深入低电压等级。二是电力系统技术基础发生深刻变化。传统电力系统以交流电技术为基础，常规电源通过机械旋转部件带动同步发电机并网，以机电—电磁耦合作用实现同步运行。新能源机组通过静止式电力电子装置并网，依赖锁相环等控制机制实现同步，交流电力系统同步运行机理由物理特性主导转向人为控制算法主导。'},
      {con: '三是电力系统控制基础发生深刻变化。传统电力系统的控制对象是同质化大容量常规发电机组，具有连续调节和控制的能力，采用集中控制模式。新能源单机容量小、数量众多、布点分散、特性多样，电力电子设备采用基于快速切换的离散控制，使得新型电力系统控制模式发生根本性改变。'},
      {con: '随着电力系统各方面发生快速而深刻的变化，以新能源为主体的新型电力系统调度运行面临严峻挑战。一是电力平衡保障难度加大。电力系统是一个发用电实时平衡系统，随着出力随机波动的新能源发电占比提升，新能源小发期间电力供应不足和大发期间消纳困难的问题将频繁交替出现。特别是在极热极寒无风、连续阴雨等特殊天气下，新能源对高峰电力平衡支撑有限。例如，2021年1月上旬全国大范围寒潮期间，晚高峰时段新能源出力仅为装机的13%，电力供应保障困难的问题已经显现。'},
      {con: '二是电网安全运行风险加大。在未来相当长的时间内，电力系统仍将以交流同步技术为主导，而随着新能源发电等电力电子静止设备大量替代旋转同步电源，维持交流电网安全稳定的物理基础被不断削弱，功角、频率、电压等传统稳定问题呈恶化趋势。当前新能源机组抗扰动能力不够强，面对频率、电压波动容易脱网，使故障演变过程更加复杂，存在大面积停电风险。电力电子设备比例不断升高，更宽时间尺度的交互影响加强，出现宽频振荡等新形态稳定问题，电网呈现多失稳模式耦合的复杂特性。'},
      {con: '三是运行控制模式亟待创新。未来新型电力系统中，控制原理将发生根本性变化，控制规模呈指数级增长，控制对象特性差异极大，运行监视与控制难度加大。供需双侧不确定性增加导致电网未来运行状态可预见性降低，使现有基于确定性框架的预防控制边界和故障防御策略制定面临很大困难。'},
      {con: '直面问题、迎难而上，攻克新型电力系统调度控制难题，保障新型电力系统的平稳转型与安全优质运行，是时代赋予电力调度的神圣职责与使命，也是电力调度全面转型升级的重大历史机遇。一是升级电力系统分析认知体系。开展新型电力系统稳定机理认知与分析基础理论研究，建设以全电磁暂态、平台化、智能化为特征的大电网多时间尺度仿真分析手段，对高比例新能源、高比例电力电子设备的“双高”电力系统特性认知更加全面、更加精确、更加高效。'},
      {con: '二是完善电力系统运行控制体系。促进“大云物移智链”等先进信息通信技术与电力技术深度融合，依托市场化机制充分挖掘源网荷储各方资源控制调节潜力，实现全业务信息感知、全系统协同控制、全过程在线决策、全时空优化平衡、全方位负荷调度，全面支撑新型电力系统运行控制需求。'},
      {con: '三是强化电力系统故障防御体系。根据新型电力系统特性认知，重构故障防御标准和体系架构。构建运行风险主动防控体系，基于电网全景全频段状态感知，在线评估电网安全态势，通过风险，预测、预判、预警和预控，实现安全风险的事前主动防御。发挥电力电子设备调节快速、可塑性强的特点，充分利用丰富的控制资源，通过大范围多资源协同快速紧急控制，增强电网故障的事中防御、事后恢复能力。'},
      {url: require('../assets/images/new/23.png')},
      {con: '在技术条件和市场机制研究方面，哪些工作亟待开展？'},
      {con: '化石能源时代的国际能源竞争是资源的竞争，新能源时代的竞争是核心技术竞争。世界主要国家和地区均将能源技术视为新一轮科技革命和产业革命的突破口。传统电工理论与技术在构建新型电力系统过程中面临瓶颈，迫切需要新理论、新方法、新材料与新设备的支撑，必须以科技创新为引领，为新型电力系统发展提供源源不断的内生动力。能源电力行业具有技术资金密集的特点，存量系统庞大，转型不能“急刹车”“急转弯”，近期应重点挖掘先进成熟技术潜力，支撑新能源快速发展，同时集中科研力量开展关键基础理论、颠覆性技术攻关，为未来构建新型电力系统做好技术储备。需要突破的基础理论包括了新型电力系统供需平衡理论、“双高”电力系统过渡过程及稳定性认知分析理论、广域分散协同优化控制理论等；近期亟需的应用技术包括新能源机组及储能主动支撑“构网”技术、远距离海上风电及汇集送出技术、“双高”电力系统仿真评估技术、源网荷储资源协调控制技术等；未来构建新型电力系统的颠覆性技术包括高效电氢双向转换、大容量低成本储能、高效碳捕捉与循环利用、柔性直流组网技术等。'},
      {con: '能源电力是国民经济的重要基础行业，电力价格的变化对经济社会发展影响巨大。新型电力系统的构建是对传统电力系统的全方位变革，会带来一定的转型成本，必然会传导到电力价格中去。必须算好转型经济账，深化电力体制改革，科学设计市场模式，合理构建电力价格形成及成本疏导机制，为构建新型电力系统提供有力的体制与资金保障，确保为经济社会发展提供绿色经济优质的电力。'},
      {con: '一是研究建立科学合理的电价体系，全面分析新能源大规模发展带来的系统成本上升问题，保持合理电价水平。推动建立以节约能  源为导向的电价机制，抑制不合理的能源消费，推动能源转型和节能提效。出台抽水蓄能、电化学储能价格形成机制和可中断负荷电价机制。二是持续推进电力市场建设，研究建立促进新能源消纳的市场机制，加快地构建全国统一电力市场，完善辅助服务市场，引导抽蓄、储能、可中断负荷等参与系统调节。研究微电网、新能源汽车等参与电力市场交易的机制。'},
      {con: '三是推动碳市场和电力市场协同发展，将电能价格与碳排放成本有机结合，发挥两个市场相互促进、协同互补作用，提高清洁能源的市场竞争力，助推碳减排技术快速进步。未来发电侧、电网侧和用电侧的生态图景如何？电网企业如何更好地发挥作用？'},
      {con: '构建新型电力系统将催生大量新技术、新业态，未来发电侧、电网侧、用电侧均将出现全新生态图景。发电侧加快西部、北部新能源集中开发与东中部分布式新能源开发，东部海上风电由近海逐步向远海拓展；新能源既是装机主体、电力与电量供应主体，也是责任的主体，具备相当程度的主动支撑、调节与故障穿越能力；水电、核电、生物质等清洁能源协同发展，非化石能源资源禀赋得到充分发挥；化石能源电源的功能变为兜底保障、调节与支撑。电网侧向适应电力生产与消费结构变化的新形态转变，西部、北部大型清洁能源基地向东中部负荷中心的输电能力进一步提高；微电网、海上风电汇集和大规模柔直等新型组网技术快速发展，交直流混联大电网与多种形态电网并存；现代智慧配电网快速发展，支持分布式新能源、电动汽车、储能等设备海量接入，满足功率双向流动和多元负荷用电需要。用电侧分布式电源广泛存在，负荷从单一用电“消费者”变为发用电一体的“产消者”；电、冷、热、气等多种能源相互耦合、灵活转换，负荷呈现明显差异性和互补性，可以提供相当程度的调节与支撑能力。'},
      {con: '电网是电力系统的中枢环节，电网企业在构建新型电力系统的过程中必将扮演极为重要的角色，应当多方面发力，积极服务和支撑能源转型。一是加快开放型智能电网建设，提升新能源承载能力。加快建设跨省跨区输电通道，建设大电网、构建大市场，并同步加强送受端交流电网、扩大联网规模以承载跨区大规模输电需求。发展现代智慧配电网，提升城乡配电网电气化高承载力。积极推动分布式微电网建设，促进多元化源荷即插即用与分布式新能源的就地消纳。'},
      {con: '二是强化源网荷储协调发展，保障新能源高效利用。并推动合理安排新能源发展规模、布局和时序，促进新能源与电网、新能源与灵活调节电源协调发展。尽早启动并加快抽蓄电站建设，大力推动非抽蓄储能发展，提升系统调节能力。以并网技术标准为抓手，着力提升新能源机组主动支撑能力。'},
      {con: '三是推动多能互补与电能替代，服务终端消费电气化。发挥电网中枢作用，加强多种能源的相互转换、联合控制、互补利用，提升能源综合利用效率。积极服务并聚合电动汽车、用能终端、储能设备等，发挥负荷集群规模效应，参与电网运行控制。多领域、多维度推进电能替代，提升终端消费电气化水平。'},
      {con: '四是开展关键技术装备攻关，占据自主创新制高点。与政府、高校、产业上下游协同，联合打造“政产学研用”深度融合科技创新体系，加强新型电力系统基础理论研究，集中突破新型电力系统运行控制等关键技术以及大规模储能等颠覆性技术，合力攻关核心技术装备，推进科技示范工程建设，全面提升国产电工电气技术装备水平。'},
      {con: '五是推动碳电市场体系建设，加快绿色能源价值实现。研究绿证、碳交易机制及其与电力市场的耦合方式，推动构建适应高比例新能源发展的市场模式，实现储能和其他电源对支撑新能源发电的辅助服务价值回报，从经济上保障新型电力系统的建设与运行，同时汇聚各方力量促进新机制、新业态的创新和融合。'},
    ],},
    companyNew22: {title: '以新能源为主体的新型电力系统',source: '电力网',author: '',date: '2021年04月01日',content:[
      {con: '近日，央财经委员会第九次会议召开，会上指出，“十四五”是碳达峰的关键期、窗口期需石窟关注其发展状况。同时南方电网公司发布服务碳达峰、碳中和工作方案，提出5个方面21项重点举措，构建以新能源为主体的新型电力系统。'},
      {con: '“新型电力系统”这个中央首提的新名词，十分惹眼。关于这个概念，确切精准的定义暂时还没有。但形态特征上，无外乎清洁低碳、安全可控、灵活高效、开放互动、智能友好等。'},
      {con: '现在的电力系统不是为这么大规模新能源准备的，新能源装机已超出系统承受范围，弃风弃光问题凸显，高比例新能源电力系统需付出极大经济成本。'},
      {con: '构建清洁低碳安全高效的能源体系，控制化石能源总量，着力提高利用效能；  实施可再生能源替代行动，深化电力体制改革，构建以新能源为主体的新型电力系统；力争2030年前实现碳达峰，2060年前实现碳中和。'},
      {url: require('../assets/images/new/24.png')},
      {con: '“十四五”推动新能源新增装机1亿千瓦打造清洁低碳电网典范  3月18日，南方电网公司在广州召开服务碳达峰、碳中和重点举措新闻发布会，公布将大力推动供给侧能源清洁替代，以“新电气化”为抓手推动能源消费方式变革，全面建设现代化电网，带动产业链、价值链上下游加快构建清洁低碳安全高效的能源体系。  '},
      {con: '到2025年，将推动南方五省区新能源新增装机1亿千瓦，达到1.5亿千瓦。到2030年，推动南方五省区新能源再新增装机1亿千瓦，达到2.5亿千瓦；非化石能源装机占比由2020年的56%提升至65%，发电量占比从2020年的53%提升至61%。 '},
      {con: '“十四五”是碳达峰的关键期、窗口期，南方电网公司将积极落实可再生能源替代行动，构建以新能源为主体的新型电力系统，全力服务供电区域广东、广西、云南、贵州、海南及港澳地区绿色低碳发展，为国家实现碳达峰、碳中和目标作出应有贡献。  '},
      {con: '经过十余年发展，南方五省区新能源装机发展到目前5000万千瓦的规模，2030年将达到2.5亿千瓦，意味着要增加近4倍，能源供给将更加清洁低碳，我们将打造清洁低碳电网典范。'},
      {con: '“风光”随机性、波动性的特性不变，电力系统能否稳定提供电源？  我们知道，新能源主要就是指风电和光伏，新能源属于可再生能源，后者包括了风力发电、光伏发电、光热发电、水电、生物质发电等。构建以新能源为主体的新型电力系统，意味着风电和光伏将是未来电力系统的主体，煤电将成辅助性能源。这个系统以“双碳”目标为指引  这个系统是实现碳达峰、碳中和的主要手段。  '},
      {con: '此次中央财经委员会第九次会议对新型电力系统的定调，就是在过去发改、生态等系统的调研摸索基础上，“双碳”目标的指引下，对我国未来电力系统所作的定位，必将对整个能源产业链产生深度影响，人们的经济社会生活也会随之重塑。'},
      {url: require('../assets/images/new/25.png')},
      {con: '过去的电力系统要更新换代，未来一新能源为主体的新型电力系统到底是什么样的？'},
      {con: '新能源+氢+储能+电动车+数字化+智能调配+用户智能双向选择，南方电网公司在构建以新能源为主体的新型电力系统上也做出不少的努力。'},
      {con: '一生产侧：新能源在电源结构中的比例会占到一半以上，符合主体定义。'},
      {con: '风电、光伏、水电、生物质发电、氢能等一二次能源大力发展；风能的装机容量是现在的12倍多，而太阳能会是现在的70多倍；氢能也将作为风光衍生出的二次能源用作交通、储能及发电端。'},
      {con: '新能源侧配置。2020年间，南方五省区非化石能源装机占比56%，发电量占比53%，居世界前列；全网水能利用率超过99.5%，风电、光伏发电利用率均达99.7%，基本实现全额消纳。西电东送直流综合能量可用率连续10年保持在96%以上，处于世界一流水平。“十三五”期间所有直流输电通道的平均年利用小时数超过设计值，利用效率高于全国平均水平。'},
      {con: '二调度侧：电网变成新能源友好型更具兼容性灵活性电网。'},
      {con: '南方电网公司将进一步提升清洁能源资源优化配置能力。方案提出，要推动西电东送可持续发展，积极引入区外清洁电力。2030年前争取新增受入2000万千瓦区外电力，新增区外送电100%为清洁能源。南方电网跨省区送电规模由2020年的5800万千瓦提升至2030年的7800万千瓦左右。'},
      {con: '随着碳达峰、碳中和目标推进，新能源将大规模并网，新型用能设备将广泛接入,电力系统将呈现高比例可再生能源、高比例电力电子设备的“双高”特征，对电网安全稳定运行带来挑战。'},
      {con: '为此，方案提出要全面建设安全、可靠、绿色、高效、智能的现代化电网，构建适应新能源发展的坚强网架，推进城市电网升级和现代农村电网建设，推动电网数字化转型和智能化调控，优化调度运行，保障电网安全稳定运行，提高新能源并网质量和效率。'},
      {con: '三输配侧：特高压大力发展，管道输氢、液氢发展。'},
      {con: '西北新能源基地+特高压/管道氢/液氢+东部消纳适用。'},
      {con: '四能源供给侧：智慧能源系统。'},
      {con: '落实重点行业领域减污降碳行动，全面服务能源消费方式变革。根据方案，南方电网公司将把节约能源资源放在首位，在工业、交通、建筑、农业农村等各个领域加快推动“新电气化”进程，加快充电设施建设，持续开展节能服务，推广电力需求侧管理，推动能源资源高效配置和利用。  '},
      {con: '预计到2030年，南方电网公司将助力南方五省区电能占终端能源消费比重由2020年的32%提升至38%以上，支撑南方五省区单位国内生产总值二氧化碳排放比2005年下降65%以上。  '},
      {con: '虽然新型电力系统精确的定义还没有出炉。但毫无疑问的是，随着高比例新能源、高比例电力电子设备接入电网，新型电力系统必将发生“彻底”改变。作为历史的参与者和见证者，工友们都要一起加油！未来更好，拭目以待！'},
    ],},
    companyNew23: {title: '新型电力系统概念、核心特征、关键技术及建设方向',source: '中国能源报',author: '',date: '2021年12月21日',content:[
      {con: '深化电力体制改革，构建以新能源为主体的新型电力系统，是党中央基于保障国家能源安全、实现可持续发展、推动碳达峰、碳中和目标实施作出的重大决策部署，为新时期能源行业以及相关产业发展提供了重要战略指引，有利于加快我国构建清洁低碳、安全高效的现代能源体系步伐，推动经济社会绿色转型和高质量发展。'},
      {tit: '一、新形势下新能源发展的历史机遇以及电力系统面临的挑战'},
      {con: '当前，我国正处于工业化后期，经济对能源的依赖程度高，而我国能源消费以化石能源为主，2020年化石能源占一次能源比重达84%。碳达峰、碳中和目标下，我国能源结构将加速调整，清洁低碳发展特征愈加突出。'},
      {url: require('../assets/images/new/26.png')},
      {con: '（一）碳达峰、碳中和目标推动新能源向“主体能源”转变   '},
      {con: '随着经济社会的转型发展和能源利用效率的不断提升，能源消费总量将会在碳排放量达到峰值后逐步下降，但电能消费总量一直呈上升趋势，预计将从2020年的7.5万亿千瓦时增长至2060年的15—18万亿千瓦时。'},
      {con: '新能源将迎来跨越式发展的历史机遇，成为电能增量的主力军，实现从“补充能源”向“主体能源”的转变。预计到2030年，风电、光伏装机规模超16亿千瓦，装机占比从2020年的24%增长至47%左右，新能源发电量约3.5万亿千瓦时，占比从2020年的13%提高至30%。   '},
      {con: '2030年后，水电、核电等传统非化石能源受资源和站址约束，建设逐步放缓，新能源发展将进一步提速。预计到2060年，风电、光伏装机规模超50亿千瓦，装机占比超80%，新能源发电量超9.6万亿千瓦时，占比超60%，成为电力系统的重要支撑。'},
      {con: '（二）新型电力系统面临的挑战'},
      {con: '新能源具有典型的间歇性特征，出力随机波动性强。以电动汽车为代表的新型负荷尖峰化特征明显，最大负荷与平均负荷之比持续提升。发电侧随机性和负荷侧峰谷差加大将对传统电力系统造成较大的冲击，要实现构建以新能源为主体的新型电力系统愿景目标，我们还需要应对以下问题：'},
      {con: '一是电力系统的可靠容量不足。风电、光伏因其自身出力特性，可靠性偏低。经研究，到2030年，在全国范围内相对均匀分布的情况下，新能源装机超10亿千瓦，每年将有30天以上出力低于装机容量的10%，置信容量仅为1亿千瓦。'},
      {con: '假设峰值负荷约18亿千瓦，水电装机5亿千瓦，可靠容量约3.5亿千瓦，核电装机3亿千瓦，可靠容量约3亿千瓦，风电、光伏可靠容量按1亿千瓦、其他可再生电源可靠容量按0.5亿千瓦估列，再考虑可中断负荷及电动汽车等可调节容量约5亿千瓦，那么电力系统可靠容量缺口约5亿千瓦，也就是说，对于稳定电源（火电）的需求仍有5亿千瓦。'},
      {con: '二是传统大电网难以满足未来电力输送需求。长期以来，我国能源资源与负荷呈逆向分布，大电网是连接三北地区等资源区与中东部负荷区的重要途径。但随着经济社会的发展，各地区用电量需求与日俱增，预计到2030年，仅广东、福建、浙江、江苏和山东五省的全社会用电量就将达到3万亿千瓦时，未来可能会达到5万亿千瓦时。'},
      {con: '如果绝大部分从三北地区远距离输送，按照80%的比例测算，送电规模将达到4万亿千瓦时，这就需要建设约100条特高压送电通道，且每条特高压不受电磁环网制约，全年满功率运行，无疑这将难以实现。'},
      {con: '三是电力系统转动惯量以及长周期调节能力不足。光伏发电利用半导体的光电效应将光能转变为电能，无转动惯量，风力发电转动惯量也严重不足，因此，当电力系统中大量的新能源机组替代常规电源时，系统频率调节能力将显著下降。另一方面，目前的电化学储能等技术只能解决电力系统的短期调节问题，且受成本等因素制约，月度调节和季度调节还存在很大障碍。'},
      {con: '而氢能、CCUS等技术在一定时期内很难取得突破性进展实现大规模应用，当局部地区出现极端天气状况时，高比例、大规模新能源的电力系统将面临长周期调节能力不足的挑战。'},
      {tit: '二、新型电力系统概念、核心特征、关键技术'},
      {con: '新型电力系统以新能源为主体，贯通清洁能源供需的各个环节，有利于体现清洁电力的多重价值，促进经济社会低碳转型，是推动能源革命落地的创新实践。'},
      {url: require('../assets/images/new/27.png')},
      {con: '（一）新型电力系统的概念内涵   '},
      {con: '新能源成为主体能源只是新型电力系统的基本特征，它有着更深刻的内涵。'},
      {con: '首先，新型电力系统是贯通清洁能源供给和需求的桥梁。构建新型电力系统的本质，是要满足高占比新能源电网的运行需求，通过打通能源供需各个环节，实现源网荷储高效互动。'},
      {con: '其次，新型电力系统是释放电能绿色价值的有效途径。新型电力系统有利于清洁能源的优化配置和调度，通过绿色电力能源中介，引导能源生产和消费产业链的绿色转型，实现电能绿色价值顺利传导至终端用户。'},
      {con: '（二）新型电力系统的核心特征'},
      {con: '新型电力系统的核心是新型，具有鲜明的特征：   '},
      {con: '数字技术赋能形成多网融合。物联网时代的突出特征是机器社交，能源真正的终端用户并不是个体的人，而是各类用能设备，能源网的终极形态一定是用能设备之间互联互通和机器社交。未来能源网将以能源的分布式生产和利用为突出特征，在云大物链等数字化技术驱动下进化成自平衡、自运行、自处理的源网荷一体化智慧能源系统。  '},
      {con: '因此，能源不会单独发展形成孤立的能源网，未来电力基础设施将变成一个平台，数字技术将深化能源网与政务网、社群网的融合互动，实现多网融合、共同发展。   '},
      {con: '用户侧将深度参与电力系统的平衡。受限于新能源的出力特性，灵活性资源将是保障电力系统稳定运行的重要因素，有效挖掘用户侧的灵活性、减少电力系统峰谷差、提高电源利用效率将成为经济可行的重要措施。   '},
      {con: '源网荷储互动将成为新型电力系统运行常态，可中断负荷和虚拟电厂得到普及应用，电力负荷将实现由传统的刚性、纯消费型向柔性、生产与消费兼具型转变。  '},
      {con: '配电网将成为电力发展的主导力量。构建新型电力系统的过程，实际上是一次配电网的革命。传统电力系统通常骨干电网最为坚强，越到电网末端系统越脆弱。但是，新型电力系统中配电网将承担绝大部分系统平衡和安全稳定的责任，绝大多数交易也将在配电网内完成。现有的配电网最终需要在物理层面实现重构，成为电力系统的主导力量。'},
      {con: '电力交易将主导调度体系。未来，新型电力系统将以满足用户的交易需求为主，调度的主要目的是确保用最小的系统成本完成用户交易行为的实施。用户与发电企业的直接交易将成为绝大部分电量的销售模式，灵活性资源也将随着现货市场机制的逐步完善成为核心交易内容，并且大部分交易将在配电网内完成，隔墙售电将成为主要交易方式。 '},
      {con: '（三）新型电力系统的关键技术展望   '},
      {con: '构建新型电力系统是一项系统而长远的工程，离不开科技创新与技术突破。'},
      {con: '一是源网荷储双向互动技术。通过数字化技术赋能，推动“源随荷动”向“源荷互动”转变，实现源网荷储多方资源的智能友好、协同互动。   '},
      {con: '二是虚拟同步发电机技术。通过在新能源并网中加入储能或运行在实时限功率状态，并优化控制方式为系统提供调频、调压、调峰和调相支撑，提升新能源并网友好性。'},
      {con: '三是长周期储能技术。长时储能与大型风光项目的组合将大概率替代传统化石能源，成为基础负载发电厂，对零碳电力系统中后期建设产生深远的影响。   '},
      {con: '四是虚拟电厂技术。源网荷储一体化项目的推广应用，以及分布式能源、微网、储能的快速发展为虚拟电厂提供了丰富的资源，虚拟电厂将成为电力系统平衡的重要组成。'},
      {con: '五是其他技术。新能源直流组网、直流微电网、交直流混联配电网等技术的研发与突破，将有助于实现更高比例的新能源并网，为电力系统的安全稳定运行提供保障。'},
      {tit: '三、新型电力系统建设方向   '},
      {con: '落实碳达峰、碳中和目标，电力行业责任重大，构建以新能源为主体的新型电力系统是时代赋予的责任和使命，这需要电源、电网、用户等产业链各方的共同努力，要以大力发展新能源为基础，以增加系统灵活性资源为保障，推动分布式、微电网与大电网融合发展，构筑坚强电网，加强技术创新和推广应用，实现发电、输配和电力消费系统协同融合、共同发展，助力电力行业率先碳中和。'},
      {url: require('../assets/images/new/28.png')},
      {con: '大力发展新能源。坚持集中式与分布式并举，有序推进三北地区等资源富集区新能源开发以及中东部负荷地区分布式能源建设，加大新能源产业开发力度。   '},
      {con: '同时，推动新能源产业与传统水电、环保、农业等融合发展，构建生态能源体系，推广水风光互补、渔光农光互补、光伏治沙等新业态，探索多能互补、智慧协同的能源生态发展道路。'},
      {con: '增强系统灵活性资源。鼓励新能源项目配置一定规模的煤电、水电、储能等调节性资源，以便通过“新能源+调节性电源”的模式提高新能源出力的稳定性。   '},
      {con: '积极推动具备条件的火电项目进行灵活性改造，努力为系统提供经济可行、规模较大的调节能力。以增加清洁能源消纳、增强调频调峰能力为目标，科学有序发展抽水蓄能、电化学储能项目。加强可调节负荷、虚拟电厂等技术的研究和应用，实现源网荷一体化协同发展。'},
      {con: '推动分布式、微电网与大电网融合发展。加强数字技术应用，通过配电资产深度链接构建基于传统电网物理架构的数字电网。支持分布式可再生能源+储能系统建设，通过就近取材、就地消纳，摆脱对大电网的依赖，形成多个独立微网，各个微电网之间互相备用支撑，实现“绿能”身边取。   '},
      {con: '研究显示，当风电、光伏发电量占比超过30%至40%时，大电网系统的频率、电压、功角稳定极限及高昂的成本决定了其消纳新能源的天花板。因此，分布式、微电网与大电网的融合发展将成为未来电力系统的重要支撑。'},
      {con: '加快技术创新和推广应用。加强新能源功率预测、虚拟同步发电机、柔性直流输电、分布式调相机等技术研发，充分挖掘工业大用户、电动汽车等需求侧响应资源，通过电源、电网以及用户侧技术创新提高新能源消纳利用水平和保障电力系统安全稳定。   '},
      {con: '同时，储能、虚拟电厂和直流微网等技术具有削峰填谷、调频调压作用，是支撑新能源跨越式发展的重要技术手段，建议国家层面统筹谋划，出台相应的顶层设计文件，加强产业引导，加大技术攻关，积极推动相关技术标准的制定，助力行业科学、规范、有序发展。'},
    ],},
    investor: [
      {id: 1, code: '838956', title: '【临时公告】旋荣科技：第三届监事会第三次会议决议公告', time: '2022-08-31', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-08-31/1661938093_017176.pdf'},
      {id: 2, code: '838956', title: '【临时公告】旋荣科技：第三届董事会第三次会议决议公告', time: '2022-08-31', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-08-31/1661938072_684357.pdf'},
      {id: 3, code: '838956', title: '【定期报告】旋荣科技：2022年半年度报告', time: '2022-08-31', link: require('../assets/images/investor/1.png'), classify: 'regular', src: 'https://www.neeq.com.cn/disclosure/2022/2022-08-31/1661938052_707418.pdf'},
      {id: 4, code: '838956', title: '【临时公告】旋荣科技：关于延期披露2022年半年度报告的提示性公告', time: '2022-08-22', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-08-22/1661164895_337970.pdf'},
      {id: 5, code: '838956', title: '【临时公告】旋荣科技：北京大成（上海）律师事务所关于上海旋荣科技股份有限公司2021年年度股东大会法律意见书', time: '2022-05-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-05-20/1653044800_664291.pdf'},
      {id: 6, code: '838956', title: '【临时公告】旋荣科技：公司章程', time: '2022-05-20', link: require('../assets/images/investor/1.png'), classify: '', src: 'https://www.neeq.com.cn/disclosure/2022/2022-05-20/1653044737_350326.pdf'},
      {id: 7, code: '838956', title: '【临时公告】旋荣科技：2021年年度股东大会决议公告', time: '2022-05-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-05-20/1653044720_783590.pdf'},
      {id: 8, code: '838956', title: '【券商公告】旋荣科技：申万宏源承销保荐关于上海旋荣股份有限公司治理专项自查及规范活动的专项核查报告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'broker', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231782_232458.pdf'},
      {id: 9, code: '838956', title: '【临时公告】旋荣科技：关于治理专项自查及规范活动相关情况的报告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231737_814110.pdf'},
      {id: 10, code: '838956', title: '【临时公告】旋荣科技：关于拟修订公司章程公告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231689_045544.pdf'},
      {id: 11, code: '838956', title: '【临时公告】旋荣科技：关于召开2021年年度股东大会通知公告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231601_159827.pdf'},
      {id: 12, code: '838956', title: '【临时公告】旋荣科技：拟续聘会计师事务所公告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231559_939308.pdf'},
      {id: 13, code: '838956', title: '【临时公告】旋荣科技：第三届监事会第二次会议决议公告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231506_102109.pdf'},
      {id: 14, code: '838956', title: '【临时公告】旋荣科技：第三届董事会第二次会议决议公告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231374_281581.pdf'},
      {id: 15, code: '838956', title: '【定期报告】旋荣科技：2021年年度报告摘要', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'regular', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231317_048405.pdf'},
      {id: 16, code: '838956', title: '【定期报告】旋荣科技：2021年年度报告', time: '2022-04-29', link: require('../assets/images/investor/1.png'), classify: 'regular', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-29/1651231318_768477.pdf'},
      {id: 17, code: '838956', title: '【临时公告】旋荣科技：关于变更2021年年度报告披露时间的提示性公告', time: '2022-04-27', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-04-27/1651047320_845069.pdf'},
      {id: 18, code: '838956', title: '【临时公告】旋荣科技：公司章程', time: '2022-02-07', link: require('../assets/images/investor/1.png'), classify: '', src: 'https://www.neeq.com.cn/disclosure/2022/2022-02-07/1644229942_872117.pdf'},
      {id: 19, code: '838956', title: '【临时公告】旋荣科技：第三届监事会第一次会议决议公告', time: '2022-02-07', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-02-07/1644229810_748956.pdf'},
      {id: 20, code: '838956', title: '【临时公告】旋荣科技：第三届董事会第一次决议公告', time: '2022-02-07', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-02-07/1644229703_157928.pdf'},
      {id: 21, code: '838956', title: '【临时公告】旋荣科技：2022年第一次临时股东大会决议公告', time: '2022-02-07', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-02-07/1644229547_519099.pdf'},
      {id: 22, code: '838956', title: '【临时公告】旋荣科技：董事长、监事会主席、高级管理人员换届公告', time: '2022-02-07', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-02-07/1644229389_966885.pdf'},
      {id: 23, code: '838956', title: '【临时公告】旋荣科技：董事、监事换届公告', time: '2022-01-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-01-20/1642677058_379292.pdf'},
      {id: 24, code: '838956', title: '【临时公告】旋荣科技：关于拟变更经营范围并修订公司章程公告', time: '2022-01-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-01-20/1642677032_852227.pdf'},
      {id: 25, code: '838956', title: '【临时公告】旋荣科技：关于召开2022年第一次临时股东大会通知公告', time: '2022-01-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-01-20/1642676629_793219.pdf'},
      {id: 26, code: '838956', title: '【临时公告】旋荣科技：2022年第一次职工代表大会决议公告', time: '2022-01-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-01-20/1642676600_771569.pdf'},
      {id: 27, code: '838956', title: '【临时公告】旋荣科技：第二届监事会第十二次会议决议公告', time: '2022-01-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-01-20/1642676600_771569.pdf'},
      {id: 28, code: '838956', title: '【临时公告】旋荣科技：第二届董事会第二十三次会议决议公告', time: '2022-01-20', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-01-20/1642676523_512294.pdf'},
      {id: 29, code: '838956', title: '【临时公告】旋荣科技：重大项目中标公告', time: '2022-01-06', link: require('../assets/images/investor/1.png'), classify: 'temporary', src: 'https://www.neeq.com.cn/disclosure/2022/2022-01-20/1642677032_852227.pdf'},
    ],
    team1: {
      title: '团队和信念',
      en: 'TEAM & BELIFE',
      img: require('../assets/images/2023/team/页面图_01.png'),
      content: [
        {con: '旋荣致力为员工提供理想的工作环境。公司透过既定的政策及制度聘用人才，并为员工提供定期及多元化的培训及发展机会，培育他们建立理想事业。'},
        {con: '我们提供具竞争力的薪酬及福利，并积极推动各项关爱员工的计划和活动，同时与员工保持定期和坦诚的沟通。'},
        {con: '旋荣的员工是公司最重要的资产，全赖他们的努力和贡献，我们能够为客户提供优质的电力服务。我们重视团队合作，鼓励他们同心为业务贡献专业知识和经验，亦关心员工各方面的需要，鼓励他们参与环保及社会服务工作，积极回馈社会。'},
        {collapse: [
          {tit: '（一）缔造理想工作环境', content: [
            {con: '公司总部目前办公地址位于上海外滩板块，距离外滩的直线距离约500米。'},
            {con: '公司的办公地点的选择原则以城市中心或副中心为主，目的是为了方便员工的出行。'},
            {con: '公司的办公环境都是经过精心的装修后才投入使用，确保工作环境的整洁与环保，使我们有个舒心的办公环境。'},
            {con: '公司努力打造一个轻松的工作氛围，平等的同事关系。工作分工明确，每个人知道自己应该干什么，晋升路径明确；我们提倡人与人之间简单的沟通方法，新员工有老同事带，强调同事之间交流不推诿，不扯皮，让大家开开心心的上班。'},
          ]},
          {tit: '（二）公司福利', content: [
            {con: '1、培训提升，根据公司需要每年对员工进行专业技术知识的培训，全面提高员工的综合素质；'},
            {con: '2、节日礼品，公司每年的中秋节、端午节、春节为员工发放节日礼品，以示慰问；'},
            {con: '3、健康体检，公司为保证员工身体健康，定期组织员工进行健康体检，根据年龄分布情况，	安排员工每一年或二年体验；'},
            {con: '4、夏季防暑，夏季公司为员工发放防暑用品，根据实际岗位情况发放防暑高温补贴；'},
            {con: '5、生日礼物，公司每年举行2次生日大会，上半年和下半年各一次，并发放相应的生日礼物；'},
            {con: '6、公司团建活动，为提高员工及部门之间的相互协作及加深员工之间的认识及沟通能力，每年根据情况，安排员工团建活动；'},
            {con: '7、激励性奖励，关键岗位员工在公司长期工作，或对优秀员工给予奖金鼓励；'},
            {con: '8、社会保险，公司除给员工缴纳社会保险以外，对特殊岗位和关键岗位购买商业保险；'},
            {con: '9、生育慰问，员工生孩子，公司派代表给予其照顾或者看望。'},
          ]},
          {tit: '（三）我们的信念', content: [
            {tit: '企业使命：为电力系统提供全方位的服务。'},
            {con: '唯有热爱电力事业，才能深挖客户的需求，激发自身的潜力，为电力系统的提供各种贴身的服务，创造有价值的产品，才能使我们伴随电力事业的发展而持续成长。'},
            {tit: '为企业客户打造高效的供应链系统和服务。'},
            {con: '唯有充分不断挖掘我们的能力并分享之，为企业客户的供应链赋能，提高全社会供应链的效能，才能使我们伴随客户的成长而持续成长。'},
            {tit: '企业核心价值观：诚信、平等、奋斗、务实、创新。'},
            {con: '诚信：我们只有内心坦荡诚恳，才能言出必行，信守承诺。诚信是我们最重要的无形资产，我们坚持对外以诚信赢得客户，对内以诚信增强企业凝聚力。'},
            {con: '平等：在平时的工作和生活中，要不偏颇的对待别人，不因为别人的财富、容貌、技能、岗位依附或贬低他人，不要去欺负和刁难有求于与你的人，总之：己所不欲勿施于人。需要加强团队合作，共同为公司的目标努力。'},
            {con: '奋斗：唯有艰苦奋斗才能赢得客户的尊重与信赖。奋斗体现在为客户创造价值的任何微小活动中，以及在劳动的准备过程中为充实提高自己而做的努力。我们坚持以奋斗者为本，使奋斗者得到合理的回报。'},
            {con: '务实：尽职尽责，真抓实干，坚持目标导向和结果导向。遵纪守法，正派做人，干净做事，靠专业优势和成果业绩赢得生存发展。不要去做炫耀客户关系，财富等满足个人虚荣心的事情。'},
            {con: '创新：积极进取、勇于开拓，坚持开放与创新。任何先进的技术、产品、解决方案和业务管理，只有转化为商业成功才能产生价值。坚持客户需求导向，并围绕客户需求持续创新。提倡公司制度的创新，公司中的任何人不得贬低和不公正的对待向公司提意见和建议的同事和合作伙伴。'},
            {tit: '企业使命和价值观的作用：'},
            {con: '企业使命和价值观是公司发展的大原则，不仅用于指导我们现有业务的发展，更重要的用于处理我们未经过的事务。我们目前正在不断创新和快速发展中，每当我们遇到之前没有遇见的业务或者事件时，我们都需要审视我们的企业使命和价值观再做决定。'},
          ]},
          {tit: '（四）我们的团队', content: [
            {tit: '诚信及廉洁'},
            {con: '内外相应，言行相称。'},
            {con: '根深不惧风吹，行正何惧人言。'},
            {con: '公司自上而下都在饯行诚信和廉洁的工作态度和处事原则，形成良好的工作氛围。'},
            {con: '同时，公司业务的全流程都在助力飞中进行管理，所有工作均留有痕迹，以备后台数据中心进行分析，及时发现问题，解决问题，杜绝隐患。'},
            {tit: '群策群力'},
            {con: '我们强调发挥每一位员工的能力，赋予员工各自司职职务的建议权，在遇到问题时，我们并不以职级的高低来做唯一标准，而是需要讲道理，谁有道理听谁的。'},
            {con: '公司各职级的员工是平等的，只是分工不同。在每个项目成立时，选择项目组成员时，项目组长可以是基层员工，公司总经理或其他高管可以为组员，领受各自的任务，在这个项目的工作安排时，无特殊情况，总经理也需要按照作为项目组长的基层员工的安排。'},
            {con: '企业往往生于谦逊，止于傲慢，无论是对客户，对同事，对工作，我们都要戒骄戒躁。在开展工作之前，需要经过充分的讨论，制定方案后方可执行。'},
            {tit: '员工数据'},
            {tit: '（1）员工年龄分布'},
            {table: {
              header: [{label: '年龄分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['30岁以下', 27.5, '27.5%'],
                ['31-40岁', 30, '30%'],
                ['41-50岁', 30, '30%'],
                ['51岁以上', 12.5, '12.5%'],
              ]
            }},
            {tit: '（2）员工学历分布'},
            {table: {
              header: [{label: '学历分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['中专', 15, '15%'],
                ['大专', 35, '35%'],
                ['本科', 37.5, '37.5%'],
                ['硕士及以上', 12.5, '12.5%'],
              ]
            }},
            {tit: '（3）员工性别分布'},
            {table: {
              header: [{label: '员工性别分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['男', 55, '55%'],
                ['女', 45, '45%'],
              ]
            }},
            {tit: '（4）高级管理人员性别分布'},
            {table: {
              header: [{label: '高级管理人员男女比例: (3.5 : 1)', width: 240, align: 'center'}, {label: '',},],
              data: [
                ['男', 17.5,],
                ['女', 5, ],
              ]
            }},
            {tit: '（5）员工司龄公布'},
            {table: {
              header: [{label: '员工司龄分布', width: 160, align: 'center'}, {label: '',}, {label: '人数比例', width: 120, align: 'center'},],
              data: [
                ['1年以下（包含）', 10, '10%'],
                ['1-2年（包含）', 7.5, '7.5%'],
                ['2-4年（包含）', 25, '25%'],
                ['4-7年（包含）', 42.5, '42.5%'],
                ['7年以上', 15, '15%'],
              ]
            }},
          ]},
        ]}
      ]
    },
    team2: {
      title: '加入旋荣',
      en: 'JOIN REGLORY',
      img: require('../assets/images/2023/team/页面图_02.png'),
      contentTitle: '招聘岗位：',
      content: [{
        content: [
          {tit: '高级.NET开发工程师 '},
          {tit: '岗位职责：'},
          {con: '1、 基于.Net平台B/S系统设计、开发；'},
          {con: '2、 参与项目的系统架构设计；'},
          {tit: '任职要求：'},
          {con: '1、 计算机或相关专业专科以上学历，2年以上从事软件设计和开发工作；'},
          {con: '2、 熟练 后端开发技术，以及Web API / Web Service/Socket等通信技术，并对以上相关技术原理有深入了解；'},
          {con: '3、 熟悉SQLServer、Mysql数据库，熟练使用SQL语言ADO.NET, LINQ To SQL, Entity Framework(一种或多种)，对其性能，工作原理等有深入了解，掌握存储过程；'},
          {con: '4、精通网站开发中常见的安全漏洞及避免方法，以及程序的性能调优，能够开发出高效、安全、稳定的系统；'},
          {con: '5、熟悉Windows操作系统和.NET框架，有实际B/S应用开发经验； 有大型系统架构经验；'},
          {con: '6、深入理解面向对象思想，并能够灵活运用于程序开发中，具有良好的编程风格，有多层结构的开发经验，对重用，重构有丰富的经验者更佳；'},
          {con: '7、 有供应链平台开发经验者优先；'},
          {con: '8、 善于沟通，具有敬业精神，有团队意识、学习能力强；                                 '},
          {con: '9、有较强的开发能力，对信息技术领域的新信息、新知识具有相当的敏感性，能够迅速更新自身的知识内容。'},
        ],
        img: require('../assets/images/2023/team/页面图_02.png'),
      },{
        content: [
          {tit: 'Java高级开发工程师 '},
          {tit: '岗位职责：'},
          {con: '1、负责公司平台项目的业务开发工作；'},
          {con: '2、负责完成单元、联调等测试问题的修复，确保安全、质量和性能；'},
          {con: '3、参与内部测试、部署、实施等工作；'},
          {con: '4、分析并解决软件开发过程中的问题。'},
          {tit: '任职要求：'},
          {tit: '1、计算机或相关专业，两年以上java开发经验；'},
          {con: '2、熟练运用 Java进行开发。熟悉 Java 常用类库，对类加载机制、GC、多线程有一定的了解；'},
          {con: '3、熟练运用 Spring 、Spring Boot、 MyBatis 等框架进行业务接口开发；'},
          {con: '4、熟练使用 MySQL以及进行 SQL 调优；'},
          {con: '5、有 Spring Boot 、Spring Cloud实际开发经验者优先；'},
          {con: '6、良好的逻辑思维能力，熟悉业务抽象和数据模型设计。能够独立负责系统中单个模块的设计与开发，具有较强的分析问题和独立解决问题的能力；'},
          {con: '7、责任心强，有良好的团队意识和团队合作精神，有较强的沟通能力；'},
        ],
        img: require('../assets/images/2023/team/Java高级开发工程师.png'),
      },{
        content: [
          {tit: 'Android开发工程师 '},
          {tit: '岗位职责：'},
          {con: '1、负责Android客户端应用的设计研发；'},
          {con: '2、参与移动端高扩展性架构设计，与后台开发及设计人员协作优化和改进移动端的性能及使用体验；'},
          {con: '3、与产品和运营人员沟通保证产品的质量和开发进度；'},
          {con: '4、持续的优化相关的产品的质量、性能、用户体验，进行Andriod移动端的适配与兼容调试；'},
          {con: '5、按照开发设计说明书编写相应模块的设计文档；'},
          {tit: '任职要求：'},
          {con: '1、学历专业要求：计算机、通信、电子信息技术及其相关专业本科以上学历；'},
          {con: '2、工作经历要求：2年以上Android应用开发经验；'},
          {con: '3、知识技能要求：'},
          {con: '1）熟悉Android移动终端开发技术；'},
          {con: '2）有扎实的Java语言基础；'},
          {con: '3）熟练掌握Android各种UI布局和控件，能够熟练自定义UI控件；'},
          {con: '4）熟练掌握HTTP及Socket编程；'},
          {con: '5）对Android程序性能优化、内存优化有一定的经验；'},
          {con: '6）有良好的代码习惯，要求结构清晰、命名规范、逻辑性强、代码冗余率低，代码注释清晰；'},
          {con: '4、素质性格要求：在规定时间内高效完成任务并具备良好的团队合作精神，可承受一定的工作压力；具有强烈的进取心和求知欲望，善于学习和运用新知识，乐于解决具有挑战性的问题。'},
        ],
        img: require('../assets/images/2023/team/Android开发工程师.png'),
      },{
        content: [
          {tit: '电气工程师 / 电气设计工程师 '},
          {tit: '岗位职责：'},
          {con: '1.独立完成电气技术方案的编制，必要时需与客户进行技术洽谈；'},
          {con: '2.与公司产品部协作完成新产品、新项目前期的方案论证；'},
          {con: '3.负责项目电气系统的设计、硬件选型、调试等工作，并在项目执行过程中提供支持；'},
          {con: '4.负责协助公司组建配网及新能源业务事业部；'},
          {con: '5.完成领导安排的其余工作。'},
          {tit: '任职要求：'},
          {con: '1、大学本科及以上学历，电气或自动化专业优先；'},
          {con: '2、具有PCB及嵌入式开发的经验，了解并有一定PCB原理图设计能力；'},
          {con: '3、熟悉断路器，智能断路器的工作原理；'},
          {con: '4、熟悉lora，wifi，RJ45，485，交换机等常用的通信模块；'},
          {con: '5、熟悉气体、温度、湿度、火灾等传感器模块；'},
          {con: '6、具备自主控制系统搭建的能力，如选择主控板，CPU，传感器模块，通讯模块，断路器等模块，根据业务的需求进行集成，并提供接口，协助产品部同事进行高级应用的开发；'},
          {con: '7、熟悉配网自动化系统者，或具有服务国网公司，南网公司经验者优先；'},
          {con: '8、工作认真细致、责任心强；具有良好的敬业精神、团队协作精神，较好的沟通能力和服务意识。'},
        ],
        img: require('../assets/images/2023/team/电气工程师  电气设计工程师.png'),
      },{
        content: [
          {tit: '技术支持工程师（售前售后）'},
          {tit: '岗位职责：'},
          {con: '1、 积极主动的完成分派的项目各阶段技术支持工作，包括需求沟通、计划制定、环境准备、软硬件实施和调试、问题分析和处理等；'},
          {con: '2、 熟练掌握公司产品，独立完成产品实施和维护工作，积极响应并解决客户在产品应用过程中遇到的疑问及技术要求；'},
          {con: '3、 协助领导参与客户业务交流，挖掘客户需求并根据需求撰写可行性解决方案、方案讲解和演示等；'},
          {con: '4、 参与公司产品各类文档的编写和优化等工作；'},
          {con: '5、 凡工作期间表现优异者，岗位发展方向包含；高级技术支持工程师、项目经理、资深售前顾问、销售等。'},
          {tit: '任职要求：'},
          {con: '1、专科及以上学历，计算机相关专业优先，2年以上相关工作经验；'},
          {con: '2、熟悉Linux和Windows操作系统及系统常用命令和工具，熟悉TCP/IP协议、C/S和B/S架构应用系统原理及其网络通信原理；'},
          {con: '3、对网络设备、安全设备等知识有所了解，熟悉路由器、交换机、防火墙、VPN、负载均衡等设备的配置及工作原理；'},
          {con: '4、具有良好的主动学习意识和学习能力，具备良好的文案编写能力，熟练编写PPT及有演讲能力者优先；'},
          {con: '5、具有良好的团队精神和合作意识，较强的沟通交流和组织协调能力，有处理问题的耐心，应变能力强；'},
          {con: '6、有强烈的进取精神、事业心、责任心。能承受一定的工作压力。 '},
        ],
        img: require('../assets/images/2023/team/技术支持工程师（售前售后）.png'),
      },{
        content: [
          {tit: '项目助理 '},
          {tit: '岗位职责：'},
          {con: '1、在项目经理的指导下完成项目的开发和履约工作；'},
          {con: '2、为项目经理的正常开展提供全方位的支持，包括且不限于做项目方案，报项目，项目投标，项目报价，工程进度跟踪，工程材料采购和结算等工作；'},
          {con: '3、完成项目经理和公司领导交给的其他事务；'},
          {con: '4、需要在公司自主开发的助力飞系统中完成整个项目管理的流程；'},
          {tit: '任职要求：'},
          {con: '1、本科以上学历，理工科专业优先，优秀应届生亦可；'},
          {con: '2、有电网客户经验、电力工程、电力装置设计方面经验的人员优先；'},
          {con: '3、责任心强，执行力强，良好的沟通能力和团队合作精神；'},
          {con: '4、事业心强，能全身心投入工作，该职位不排除有适当的商务应酬，需要具备一定的抗压能力。'},
        ],
        img: require('../assets/images/2023/team/项目经理.png'),
      },{
        content: [
          {tit: '总经办助理（兼项目经理）'},
          {tit: '岗位职责：'},
          {con: '1、协助总经办开展各项事务，处理日常事务；'},
          {con: '2、协助总经办成员完成项目节点管理，项目进度推进，项目现场管理等相关工作；'},
          {con: '3、协助安排总经办成员日常工作行程、以及必要的商务对接和应酬工作；'},
          {con: '4、需根据总经办成员的安排，完成部分销售业务的跟单工作；'},
          {con: '5、领导交办的其他工作事项。'},
          {tit: '任职要求：'},
          {con: '1、本科以上学历，理工科专业优先，优秀应届生亦可；'},
          {con: '2、工作细心负责，主观能动性强；'},
          {con: '3、事业心强，能全身心投入工作，具备抗压能力；'},
          {con: '4、该岗位为公司的重要岗位，公司将提供全面的培训和职业规划，欢迎有稳定的工作状态和长期职业规划的优秀年轻人前来报道。'},
          {tit: '薪资福利及晋升路径：'},
          {con: '1、根据公司的业务情况发奖金；'},
          {con: '2、根据个人的业绩完成情况发奖金；'},
          {con: '3、晋升路径：项目经理，大客户经理，项目总监，副总经理，总经理。'},
        ],
        img: require('../assets/images/2023/team/总经办助理（兼项目经理）.png'),
      },{
        content: [
          {tit: 'Web前端工程师'},
          {tit: '岗位职责：'},
          {con: '1、积极主动的完成分派的项目各阶段技术支持工作，包括需求沟通、计划制定、环境准备、软硬件实施和调试、问题分析和处理等；'},
          {con: '2、熟练掌握公司产品，独立完成产品实施和维护工作，积极响应并解决客户在产品应用过程中遇到的疑问及技术要求；'},
          {con: '3、协助领导参与客户业务交流，挖掘客户需求并根据需求撰写可行性解决方案、方案讲解和演示等；'},
          {con: '4、参与公司产品各类文档的编写和优化等工作；'},
          {tit: '任职要求：'},
          {con: '1、专科及以上学历，计算机相关专业优先，2年以上相关工作经验；'},
          {con: '2、掌握HTML5、CSS3、JavaScript、Ajax，熟悉页面架构和布局，对Web标准和标签语义化有深入理解;'},
          {con: '3、具有良好的主动学习意识和学习能力，具备良好的文案编写能力，熟练编写PPT及有演讲能力者优先；'},
          {con: '4、具有良好的团队精神和合作意识，较强的沟通交流和组织协调能力，有处理问题的耐心，应变能力强；'},
          {con: '5、有强烈的进取精神、事业心、责任心。能承受一定的工作压力。'},
        ],
        img: require('../assets/images/2023/team/Web前端工程师.png'),
      },{
        content: [
          {tit: '大客户销售经理（电力）'},
          {tit: '岗位职责：'},
          {con: '1、在上级的领导和监督下定期完成量化的工作要求，并能独立处理和解决所负责的任务；'},
          {con: '2、维护客户关系，完成销售任务；'},
          {con: '3、了解和发掘客户需求及购买愿望；'},
          {con: '4、为客户提供专业的咨询；'},
          {con: '5、完成公司领导指定的其他任务。'},
          {tit: '任职要求：'},
          {con: '1、专科及以上学历，市场营销、机电、EE等相关专业优先；'},
          {con: '2、1年以上销售行业工作经验，具备电力行业（国家电网）、政府采购行业经验且业绩突出者优先；'},
          {con: '3、责任心强，具有较强的沟通能力及交际技巧，具有亲和力；'},
          {con: '4、具备一定的市场分析及判断能力，良好的客户服务意识；'},
          {con: '5、有责任心，能承受工作压力，有毅力，且有长久职业规划；'},
          {con: '6、工作将有适当的应酬，会驾驶者优先考虑；'},
          {con: '公司有稳定的大客户群和良好的培训机制，可以给到销售人员足够的成长空间。希望我们的销售人员对公司的价值和优势有长期的认同，能够伴随公司一起成长和进步。'},
        ],
        img: require('../assets/images/2023/team/大客户销售经理（电力）.png'),
      },{
        content: [
          {tit: '采购助理 '},
          {tit: '岗位职责：'},
          {con: '1、为采购寻源的岗位，负责选定供应商，约定商务条件，开发新的供应商；'},
          {con: '2、负责执行公司的采购计划；'},
          {con: '3、负责询价、比价，不断降低公司的采购成本；'},
          {con: '4、处理与经销商的合同、发票及付款等相关商务条件；'},
          {con: '5、完成经理交给的其他事务。'},
          {tit: '任职要求：'},
          {con: '1、大学本科以上学历，专业不限，具备三年以上采购经验；'},
          {con: '2、具备仪器仪表、工器具、办公用品，商超行业等MRO产品的采购经验；'},
          {con: '3、从事过网上电子商务或有电子行业从业经验者优先；'},
          {con: '4、具备较强的观察力和应变能力，计划能力、语言表达能力强，极强的责任心，良好的团队合作精神，优秀的学习能力。'},
        ],
        img: require('../assets/images/2023/team/采购助理.png'),
      },{
        content: [
          {tit: '采购经理 '},
          {tit: '岗位职责：'},
          {con: '1、负责采购部门的制度建设及工作流程与体系建设；'},
          {con: '2、开发、评审、管理供应商，建立供应商管理体系；'},
          {con: '3、为采购寻源的岗位，负责选定供应商，约定商务条件，开发新的供应商；'},
          {con: '4、负责执行公司的采购计划；'},
          {con: '5、负责询价、比价，不断降低公司的采购成本；'},
          {con: '6、处理与经销商的合同、发票及付款等相关商务条件；'},
          {con: '7、完成 其他事务。'},
          {tit: '任职要求：'},
          {con: '1、大学本科以上学历，专业不限，具备3年以上采购经验及管理经验；'},
          {con: '2、具备仪器仪表、工器具、办公用品，商超行业等MRO产品的采购经验；'},
          {con: '3、从事过网上电子商务或有电子行业从业经验者优先；'},
          {con: '4、具备较强的观察力和应变能力，计划能力和管理能力，语言表达能力强，极强的责任心，良好的团队合作精神，优秀的学习能力。'},
        ],
        img: require('../assets/images/2023/team/采购经理.png'),
      },{
        content: [
          {tit: '销售助理'},
          {tit: '岗位职责：'},
          {con: '1.跟客户就收货要求、产品咨询，交期等订单履约方面的详细细节进行沟通；'},
          {con: '2.登录客户供应商平台进行订单履约过程的操作（包括上架，线上合同签订，打印签收单，签收单线上签章等；'},
          {con: '3.通过公司内部助力飞系统进行下单；'},
          {con: '4.督导订单的履约执行完成情况，协助销售外勤掌握订单履约过程；'},
          {con: '5.跟采购沟通产品询价细节，送货方式，到货情况；'},
          {con: '6.报价单制作，合同签订，履约跟踪并登记；'},
          {con: '7.业绩统计，毛利表统计汇总；'},
          {con: '8.完成上级领导交给的其他事务。'},
          {tit: '任职要求：'},
          {con: '1.具有解决复杂问题的能力，较强的计划性和执行力；'},
          {con: '2.熟悉office等办公软件；'},
          {con: '3.有良好的沟通能力，做事仔细认真；'},
          {con: '4、有相关工作经验优先考虑；'},
          {con: '5、能接受部分的外勤工作优先考虑。'},
        ],
        img: require('../assets/images/2023/team/销售经理.png'),
      },{
        content: [
          {tit: '投标专员'},
          {tit: '岗位职责：'},
          {con: '1、熟悉招投标流程，掌握招投标的组织、编制等相关程序和内容；'},
          {con: '2、安排标书制作计划，招投标信息的收集，投标文件的制作及标书中涉及到的相应工作；'},
          {con: '3、根据招标文件的要求，负责标书的编写、排版等工作，制作投标文件；'},
          {con: '4、对标书、合同技术规格进行审核、确认；'},
          {con: '5、负责对招投标资料和客户信息资料进行整理，确保档案的准确性、完整性和保密性；'},
          {con: '6、完成公司或部门安排的其他各项工作。'},
          {tit: '任职要求：'},
          {con: '1、大专及以上学历，；'},
          {con: '2、熟练操作办公软件，office校对能力强；'},
          {con: '3、具有良好的沟通应变能力，富有责任心，为人处事耐心细心。'},
        ],
        img: require('../assets/images/2023/team/投标专员.png'),
      },{
        content: [
          {tit: '投标助理 商务助理 （文职岗位、无需出差）'},
          {tit: '岗位职责：'},
          {con: '一、标书的制作'},
          {con: '1、认真阅读招标文件要求，根据招标文件要求，收集制作招标文件需要的技术和商务资料并办理办理报名及保证金交纳等手续；'},
          {con: '2、协助招标经理按时完成投标文件的制作、密封与提供投标现场需要的资料；'},
          {con: '3、配合投标经理完成投标文件制作完成后，对新收集到的技术资料和商务资料应将原件保存在标书部的共享磁盘内，供日后查询和使用；'},
          {con: '4、定期检查商务资料和技术资料的时效性，对超过有效期的资料和文件应及时删除修改，将制作后的投标文件副本进行归档供查询；'},
          {con: '5、投标信息结果查询，当值信息员应对当天开标的信息结果进行查询，确定信息中标单位及中标金额等重要信息，记录归档供日后查询；'},
          {con: '6、搜集掌握全国各地相关产品的招标信息，判断是否适合公司投标，并汇报部门负责人处理，提交信息时应提供初步处理情况；'},
          {con: '7、协助销售后勤工作。'},
          {tit: '任职要求：'},
          {con: '1、熟练使用Office、WPS办公软件，有较强的文字功底，具备良好敬业、合作精神；'},
          {con: '2、认同公司文化，具备较强的责任心，品行端正；'},
          {con: '3、大专以上的学历。'},
        ],
        img: require('../assets/images/2023/team/投标助理.png'),
      },{
        content: [
          {tit: '平面设计'},
          {tit: '岗位职责：'},
          {con: '1、根据业务需求，完成创意设计工作；'},
          {con: '2、做好公司产品的包装设计、公司海报设计、印刷排版设计等等；'},
          {con: '3、配合业务部门进行封面设计等相关工作；'},
          {con: '4、配合公司董秘完成公司宣发材料的设计工作；'},
          {con: '5、配合公司相关部门完成公司的首页、公众号、商城的设计改版等方面的工作；'},
          {con: '6、完成领导安排的其它工作。'},
          {tit: '任职要求：'},
          {con: '1、1年以上相关工作经验；'},
          {con: '2、熟练使用AI、PS、ID等设计软件；'},
          {con: '3、学习能力强，思维活跃，创意新颖；'},
          {con: '4、具备良好的团队合作精神及沟通能力 ；'},
          {con: '5、吃苦耐劳，踏实肯干。'},
          {con: '做五休二，早九晚六，工作轻松、比较稳定，五险一金，节日福利，员工体检，年终奖金'},
        ],
        img: require('../assets/images/2023/team/平面设计.png'),
      },{
        content: [
          {tit: '综合文员（南京）'},
          {tit: '岗位职责：'},
          {con: '1、公司基础岗位，主要负责平台商品管理；'},
          {con: '2、协助采购部门部分工作；'},
          {con: '3、工作涉及文档打印、复印、快递方面等的工作；'},
          {con: '4、配合和执行上海总部部分工作；'},
          {con: '5、领导安排的其他工作内容。'},
          {tit: '任职要求：'},
          {con: '1、大专及以上学历，年龄20-35岁；'},
          {con: '2、认同公司的价值理念，能服从领导安排，工作认真负责，做事有条理；'},
          {con: '3、踏实肯干、吃苦耐劳，有扎实的专业基础和一定的创新性和灵活性；'},
          {con: '4、熟练使用office 办公软件，有相关工作经验者优先录用；'},
          {con: '5、能够接受部分外勤工作。'},
        ],
        img: require('../assets/images/2023/team/综合文员.png'),
      },{
        content: [
          {tit: '司机 '},
          {tit: '岗位职责：'},
          {con: '1、熟悉市区的路线，掌握车辆的技术性能，精通配送业务，遵守交通法规；'},
          {con: '2、负责取货送货，搬运，分货打包，随车商品须认真清点，核对；'},
          {con: '3、态度端正，责任心强，能吃苦耐劳，有较强的团队合作意识；'},
          {con: '4、负责按时对汽车进行维修、清洗，办理车辆有关手续；'},
          {con: '5、3年以上驾龄，C照；'},
          {con: '6、完成领导临时交办的其它工作。'},
          {tit: '任职要求：'},
          {con: '1、年龄18-55岁；'},
          {con: '2、责任心强，诚信、细致，有较强的团队合作意识；'},
          {con: '3、有物流行业经验优先考虑；'},
          {con: '4、公司离东昌路地铁站比较近，家住在附近或者人民路隧道、东昌路与浦东南路交叉口附近优先考虑；'},
        ],
        img: require('../assets/images/2023/team/司机.png'),
      },],
    },
    team3: {
      title: '职业规划和发展',
      en: 'PLANNING & DEVELOPMENT',
      img: require('../assets/images/2023/team/页面图_05.png'),
      content: [
        {tit: '应届生培养计划：'},
        {con: '1、岗前培训：入岗前进行3天培训，包括宣导企业文化及相关制度、学习专业知识及沟通技巧、了解岗位专业技能知识及工作范围等；'},
        {con: '2、培训考核：培训结束后，针对培训内容进行考核；'},
        {con: '3、正式上岗：考核合格的人员将安排正式上岗；'},
        {con: '4、师徒培养：建立师徒制度，让应届生更快的适应工作岗位及工作环境。'},
        {tit: '岗位晋升计划：'},
        {con: '1、岗位晋升的基本原则'},
        {con: 'A.以公平、公正、公开、为基本原则；'},
        {con: 'B.以综合素质和专业技能来综合评定晋升的标准；'},
        {con: '2、岗位晋级分类'},
        {con: 'A.职务任命：根据公司的实际发展及工作需求，任命员工担任某一职务；'},
        {con: 'B.推荐上岗：员工通过内部推荐，从一个岗位转到另一个岗位；'},
        {tit: '3、岗位晋级的条件'},
        {con: 'A.员工入职满一年，试用达标，转正合格，无违纪行为；'},
        {con: 'B.被公司或者部门通报，实行一票否决制，一年内不能晋级；'},
        {con: 'C.理论、实操、综合评价考核成绩作为晋级重要依据；'},
        {con: 'D.对员工绩效进行考评，以评选结果作为晋升的依据；'},
        {con: 'E.将员工绩效结果、评选依据记入员工档案中，以备后续进行核实。'},
      ]
    },
    team4: {
      title: '定期培训',
      en: 'PERIODICAL TRAINING',
      img: require('../assets/images/2023/team/页面图_06.png'),
      content: [
        {tit: '（1）管理人员的培训：'},
        {con: '对高层管理人员的培训，以带好队伍、抓好生产经营为培训主题，提高管理人员的决策、指挥、协调、经营等各方面的能力。'},
        {tit: '（2）专业技术人员的培训：'},
        {con: '结合公司技术特点及重点，针对专业技术人员进行培训，提高其专业技术能力。'},
        {tit: '（3）对新进员工的培训：'},
        {con: '对新进人员的培训重点是帮助新进员工尽快适应岗位、新环境；对新进员工进行企业规章制度、工作岗位职责、企业文化等相关内容的培训；'},
        {tit: '（4）增加公司其他的培训投入：'},
        {con: '如增加以下管理技能的培训（言语沟通、时间规划和压力管理等）。'},
      ]
    },
    team5: {
      title: '员工关系和健康',
      en: 'EMPLOYEE RELATIONS & HEALTH',
      img: require('../assets/images/2023/team/页面图_07.png'),
      content: [
        {tit: '1、员工关爱机制'},
        {con: '关注员工身体健康，每年定期安排一次员工体检项目。'},
        {tit: '2、员工沟通机制'},
        {con: '设置员工意见箱，满意度调查机制或定期举行员工沟通会，员工可自由反馈疑问或工作中遇到的问题，公司高层保持与员工的有效沟通，及时了解员工的需求及建议。'},
        {tit: '3、康乐活动机制'},
        {con: '每年不定期举办员工生日会，团建、户外拓展活动等。'},
        {tit: '4、员工心理辅导'},
        {con: '行政部不定期开展员工心理疏导座谈会，及时了解员工心理需求，帮助员工增强心理素质、提升员工对公司的归属感。'},
      ]
    },
    develop1: {title: '通向可持续发展之路', en: 'SUSTAINABLE DEVELOPMENT', img: require('../assets/images/2023/develop/配图_1.png'), content: [
      {con: '我们认为企业的生存、成长和发展都应该遵循客观规律，我们更愿意将企业视为一个活着的生命体，他应该充满热情和热爱，具有勇气意志和信心，秉承良好的品德和信誉，为社会创造价值。'},
      {con: '我们将通过制定正确的经营战略、不断增强企业的核心竞争力、以及培育优秀的企业文化来实现企业的持续发展。'},
      {tit: '（一）制定正确的经营战略'},
      {con: '企业的经营战略就是企业的发展方向，方向的正确与否，决定着企业的生死存亡。战略正确就能做到步步领先，占领优势，并使其发挥到极致。相反，战略错误将处处被动，甚至会付出被淘汰出局的惨重代价。'},
      {con: '我们经营战略核心在于用技术优势和个性化服务能力的构建属于我们的市场壁垒，并努力开创新的市场；同时我们的经营一定要保持审慎的毛利，切不可以因占有优势地位而扩大毛利，以此来保持警惕和对市场的敬畏。'},
      {tit: '（二）增强企业的核心竞争力'},
      {con: '战略确定固然重要，但更重要的是如何组织企业资源，分阶段、分步骤的进行战略实施。如果没有一个好的战略，再好的战术也会变得没有意义，而有了好的战略，在实施中战术跟不上，也会毁掉一个卓越的战略构想。战略是企业决策层的职能，而战略的组织实施就属于企业管理层的事了，管理人员如何采取各种战术措施确保战略的实现，其中心工作就是要不断增强企业的核心竞争力。'},
      {con: '企业核心竞争力是企业经营理念、经营机制、企业资源和企业文化等因素的有机结合体，是企业综合实力的表现，是偷不去、买不来、带不走的企业所特有的能力。我们会在以下几点维护好我们的核心竞争力：'},
      {con: '1.实施人才开发战略，建设一支知识结构合理、符合市场和企业发展要求的技术、管理人才队伍。'},
      {con: '关于我们的人才梯队的建设：对外我们将通过各种渠道招贤纳士，广泛吸引人才。对内，我们将采取不同形式，结合公司发展需要，大力培养人才。我们务必要做到知人善任，用其所长，避其所短，做到“贤者居上，智者居侧，能者居前”。'},
      {con: '2.实施技术开发战略，提高技术、装备水平，增强市场竞争能力，'},
      {con: '在当今科学技术发展突飞猛进、日新月异的时代，一个企业的技术储备和人才储备决定了这个企业能否可持续发展的程度。对此，要增强科技创新意识，提高对科技的重视程度，加大对科技的投入，建立有效的技术开发运行机制，采用CMMi 3级以上的标准严格的进行项目开发管理，确保技术研发的效率和质量。'},
      {con: '3.实施合理成本战略，走精细管理之路，提高企业的整体管理水平'},
      {con: '企业的最终目的是争取效益的最大化，企业生存与发展的根本取决于它的赢利能力。降本增效是企业永恒的主题，在实际操作运行过程中，尽管方法不同，但归纳起来，不外乎两方面的内容，一是依靠科技降低成本；二是依靠管理降低成本。'},
      {con: '在科技降本方面，一是通过科技进步，应用先进的生产技术，改进生产工艺，提高劳动生产率，达到直接降低生产成本的目的：二是通过科研攻关，克服生产工艺过程中存在的各种复杂问题，提高产品质量，避免操作事故，从而减少损失，实现间接降低成本的目的。'},
      {con: '在管理降本方面，一是加强成本费用定额管理，结合实际生产，实施“成本倒逼”，制定科学、先进、合理、可行的内部费用消耗定额，建立有效的成本控制机制、予警机制、考核激励机制，完善成本管理制度，加大奖惩幅度，确保成本的有效控制；二是加强资金管理，进一步完善资金管理制度，调整和细化资金集中管理环节，提高资金使用的整体效果。加强资金的内部控制，建立以财务为中心的成本决策、控制体系和成本分析制度，制定严格的经济活动管理规定和项目投资审批程序，做到权限清楚，责任分明；三是大力推行管理创新工作，增强管理创新意识，树立只有不断创新，我们才能持续发展的观念。我们已进入无边界的竞争时代，那种凝固不变的常规管理模式已远远不能适应，必将被新型的管理方式所取代。'},
      {con: '与高校或研究机构的资深研究人员或学院紧密联系，获取更为前沿的信息和技术，积极参与高校的产学研方面的合作。高校的研发机构数量庞大，种类繁多，有其一定的优势，我们可以取其精华，为我所用，同时又把市场的需求告知高校，助力其研发的成果更好的落地，为科研成果转化提高效率。'},
      {tit: '（三）培育优秀的企业文化'},
      {con: '企业文化是根植于企业本身土壤的独特文化和制度，是企业核心竞争力的重要因素。'},
      {con: '优秀的企业文化一旦形成，就能将企业的追求和社会公德、是非标准等融化在全体职工的思想和行动之中，使企业目标与个人目标达到高度契合，人人都自觉地把追求企业目标当作追求个人目标，从而，职工产生极强的向心力，企业形成极强的凝聚力。因此，要以战略的眼光，重视企业文化，大力加强新形式下企业文化建设。'},
      {con: '企业文化建设是一项复杂的“人本”工程，要从长计仪，有计划、按步骤地从基础工作抓起，树立正确的经营理念，培养职工树立正确的价值观，把企业精神、经营观念和被全体职工认同的价值观、行为准则有机地结合在一起，形成克服困难，促进发展的强大合力，充分发挥企业文化在市场竞争中内强素质、外树形象的重要作用。'},
    ],},
    develop2: {title: '注重可持续发展', en: 'FOUCUS ON SUSTAINABLE DEVELOPMENT', img: require('../assets/images/2023/develop/配图_2.png'), content: [
      {con: '旋荣的发展凝聚了我们全员的心血，我们希望他能够长久且健康的存续，因此我们的十分注重企业的可持续发展。'},
      {con: '旋荣的可持续发展不仅需要我们细心的打理和经营，更重要的是需要秉承一些基本原则：'},
      {con: '（一）坚持诚信、平等、奋斗、务实、创新的企业文化；'},
      {con: '（二）坚决不做有损公司的声誉和信誉的事情，不为短期利益所动；'},
      {con: '（三）坚持以人为本的重要原则，聆听员工诉求，解决员工问题，让员工能够充分发挥主观能动性；'},
      {con: '（四）坚决反对内耗，搞小团体，对事不对人。我们提倡互帮互助，对事不对人，公司内部有事直接说，不用绕弯子，凡是讲个理，并不以职位高低为决策的唯一标准。'},
    ],},
    develop3: {title: '构建可持续的组织', en: 'BUILD SUSTAINABLE ORGANIZATION', img: require('../assets/images/2023/develop/配图_3.png'), content: [
      {con: '公司的传承是构建可持续发展组织的目的。我们将有两大原则：'},
      {con: '（一）开展员工持股计划，只要满足一定条件的员工均能参与；'},
      {con: '（二）公司对新进的年轻员工，尤其是应届生均进行师徒制的培养；'},
      {con: '（三）公司开展梯队建设的培养计划，在每个年龄段都优选若干名的员工进行长期全方位培养，赋予更多单独团队的决策和实操工作，加快成长；'},
      {con: '（四）公司将不断完善职业经理人制度，未来的传承将在公司现有员工中进行选拔，而且优选员工的子女，公司的传承将对下一代的子女一视同仁，一切为了公司好，让贤者上。'},
    ],},
    develop4: {title: '生态与繁荣并行', en: 'ECOLOGY & PROSPERITY', title1: '绿色低碳的经营原则', img: require('../assets/images/2023/develop/配图_4.png'), content: [
      {con: '旋荣的发展应秉承不损坏自然环境的原则，将力所能及的参与自然环境的优化，并视为企业未来的责任之一。我们作为服务于电网的企业，深刻了解能源和资源的来之不易和用之不易，因此我们为企业的发展制定了以下原则：'},
      {con: '（1）奉行低碳的运营原则：新增车辆以电动车为主，办公区域采用尽可能多的光伏等清洁能源发电装置为企业提供辅助能源供给等；'},
      {con: '（2）奉行节约的办公原则：公司以无纸化办公为主，涉及使用纸张时，非正式对外使用的文书要采用70G纸，且双面打印；节约用电，及时关闭不用办公电器，仪器，照明灯等，从点滴做起；鼓励员工减少使用一次性用品；建议员工日常生活中也不要铺张浪费等；'},
      {con: '（3）生产物资优选环保材料：在公司日常经营中，不论是公司自产的产品，还是通过供应链采购的产品，我们都会关注产品材料的环保属性，在成本相似的情况，务必优选环保材料'},
      {con: '（4）绿色环保理念的推广：公司积极参加力所能及的有关低碳，绿色，节能的各项活动，为绿色环保多出一份力；'},
      {con: '（5）探索高效的能源利用方式：通过低碳人工智能研究示范岛的建设，把节能和高效利用能源的探索落实到实处，我们不仅尽可能的使用清洁能源，同时探索在创造相同业务体量时如何降低能耗？通过示范岛的建设，实现高效的能源利用，并把各种节能的场景模块化，可以快速嫁接至各种场景中，帮助更多的用户实现节能减排。'},
      {con: '同时，旋荣力争构建一个TO B领域的良好商业生态，定位于MRO类产品的中宗（区别于大宗和小宗）产品交易，依托公司的助力飞现代智慧供应链平台，为合作伙伴做全面赋能，实现商业领域的生态与繁荣。'},
    ],},
    digitalMeter1: {title: '数字化远传表计技术', content: [
      {con: '以极高的性价比，实现变电站表计数据获取的全覆盖；'},
      {con: '依托长期和全面的表计数据积累，建立起主要设备故障预警的单层和多层的前馈神经网络;'},
      {con: '根据表计数据，建立变电站设备运行电子仿真平台，便于更加全面和科学预判故障和进行设备诊断。'},
      {url: require('../assets/images/2023/digitalMeter/配图-1.jpg')},
    ]},
    digitalMeter2: {title: '数字化远传表计专利', content: [
      {tit: '发明专利'},
      {con: '一种视觉Al精确识别的技术；'},
      {tit: '实用新型专利'},
      {con: '一种通过视觉Al精确识别进行表计数据采集的技术, 一种可级联的多类型数据融合加密终'},
      {url: require('../assets/images/2023/digitalMeter/配图-2.jpg')},
    ]},
    inspection1: {title: '输变电设备智能巡检及辅助控制', content: [
      {con: '变电站辅助设备监控系统由视频监控子系统、技术防范报警子系统、环境监测子系统、灯光控制子系统、火灾报警子系统、水冷却子系统（地下变电站）组成，实现对站内安防、消防、视频、环境监测等辅助设备的监视与控制。'},
      {url: require('../assets/images/2023/product/inspection/01.png')},
    ]},
    inspection2: {title: '变电站数字化表计平台', content: [
      {con: '变电站数字化表计平台旨在实现变电站内所有的表计数据实现数字化远传，以较高的性价比，快速实现变电站各类设备监视表计数据远传的全覆盖，减轻运行人员周期性现场抄表的工作负担；同时该平台能够全面积累各类表计数据的读数，结合变电站内设备运行的情况，建立故障预测的单层和多层的前馈神经网络。'},
      {url: require('../assets/images/2023/product/inspection/02.png')},
    ]},
    status1: {title: '特高压重症监护平台', content: [
      {con: '特高压重症监护平台由红外监控装置、网络传输装置、电源装置、后台监护系统组成，实现对站内需要监护的设备的监视。'},
      {url: require('../assets/images/2023/product/status/01.png')},
    ]},
    status2: {title: '微环境（温湿度、积水）状态监测平台', content: [
      {con: '微环境状态监测平台由传感器装置、集中采集装置、Lora/NB-IoT无线传输装置、后台监护系统组成，实现对站内设备需要监测的微环境的监视。'},
      {url: require('../assets/images/2023/product/status/02.png')},
    ]},
    status3: {title: '电缆状态监测平台', content: [
      {con: '电缆状态监测平台由高精度红外双光摄像机、高频局放在线监测、动态阻抗测试、电缆故障在线定位等装置模块组成，实现对电缆运行状态的实时监视和故障判断及定位。'},
      {url: require('../assets/images/2023/product/status/03.png')},
    ]},
    ibm1: {title: '助力飞现代智慧供应链', content: [
      {con: '“助力飞”是一款基于SaaS架构的一体化信息系统，通过独创的商业模式，旨在实现企业版“拼多多”和基于用户共享库存的分布式电子商城。助力飞的商业模式是为中小企业提供低成本的一体化信息系统，在大幅提升企业管理效率的同时，标准化中小企业的库存管理和订单履约流程。'},
      {con: '“助力飞”作为公司具有完全知识产权的产品，其核心技术包括：'},
      {con: '（1）AI自动识物：无需扫码，通过产品图形建模可自动识别，用以跟踪库存；'},
      {con: '（2）Saas化平台，免安装，可以部署在各种移动终端；'},
      {con: '（3）共享库存识别技术：通过用户的实际进销存操作，自动计算库存；'},
      {con: '（4）商品一物多码技术：建立公共标准库，与用户本地商品库进行推荐关联，用户确认等系列操作，实现多用户的商品标准化工作。'},
      {url: require('../assets/images/2023/product/ibm/01.png')},
    ]},
    cecurity1: {title: '变电站电子安防平台', content: [
      {con: '在变电站的实际应用中，各个监控系统均各成体系、师出多门，存在设备型号复杂、损坏后不便维护、在系统相似或交叉领域重复投资等诸多问题，在业务协调、联动等方面还存在困难。为了进一步提高变电站电子安防的管理水平，需要建立一套统一的管理维护平台——“变电站电子安防平台”来实现对各安防相关的子系统进行整合和管理，协助电力公司的管理人员更好的掌握全站安防设备的状态以及安全防护的状态。'},
      {url: require('../assets/images/2023/product/cecurity/01.png')},
    ]},
    platform1: {title: '变电站智慧辅控管理平台（LOGI-SACT）', en: '（LOGI-SACT）', content: [
      {con: '综合采用自动化技术、计算机技术、网络通信技术、视频压缩技术以及智能控制、联动等多种技术研发的，实现对变电站动力环境、图像、火灾报警、消防、照明、采暖通风、安防报警、门禁等进行在线监视和智能控制，对全站主要电气设备、关键设备安装地点以及周围环境进行全天候的状态监视和智能控制，以满足电力系统安全生产要求。'},
      {url: require('../assets/images/2023/product/platform/01.png')},
      {url: require('../assets/images/2023/product/platform/02.png')},
      {url: require('../assets/images/2023/product/platform/03.png')},
    ]},
    platform2: {title: '变电站远程智能巡视系统', content: [
      {con: '变电站远程智能巡视系统部署在变电站站端，主要由巡视主机、智能分析主机、轮式机器人、挂轨机器人、摄像机、无人机及声纹监测装置等组成。巡视主机下发控制、巡视任务等指令，由机器人、摄像机和无人机开展室内外设备联合巡视作业，并将巡视数据、采集文件等上送到巡视主机；巡视主机与智能分析主机对采集的数据进行智能分析，形成巡视结果和巡视报告。巡视系统具备获取与巡视相关的状态监测数据与动力环境数据、与主辅设备监控系统智能联动等功能。'},
      {url: require('../assets/images/2023/product/platform/04.png')},
    ]},
    platform3: {title: '智能电子安防监控平台', content: [
      {con: '电子安防综合管理平台是根据GA1800——《电力系统治安反恐防范要求》和国家电网公司颁布的《变电站（换流站）治安反恐防范工程完善化改造原则》的文件精神，打造的一套以电子技术防范手段为主的变电站（换流站）安全防范管控系统，包含了周界入侵和紧急报警模块、视频监控模块、出入口控制模块、保安电子巡更模块和反无人机防御模块等。系统采用软网关、UKEY用户认证和数据安全加密等技术手段，确保系统运行安全流畅，同时还预留和消防报警系统联动的接口模块，可实现安消一体化平台功能。'},
      {tit: '该平台的主要功能有：'},
      {con: '提供安防一张图、全局掌控、图上指挥的功能，实现报警联动可视化，站内地图可视化，视频监控可视化，设备状态可视化，人员/车辆出入可视化，系统状态可视化；'},
      {con: '兼容性强：平台可接入多种智能设备，多种独立的硬件设备在系统的统一调度下协同工作。硬件相互配合使用，可获得最好的稳定性和最佳的效能；'},
      {con: '可利旧：基于系统硬件兼容性强的基础上，本次安防项目可利用站内现有的安防硬件基础上进行升级改造；'},
      {con: '系统开放度高：为其他系统提供标准接口，以利于其他系统调用及二次开发。具备开放的二次接口，可以为第三方提供进行连接的接口。提供的支撑软件能支持用户进一步开发应用软件；'},
      {con: '支持定制开发：根据客户的需求进行定制自定义开发最大的优势就在于，因为是开发人员根据企业的需求进行的个性化开发。所以软件里面的所有功能，都可以最大限度地达到客户的业务要求。'},
      {url: require('../assets/images/2023/product/platform/05.png')},
      {url: require('../assets/images/2023/product/platform/06.png')},
    ]},
    platform4: {title: '变电站表计数字化远程监视平台', content: [
      {con: '该平台旨在实现变电站内所有的表计数据实现数字化远传，以较高的性价比快速实现变电站各类设备监视表计数据远传的全覆盖，减轻运行人员周期性现场抄表的工作负担；同时该平台能够全面积累各类表计数据的读数，结合变电站内设备运行的情况，建立故障预测的单层和多层的前馈神经网络。通过更加长期的数据积累，在条件成熟的基础上，我们将着力建立变电站设备运行电子仿真平台，便于更加全面和科学预判故障和进行设备诊断，协助客户更好的构建新型电力系统。'},
      {con: '通过AI图像识别技术实现变电站表计数字化远程监视，对传统表计通过装设AI读表装置，将表盘的指针/数字信息就地读取识别，并汇总传送到站端智能数据融合终端（主IED），进行各类表计读数和历史曲线的展示，并做一定的趋势预判和阈值告警功能。'},
      {tit: '该平台目前主要功能有：'},
      {con: '提供驾驶舱式的数据展示和管理方式；'},
      {con: '对常规表计加装数字传感器或直接更换为数字型表计，通过有线或无线通信将表计数据汇集到智能表计数据融合终端，并上传至辅控系统。其中关键类表计通过数字表+AI图像分析实现数据双确认；'},
      {con: '用于目前无法数字化改造的表计如开关动作计数器等，将表盘图像数据进行就地AI分析智能读取后，通过有线或无线通信汇集到智能表计数据融合终端，并上传至辅控系统；AI智能读表也可用于所有的常规表计；'},
      {con: '对于一体化系统中的表计读数，由一体化电源系统通过我们自研的表计数据融合终端，统一接入辅控系统。'},
      {url: require('../assets/images/2023/product/platform/07.png')},
    ]},
    guardianship1: {title: '变电设备红外重症监护装置（XM-IR-ICU）', content: [
      {con: '可移动式红外热像重症监护装置安装方便快速、功能强大，具有红外实时监测、自动预警等多种功能，自动告警功能，可在任何4G网络覆盖的地方实时接收到来自装置系统的告警信息和红外热像图。该设备是实现对重点设备或红外缺陷设备进行短期或长期实时热隐患监测的理想工具，减少了人员到现场巡检的次数，提高了运行工作效率，符合现场运维管理向少人或无人值班发展趋势的需要，方便用于要求高密度巡检的高负载电力设备的实时监测。装置由具备全天候防护能力的超小型红外热成像摄像机（行业领先的美国FLIR公司产品）、现场操控平台（内置工业平板电脑、大容量可充电移动电源、4G通讯模块）和后台（站端PC机或移动终端APP）组成。'},
      {url: require('../assets/images/2023/product/guardianship/01.png')},
      {tit: '装置主要功能：'},
      {con: '多种远端监控方式，同时支持4G短信报警、4G视频监控和有线视频监控；'},
      {con: '仪器内置电力设备诊断规范，实时智能诊断电力设备故障，智能报警，实时通知相关设备负责人员，同时自动生成报表；'},
      {con: '支持全像素红外温度流和可见光视频监控，实时准确测温，在无外电的情况下可独立持续工作超72小时；'},
      {con: '具备热像视频存储功能，可保存超过4000个图像或16小时视频文件，可在电脑上回放分析；'},
      {con: '设备集成度高，抗干扰性强，便于移动和携带，安装简易方便。'},
      {con: '系统预留有网络安全接口，便于接入电力专网，统一管理；'},
      {con: 'IP66防护，系统能全天候运行，不受气候的影响，无论是刮风下雨，还是高低温环境，都可以实现不间断自动监测，安全可靠。'},
    ]},
    guardianship2: {title: '电气柜防潮防凝露专项解决方案', content: [
      {tit: '（一）电气柜内潮气来源'},
      {con: '运行年久，密封条老化，潮气从柜门、观察窗密封处侵入；'},
      {con: '柜底防火堵泥老化干裂或基建施工不规范，潮气从底部电缆沟侵入；'},
      {con: '部分电气柜未考虑通风设计，柜内加热器通常放置在低处，潮湿空气受热上升，在柜顶遇冷凝露；'},
      {url: require('../assets/images/2023/product/guardianship/02.png'), class: 'border-img'},
      {tit: '（二）改善柜内运行微环境的方案——除湿+防潮'},
      {tit: '1、多管齐下主动吸湿+强制排风'},
      {con: '调湿片双向湿度调节，重复利用；'},
      {con: '智能除湿器按设定的启动条件开启除湿，将潮气以冷凝水的方式排出柜体，同时可远程监控柜内温湿度情况；'},
      {con: '加装强排风扇，强化柜内空气流通，提高除湿装置的工作效率，更有效的将潮湿空气抽出柜体。'},
      {url: require('../assets/images/2023/product/guardianship/03.png'), class: 'w30'},
      {tit: '2、从柜体、柜底分别采取防潮措施'},
      {con: '更换箱柜门密封条，根据不同的柜门结构，选取合适的三元乙丙高性能密封条替换老化失效的原密封条；'},
      {con: '柜底满铺防潮阻燃专用胶。'},
      {url: require('../assets/images/2023/product/guardianship/04.png'), class: 'border-img'},
    ]},
    guardianship3: {title: '自组网温湿度及积水监控（XM-WSD）', content: [
      {con: '温湿度及积水监控装置，前端信息采集由低功耗自组网传感器和信号汇集器组成，传感器放置在户外设备机构箱、汇控箱和电缆沟内，通过LORA无线局域网将监测数据发送给信号汇集器，后者将现场采集并预处理后的监控数据接入统一智辅信息监控平台。'},
      {url: require('../assets/images/2023/product/guardianship/05.png')},
      {tit: '主要元器件：'},
      {con: '低功耗传感器：无源设计，内置锂电可连续工作长达5年（按1次/小时的采样频率），基于Lora或NB-loT窄带物联网进行数据传送，根据监测对象具体情况可选传感器天线一体布置或分体布置形式，满足不同的空间和信号屏蔽条件。'},
      {con: '信号汇集器：传感器采用Lora方式组网时使用，分为自带液晶调试面板和不带面板两种形式，安装于可取电的现场箱柜内，收集附近区域传感器上报的数据（每个采集装置最多可接入240路数据），并进行初步数据处理后上传后端监控平台。'},
      {con: '信息监控平台：辅信息监控平台（名称需核实确认）  开放式综合数据接入及展示平台，实现多种监视信息的实时、历史数据查询和报警，根据需要可输出远程控制指令（如远程投入柜内加热、除湿和通风等装置）。平台附带微信小程序功能，可在手机上进行查询和操作。'},
      {url: require('../assets/images/2023/product/guardianship/06.png')},
    ]},
    core1: {title: '旋荣产品核心技术层级', content: [
      {con: '旋荣的核心技术分为五个层级，各层级之间可进行多样化的组合，高效的满足不同类型用户和应用场景的需求，每个层级的功用概述如下：'},
      {con: '核心层：基于多维度和多模型的视觉AI精确识别引擎、助力飞智慧供应链SaaS平台、电力设备控制平台、设备状态诊断边缘计算。'},
      {con: '感知层：TTU(配电变压器监测终端)、DTU(数据终端设备)、红外摄像头、光学摄像头、温湿传感器、Lora组网模块等。'},
      {con: '内部机制：安全机制、数据库设计机制、SaaS部署机制、License注册机制、中间件机制、缓存机制、UI机制。'},
      {con: '应用层：智能变电站辅助系统综合监控平台、特高压重症监护平台、微环境（温湿度、积水）状态监测平台、电缆状态监测平台、充电桩微电网调度管理平台、OA、CRM、PMP、VP（SRM）、WMS、TMS、BM（投标管理）、BI、进销存、MRP、FI-CO。'},
      {con: '外部接口层（外部平台或客户）：其他供应链平台、其他电商平台、其他OA、其他CRM、其他进销存、各个充电桩企业、车企、各传感器厂商、其他辅控系统。'},
      {url: require('../assets/images/2023/product/core/01.png')},
    ]},
    core2: {title: '助力飞核心技术', content: [
      {con: '“助力飞”作为旋荣具有完全知识产权的产品，其核心技术包括：'},
      {con: 'AI自动识物：无需扫码，通过产品图形建模可自动识别，用以跟踪库存。'},
      {con: 'Saas化平台：免安装，可以部署在各种移动终端。'},
      {con: '共享库存识别技术：通过用户的实际进销存操作，自动计算库存。'},
      {con: '商品一物多码技术：建立公共标准库，与用户本地商品库进行推荐关联，用户确认等系列操作，实现多用户的商品标准化工作。'},
      {url: require('../assets/images/2023/product/core/02.png')},
    ]},
    monitor1: {title: '视频在线监测装置',en: '【LW-iV001】', img: require('../assets/images/2023/product/monitor/01.png'), content: [
      {con: '输电线路视频在线监测装置【LW-iV001】，主要由太阳能板、监控箱、电源箱、球机组件、四部分组成，其中电源箱包括特定的环境保温隔热设计，满足室外高温环境、低温环境的应用，监控箱包括通讯及其他运行模块和附件。'},
    ], title2: '图像在线监测装置',en2: '【LW-iP001】', img2: require('../assets/images/2023/product/monitor/02.png'), content2: [
      {con: '输电线路图像在线监测装置【LW-iP001】，主要由三部分组成：'},
      {con: '主机单元：提供摄像机、通讯模块和其他附件。'},
      {con: '供电单元：包含锂电池、太阳能电池板和相关附属组件。'},
      {con: '摄像单元：具有控制雨刷电机和镜头玻璃加热功能。'},
      {con: '具备24小时视频工作及取证能力，支持录像循环存储，支持录像检索及回放；具备图像远程传输功能，支持图像长时不回传，通讯恢复后并回传的功能以及AI智能分析功能等。'},
    ]},
    monitor2: {title: '虚拟电厂为配电网和输电网提供管理和辅助服务',en: 'Virtual Power Plant', img: require('../assets/images/2023/product/monitor/03.png'), content: [
      {con: '虚拟电厂是互联网+智慧能源环境下，以用户为中心、以商业化市场为平台的源网荷聚合管理模式。“虚拟电厂”是将分布式发电机组、可控负荷和分布式储能设施有机结合，通过配套的调控技术、通信技术实现对各类分布式能源进行整合调控的载体，以作为一个特殊电厂参与电力市场和电网运行，从某种意义上讲，虚拟发电厂可以看作是一种先进的区域性电能集中管理模式。'},
      {con: '虚拟电厂为配电网和输电网提供管理和辅助服务，虚拟电网是一种智能电网技术，其核心理念就是通过先进信息通信技术和软件系统，把各类分散、可调节的电源和负荷汇聚起来，形成一个虚拟的“电”进行统一管理和调度。'},
    ]},
    overhaul1: {title: '电力系统工程大修', en: 'Power System Engineering Overhaul', content: [
      {con: '对输变配供电相关设备及相应附属设施等进行改造、更新、周期性更换、修理和维护等，能提高电力系统的安全性、稳定性和可靠性，为客户提供检修现场6S管理，推进信息化系统一体化应用，提升实用化水平，实现大修技改项目准入与设备台账、缺陷隐患、状态评价、风险评估等信息动态关联，实现生产项目全过程管控，实现设备资产从投资计划、设备采购、工程安装、运行维护、退役报废等全过程精益化管理。同时，通过不断优化系统，解决系统流程繁琐和不稳定问题，加强人员培训，提高系统应用效率和应用水平。'},
      {url: require('../assets/images/2023/product/overhaul/01.png')}
    ]},

  },
  mutations: {},
  actions: {},
  getters: {
    getNmps: state => state.nmps,
    getInfrared: state => state.infrared,
    getMicroenvironment: state => state.microenvironment,
    getAi: state => state.ai,
    getSubstation: state => state.substation,
    getCharge: state => state.charge,
    getProfile: state => state.profile,
    getCulture: state => state.culture,
    getHonor: state => state.honor,
    getCertification: state => state.certification,
    getVision: state => state.vision,
    getService: state => state.service,
    getBusiness: state => state.business,
    getInformation: state => state.information,
    getCompany: state => state.company,
    getIndustry: state => state.industry,
    getCompanyNew1: state => state.companyNew1,
    getCompanyNew2: state => state.companyNew2,
    getCompanyNew3: state => state.companyNew3,
    getCompanyNew4: state => state.companyNew4,
    getCompanyNew5: state => state.companyNew5,
    getCompanyNew6: state => state.companyNew6,
    getCompanyNew7: state => state.companyNew7,
    getCompanyNew11: state => state.companyNew11,
    getCompanyNew12: state => state.companyNew12,
    getCompanyNew13: state => state.companyNew13,
    getCompanyNew14: state => state.companyNew14,
    getCompanyNew15: state => state.companyNew15,
    getCompanyNew16: state => state.companyNew16,
    getCompanyNew17: state => state.companyNew17,
    getCompanyNew18: state => state.companyNew18,
    getCompanyNew19: state => state.companyNew19,
    getCompanyNew20: state => state.companyNew20,
    getCompanyNew21: state => state.companyNew21,
    getCompanyNew22: state => state.companyNew22,
    getCompanyNew23: state => state.companyNew23,
    getInvestor: state => state.investor,
    getTeam1: state => state.team1,
    getTeam2: state => state.team2,
    getTeam3: state => state.team3,
    getTeam4: state => state.team4,
    getTeam5: state => state.team5,
    getDevelop1: state => state.develop1,
    getDevelop2: state => state.develop2,
    getDevelop3: state => state.develop3,
    getDevelop4: state => state.develop4,
    getDigitalMeter1: state => state.digitalMeter1,
    getDigitalMeter2: state => state.digitalMeter2,
    getInspection1: state => state.inspection1,
    getInspection2: state => state.inspection2,
    getStatus1: state => state.status1,
    getStatus2: state => state.status2,
    getStatus3: state => state.status3,
    getIbm1: state => state.ibm1,
    getCecurity1: state => state.cecurity1,
    getPlatform1: state => state.platform1,
    getPlatform2: state => state.platform2,
    getPlatform3: state => state.platform3,
    getPlatform4: state => state.platform4,
    getGuardianship1: state => state.guardianship1,
    getGuardianship2: state => state.guardianship2,
    getGuardianship3: state => state.guardianship3,
    getCore1: state => state.core1,
    getCore2: state => state.core2,
    getMonitor1: state => state.monitor1,
    getMonitor2: state => state.monitor2,
    getOverhaul1: state => state.overhaul1,
  }
})
